<template>
  <svg
    id="Box"
    xmlns="http://www.w3.org/2000/svg"
    width="18.877"
    height="18.877"
    viewBox="0 0 18.877 18.877"
  >
    <g
      id="Gruppe_304"
      data-name="Gruppe 304"
      transform="translate(-732.25 -332.25)"
    >
      <rect
        id="Rechteck_1199"
        data-name="Rechteck 1199"
        width="11.032"
        height="11.032"
        transform="translate(733.163 339.181)"
        fill="#c0c1c3"
       :class="fillClass + ' transition-fill'"
       />
      <path
        id="Pfad_487"
        data-name="Pfad 487"
        d="M744.2,350.05H733.163a.914.914,0,0,1-.913-.913V338.1a.914.914,0,0,1,.913-.913H744.2a.914.914,0,0,1,.913.913v11.033A.914.914,0,0,1,744.2,350.05Zm-10.119-1.827h9.206v-9.206h-9.206Z"
        transform="translate(0 1.076)"
        fill="#c0c1c3"
       :class="fillClass + ' transition-fill'"
       />
      <path
        id="Pfad_488"
        data-name="Pfad 488"
        d="M736.832,333l-3.009,3.009h11.032L747.865,333Z"
        transform="translate(0.343 0.163)"
        fill="#c0c1c3"
       :class="fillClass + ' transition-fill'"
       />
      <path
        id="Pfad_489"
        data-name="Pfad 489"
        d="M745.019,337.086H733.987a.914.914,0,0,1-.647-1.56l3.009-3.008a.91.91,0,0,1,.646-.268h11.033a.913.913,0,0,1,.645,1.559l-3.008,3.009A.915.915,0,0,1,745.019,337.086Zm-8.827-1.827h8.45l1.181-1.183h-8.45Z"
        transform="translate(0.18)"
        fill="#c0c1c3"
       :class="fillClass + ' transition-fill'"
       />
      <path
        id="Pfad_490"
        data-name="Pfad 490"
        d="M744.529,338.068l3.009-3.009v11.032l-3.009,3.009Z"
        transform="translate(2.675 0.612)"
        fill="#c0c1c3"
       :class="fillClass + ' transition-fill'"
       />
      <path
        id="Pfad_491"
        data-name="Pfad 491"
        d="M744.692,350.177a.9.9,0,0,1-.35-.069.913.913,0,0,1-.564-.844V338.231a.913.913,0,0,1,.268-.645l3.009-3.009a.914.914,0,0,1,1.559.647v11.033a.914.914,0,0,1-.268.645l-3.008,3.009A.918.918,0,0,1,744.692,350.177Zm.913-11.567v8.45l1.183-1.183v-8.45Z"
        transform="translate(2.512 0.449)"
        fill="#c0c1c3"
       :class="fillClass + ' transition-fill'"
     
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ColliBoxIcon",
  props: {
    fillClass: {
      type: String,
      required: false,
      default: "",
    },
  },
});
</script>
