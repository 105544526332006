<template>
  <div data-testId="list-item" class="p-3" v-if="!showItems">
    <!-- Title -->
    <div class="flex items-center gap-2">
      <div class="font-semibold text-md flex gap-2">
        <div>{{ colli.name }}</div>
      </div>

      <div
       data-testId="box-icon"
        class="cursor-pointer"
        v-if="
          !administrationView &&
            colli.colliPositions != null &&
            colli.colliPositions.length != 0
        "
        @click.stop="$emit('showAllPositions')"
      >
        <div><box-icon :fillClass="'fill-primary'" /></div>
      </div>

      <div
        class="cursor-pointer ml-2"
        v-if="colli.data != null && colli.data.data != null"
        @click.stop="$emit('showAllData')"
      >
        <div><info-icon :fillClass="'fill-primary'"></info-icon></div>
      </div>

      <div
        class="ml-auto rounded-full w-10 h-10 items-center flex hover:bg-primary-lightest "
        @mouseover="state.hover = true"
        @mouseleave="state.hover = false"
      >
        <div
          class="cursor-pointer"
          v-if="!administrationView"
          @click.stop="$emit('showPositions')"
        >
          <div id="btn" class="ml-2">
            <position-icon />
          </div>

          <div
            class="text-sm mt-2 bg-grey-dark text-white px-1 absolute rounded shadow-xl"
            id="tooltip"
            v-if="state.hover == true"
          >
            {{ $t("show_all_positions") }}
          </div>
        </div>

        <div class="cursor-pointer" @click="$emit('editColli')" v-else>
          <div><edit-icon /></div>
        </div>
      </div>
    </div>

    <div class="flex flex-row flex-wrap justify-start mt-3 gap-8">
      <div class="sm:w-auto w-1/2" v-if="!administrationView">
        <div class="text-sm text-blue">
          {{ $t("current_position") }}
        </div>
        <div :id="'colli-position-name-' + colli.id" class="text-md">
          {{ colli.position ? colli.position.name : "-" }}
        </div>
      </div>
      <div v-for="dataField of dataModelOfDataFields" :key="dataField">
        <div class=" flex text-sm gap-9 sm:gap-20">
          <div>
            <div class="text-sm text-blue">
              {{ dataField.key }}
            </div>
            <div
              class="text-md mt-2"
              v-if="colli.data.data[dataField.key] === true"
            >
              <right-icon />
            </div>
            <div
              class="text-md mt-2"
              v-else-if="colli.data.data[dataField.key] === false"
            >
              <false-icon />
            </div>
            <div class="text-md" v-else>
              {{
                colli.data.data[dataField.key] == null ||
                colli.data.data[dataField.key] == ""
                  ? "-"
                  : colli.data.data[dataField.key]
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!----------- product carrier list  -------------->
  <div class="bg-white-dark rounded-md">
    <div
      class="bg-white-dark rounded-md w-full p-2 mt-2 hover:border-grey-fontgrey"
      :class="
        GET_CUSTOMER_COLLI.id == colli.id
          ? 'border-t-4 border-primary'
          : 'border border-transparent'
      "
      v-if="showItems"
    >
      <div class="p-2 flex items-center">
        <div class="flex flex-col">
          <div class=" flex  font-semibold  box-content md:text-md gap-1">
            <div class="font-semibold">{{ colli.name }}</div>
          </div>
          <div class="text-xs md:text-sm hidden sm:block">
            <div class=" flex items-center text-sm gap-2">
              <div v-if="colli != null && colli.tag != null">
                {{ $t("channel") }}: {{ colli.tag.name }} |
              </div>
              <div v-if="colli.data != null && colli.data.dataModel != null">
                {{ $t("group") }}: {{ colli.data.dataModel.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="ml-auto md:mr-7">
          <div class="flex items-center space-x-6 ">
            <edit-icon class="cursor-pointer" @click="$emit('editColli')" />
            <trash-icon class="cursor-pointer" @click="$emit('deleteColli')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";

import EditIcon from "../../../components/icons/EditIcon.vue";
import InfoIcon from "../../../components/icons/InfoIcon.vue";
import PositionIcon from "../../../components/icons/PositionIcon.vue";
import TrashIcon from "../../../components/icons/TrashIcon.vue";
import { CUSTOMER_COLLIS_TYPES, STORE_TYPES } from "../../../store/types";

export default defineComponent({
  name: "ProductCarrierInfoContainer",
  components: { PositionIcon, EditIcon, TrashIcon, InfoIcon },

  /** Emits :  */

  emits: [
    "open",
    "editColli",
    "showAllPositions",
    "deleteColli",
    "showPositions",
    "showAllData",
  ],

  /** Props */
  props: {
    administrationView: {
      type: Boolean,
      default: false,
    },
    colli: {
      type: Object,
    },
    showItems: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const store = useStore();

    /** State */
    const state = reactive({
      hover: {
        type: Boolean,
        default: false,
      },
    });

    /**
     * Computeds
     */
    const GET_CUSTOMER_COLLI = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.GET_CUSTOMER_COLLI}`
      ];
    });
    const dataModelOfDataFields = computed(() => {
      let dataModelFields = [];

      if (
        props.colli.data != null &&
        props.colli.data.data != null &&
        props.colli.data.dataModel != null &&
        props.colli.data.dataModel.dataFields != null
      ) {
        dataModelFields = props.colli.data.dataModel.dataFields;
      }
      return dataModelFields;
    });

    /**
     * Functions
     */
    const showUp = () => {
      window.addEventListener("DOMContentLoaded", () => {
        const btn = document.querySelector("#btn");
        const tooltip = document.querySelector("#tooltip");
        btn.addEventListener("mouseover", () => {
          tooltip.classList.remove("hidden");
        });
        btn.addEventListener("mouseleave", () => {
          tooltip.classList.add("hidden");
        });
      });
    };

    return {
      GET_CUSTOMER_COLLI,
      showUp,
      state,
      dataModelOfDataFields,
    };
  },
});
</script>
