import { SETTINGS_TYPE } from "../types";

const state = {
  "synthwave84.brightness": "0.45",
  "synthwave84.disableGlow": true,
};

const getters = {};

const actions = {
  async [SETTINGS_TYPE.FETCH_SETTINGS]() {
    await new Promise((r) => setTimeout(r, 3000));
    return;
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
