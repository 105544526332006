import { CUSTOMER_CHANNELS_TYPES } from "../types";
import ApiService from "../../common/apiService";
import { getData } from "../../common/dataHelper";
import router from "../../router";

/**  ********************** STATE *********************** */
const state = {
  customer_channels: [],
  customer_channel: {},
  delete_customer_channel: null,
};

/**  ********************** GETTERS *********************** */
const getters = {
  [`${CUSTOMER_CHANNELS_TYPES.GET_CUSTOMER_CHANNELS}`](state) {
    return state.customer_channels;
  },

  [`${CUSTOMER_CHANNELS_TYPES.GET_CUSTOMER_CHANNEL}`](state) {
    return state.customer_channel;
  },
  [`${CUSTOMER_CHANNELS_TYPES.GET_DELETE_CUSTOMER_CHANNEL}`](state) {
    return state.delete_customer_channel;
  },
};

/**  ********************** ACTIONS *********************** */
const actions = {
  async [`${CUSTOMER_CHANNELS_TYPES.FETCH_CUSTOMER_CHANNELS}`](context, id) {
    const response = await ApiService.query("api/customers/" + id + "/tags");
    if (getData(response, "status") === 200) {
      context.commit(
        `${CUSTOMER_CHANNELS_TYPES.SET_CUSTOMER_CHANNELS}`,
        getData(response, "data")
      );
      return getData(response, "data");
    }
  },

  /** Create CUSTOMER CHANNEL */
  async [`${CUSTOMER_CHANNELS_TYPES.CREATE_CUSTOMER_CHANNEL}`](context, id) {
    let customer_channel = context.state.customer_channel;
    const response = await ApiService.post(
      "api/customers/" + id + "/tags",
      customer_channel
    );
    if (getData(response, "status") === 200) {
      state.customer_channels.push(getData(response, "data"));
    }
    router.push({ name: "Channel" });
    return response;
  },

  /** Update CUSTOMER CHANNEL */

  async [`${CUSTOMER_CHANNELS_TYPES.UPDATE_CUSTOMER_CHANNEL}`](context) {
    let customer_channel = context.state.customer_channel;
    const response = await ApiService.update(
      "api/customers/" + customer_channel.customer_id + "/tags",
      customer_channel.id,
      customer_channel
    );
    if (getData(response, "status") === 200) {
      context.commit(
        `${CUSTOMER_CHANNELS_TYPES.SET_CUSTOMER_CHANNEL}`,
        getData(response, "data")
      );
    }
    return response;
  },

  /** DELETE CUSTOMER CHANNELS */

  async [CUSTOMER_CHANNELS_TYPES.delete_customer_channel](context, id) {
    let customer_channel = context.state.delete_customer_channel;
    const response = await ApiService.delete(
      "api/customers/" + id + "/tags/" + customer_channel.id
    );

    if (customer_channel != null) {
      const findIndex = context.state.customer_channels.findIndex(
        (customerChannels) => {
          return customerChannels.id == customer_channel.id;
        }
      );
      context.state.customer_channels.splice(findIndex, 1);
      context.state.delete_customer_channel = null;
    }

    return response;
  },
};

/**  ********************** MUTATIONS *********************** */
const mutations = {
  [`${CUSTOMER_CHANNELS_TYPES.SET_CUSTOMER_CHANNELS}`](state, value) {
    state.customer_channels = value;
  },

  [`${CUSTOMER_CHANNELS_TYPES.SET_CUSTOMER_CHANNEL}`](state, value) {
    state.customer_channel = value;
  },
  [`${CUSTOMER_CHANNELS_TYPES.SET_DELETE_CUSTOMER_CHANNEL}`](state, value) {
    state.delete_customer_channel = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
