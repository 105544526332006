const files = require.context("../components/icons", true, /\.vue$/i);
export const registerIcons = (app) => {
    files.keys().map((key) => {
        app.component(
            key
                .split("/")
                .pop()
                .split(".")[0],
            files(key).default
        );
    });
};
