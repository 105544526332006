import store from "../store";
import { FINDERS_TYPES, STORE_TYPES } from "../store/types";

/**
 * 1. Colliarray -> foreach
 * 2. Colli in position finden & zwischenspeichern (PositionColli)
 * 3. PositionColli.last_update_time = pusherColli.last_update_time
 * 4. Ändert sich position ? pusherColli.position.id != position.id
 * 5. Wenn Position geändert: Splice & Push in neue Position
 */

export function updateCollis(pusherCollis) {
  // save position on a variable
  const positions =
    store.getters[
      `${STORE_TYPES.FINDERS}/${FINDERS_TYPES.GET_FINDER_POSITIONS}`
    ];

  // search in pusherCollis
  pusherCollis.forEach((colli) => {
    // save the updatedPosition in a variable
    let updatedPosition = positions.find((position) => {
      // return the changed colli
      return position.collis.some((pc) => pc.id == colli.id);
    });

    if (updatedPosition != null) {
      // save the updated colli in a variable
      let updatedColli = updatedPosition.collis.find((c) => c.id == colli.id);
      updatedColli.lastUpdate = colli.position.timestamp;

      // change text-color
      let colliLastUpdate = document.getElementById("colli-last-update-" + updatedColli.id);
      setTimeout(() => {
        if (colliLastUpdate != null) {
          colliLastUpdate.style.color = "#3CB371";
          colliLastUpdate.style.fontWeight = "500";
        }
        setTimeout(() => {
          if (colliLastUpdate != null) {
            colliLastUpdate.style.color = "";
            colliLastUpdate.style.fontWeight = "";
          }
        }, 4000);
      }, 100);

      if (updatedPosition.id != colli.position.id) {
        moveColli(updatedColli, updatedPosition, positions, colli.position);
      }
    }
  });
}

function moveColli(updatedColli, updatedPosition, positions, newColliPosition) {
  var index = updatedPosition.collis.indexOf(updatedColli);
  updatedPosition.collis.splice(index, 1);

  let newPosition = positions.find((position) => {
    return position.id == newColliPosition.id;
  });

  if (newPosition != null) {
    newPosition.collis.push(updatedColli);
    newPosition.colliCount++;
    updatedPosition.colliCount--;
  }
}

/**
 *
 * CHANGE EVENT
 */

export function changeCurrentPosition(collis) {
  const allCollis =
    store.getters[`${STORE_TYPES.FINDERS}/${FINDERS_TYPES.GET_FINDER_COLLIS}`];
  allCollis.forEach((c) => {
    collis.forEach((colli) => {
      if (colli.id == c.id) {
          const changedPosition = c.position != colli.position;
        c.position = colli.position;

        // change text-color
        setTimeout(() => {
          let colliPositionName = document.getElementById(
            "colli-position-name-" + colli.id
          );
          if (colliPositionName != null && changedPosition) {
            colliPositionName.style.color = "#3CB371";
            colliPositionName.style.fontWeight = "500";
          }
          setTimeout(() => {
            if (colliPositionName != null) {
              colliPositionName.style.color = "";
              colliPositionName.style.fontWeight = "";
            }
          }, 4000);
        }, 100);

        c.colliLogs.unshift({
          position: c.position,
          timestamp: c.position.timestamp,
        });

        setTimeout(() => {
          let colliLog = document.getElementById("colli-Log");
          if (colliLog != null) {
            colliLog.style.borderColor = "#3CB371";
          }
          setTimeout(() => {
            if (colliLog != null) {
              colliLog.style.borderColor = "";
            }
          }, 4000);
        }, 100);
      }
    });
  });
  updateCollis(collis);
}
