<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.043"
        height="11.5"
        viewBox="0 0 24.043 11.5"
    >
        <g id="BurgerMenu" transform="translate(16 11.5) rotate(180)">
            <rect
                id="Rechteck_920"
                data-name="Rechteck 920"
                width="16"
                height="1.5"
                rx="0.5"
                transform="translate(-0.078)"
                :class="fillClass + ' transition-fill'"
            />
            <rect
                id="Rechteck_922"
                data-name="Rechteck 922"
                width="16"
                height="1.5"
                rx="0.5"
                transform="translate(-0.078 10)"
                :class="fillClass + ' transition-fill'"
            />
            <rect
                id="Rechteck_1127"
                data-name="Rechteck 1127"
                width="21"
                height="1.5"
                rx="0.5"
                transform="translate(-5 5)"
                :class="fillClass + ' transition-fill'"
            />
            <path
                id="Pfad_393"
                data-name="Pfad 393"
                d="M17.792,13.1l-.007-6.69a.5.5,0,0,1,.856-.355l3.347,3.341a.5.5,0,0,1,0,.709L18.647,13.45a.5.5,0,0,1-.855-.354"
                transform="translate(14.092 15.5) rotate(180)"
                :class="fillClass + ' transition-fill'"
            />
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "MenuLeftIcon",
    props: {
        fillClass: {
            type: String,
            required: true,
        },
    },
});
</script>
