<template>
  <svg
    id="Box"
    xmlns="http://www.w3.org/2000/svg"
    width="15.5"
    height="15.5"
    viewBox="0 0 15.5 15.5"
  >
    <g
      id="Gruppe_302"
      data-name="Gruppe 302"
      transform="translate(-732.25 -332.25)"
    >
      <rect
        id="Rechteck_1199"
        data-name="Rechteck 1199"
        width="9.059"
        height="9.059"
        transform="translate(733 337.941)"
        :class="fillClass + ' transition-fill'"
      />
      <path
        id="Pfad_487"
        data-name="Pfad 487"
        d="M742.059,347.75H733a.75.75,0,0,1-.75-.75v-9.059a.75.75,0,0,1,.75-.75h9.059a.75.75,0,0,1,.75.75V347A.75.75,0,0,1,742.059,347.75Zm-8.309-1.5h7.559v-7.559H733.75Z"
        :class="fillClass + ' transition-fill'"
      />
    </g>
    <g
      id="Gruppe_303"
      data-name="Gruppe 303"
      transform="translate(-732.25 -332.25)"
    >
      <path
        id="Pfad_488"
        data-name="Pfad 488"
        d="M736.294,333l-2.471,2.471h9.059L745.353,333Z"
        :class="fillClass + ' transition-fill'"
      />
      <path
        id="Pfad_489"
        data-name="Pfad 489"
        d="M742.882,336.221h-9.058a.751.751,0,0,1-.531-1.281l2.471-2.47a.747.747,0,0,1,.53-.22h9.059a.75.75,0,0,1,.53,1.28L743.413,336A.751.751,0,0,1,742.882,336.221Zm-7.248-1.5h6.938l.97-.971H736.6Z"
        :class="fillClass + ' transition-fill'"
      />
    </g>
    <g
      id="Gruppe_304"
      data-name="Gruppe 304"
      transform="translate(-732.25 -332.25)"
    >
      <path
        id="Pfad_490"
        data-name="Pfad 490"
        d="M744.529,337.529,747,335.059v9.059l-2.471,2.471Z"
        :class="fillClass + ' transition-fill'"
      />
      <path
        id="Pfad_491"
        data-name="Pfad 491"
        d="M744.529,347.338a.742.742,0,0,1-.287-.057.75.75,0,0,1-.463-.693v-9.059A.75.75,0,0,1,744,337l2.471-2.471a.75.75,0,0,1,1.28.531v9.059a.75.75,0,0,1-.22.53l-2.47,2.471A.754.754,0,0,1,744.529,347.338Zm.75-9.5v6.938l.971-.971v-6.938Z"
        :class="fillClass + ' transition-fill'"
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BoxIcon",
  props: {
    fillClass: {
      type: String,
      default: "",
    },
  },
});
</script>
