<template>
  <svg
    id="IoT"
    xmlns="http://www.w3.org/2000/svg"
    width="20.819"
    height="20.52"
    viewBox="0 0 20.819 20.52"
  >
    <path
      id="Pfad_281"
      data-name="Pfad 281"
      d="M211.7,76.256a4.584,4.584,0,1,1,4.584-4.584A4.589,4.589,0,0,1,211.7,76.256Zm0-7.668a3.084,3.084,0,1,0,3.084,3.084A3.087,3.087,0,0,0,211.7,68.588Z"
      transform="translate(-201.931 -60.665)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_282"
      data-name="Pfad 282"
      d="M209.752,65.85a2.592,2.592,0,1,1,2.592-2.593A2.6,2.6,0,0,1,209.752,65.85Zm0-3.685a1.092,1.092,0,1,0,1.092,1.092A1.094,1.094,0,0,0,209.752,62.165Z"
      transform="translate(-201.931 -60.665)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_283"
      data-name="Pfad 283"
      d="M219.51,68.041a3.24,3.24,0,1,1,3.24-3.24A3.244,3.244,0,0,1,219.51,68.041Zm0-4.98a1.74,1.74,0,1,0,1.74,1.74A1.741,1.741,0,0,0,219.51,63.061Z"
      transform="translate(-201.931 -60.665)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_284"
      data-name="Pfad 284"
      d="M204.45,80.19a2.519,2.519,0,1,1,2.519-2.518A2.521,2.521,0,0,1,204.45,80.19Zm0-3.537a1.019,1.019,0,1,0,1.019,1.019A1.02,1.02,0,0,0,204.45,76.653Z"
      transform="translate(-201.931 -60.665)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_285"
      data-name="Pfad 285"
      d="M219,81.185a3.2,3.2,0,1,1,3.2-3.2A3.207,3.207,0,0,1,219,81.185Zm0-4.906a1.7,1.7,0,1,0,1.7,1.7A1.706,1.706,0,0,0,219,76.279Z"
      transform="translate(-201.931 -60.665)"
      :class="fillClass + ' transition-fill'"
    />
    <rect
      id="Rechteck_958"
      data-name="Rechteck 958"
      width="1.5"
      height="2.976"
      transform="matrix(0.937, -0.35, 0.35, 0.937, 7.419, 4.696)"
      :class="fillClass + ' transition-fill'"
    />
    <rect
      id="Rechteck_959"
      data-name="Rechteck 959"
      width="3.967"
      height="1.5"
      transform="matrix(0.733, -0.68, 0.68, 0.733, 12.372, 8.224)"
      :class="fillClass + ' transition-fill'"
    />
    <rect
      id="Rechteck_960"
      data-name="Rechteck 960"
      width="1.5"
      height="3.571"
      transform="matrix(0.594, -0.805, 0.805, 0.594, 11.924, 14.417)"
      :class="fillClass + ' transition-fill'"
    />
    <rect
      id="Rechteck_961"
      data-name="Rechteck 961"
      width="3.616"
      height="1.5"
      transform="matrix(0.81, -0.587, 0.587, 0.81, 3.301, 15.327)"
      :class="fillClass + ' transition-fill'"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CommunicationIcon",
  props: {
    fillClass: {
      type: String,
 
    },
  },
});
</script>
