<template>
  <div class="flex flex-col gap-5">
    <div
      class="flex w-full flex-wrap sm:flex-nowrap   gap-2"
      v-if="GET_CUSTOMER_POSITION != null"
    >
      <input-text
        class="w-full sm:w-1/2 sm:flex-1"
        :id="'name'"
        :label="$t('designation')"
        :name="'name'"
        v-model="GET_CUSTOMER_POSITION.name"
        :labelClass="'bg-white'"
      />

      <input-select
        :id="'receiver'"
        :activeColor="'grey-fontgrey'"
        class="w-full sm:w-1/2 sm:flex-1"
        :name="'receiver'"
        :label="$t('receiver')"
        :labelClass="'bg-white'"
        :nameColumn="'name'"
        :isSearch="true"
        :background="'bg-white'"
        ref="dropdown"
        :options="GET_RECEIVERS"
        :activeItems="
          GET_CUSTOMER_POSITION.anchors ? GET_CUSTOMER_POSITION.anchors : []
        "
        @changeItem="
          (item) => {
            select_receiver(item);
            $refs.dropdown.close();
          }
        "
      />
    </div>
    <div
      class="flex w-full flex-wrap sm:flex-nowrap   gap-2"
      v-if="GET_CUSTOMER_POSITION != null"
    >
      <input-select
        :id="'superior_location'"
        :activeColor="'grey-fontgrey'"
        :name="'superior_location'"
        :label="$t('superior_location')"
        :labelClass="'bg-white'"
        class="w-full sm:w-1/2 sm:flex-1"
        ref="dropdown"
        :isSearch="true"
        :nameColumn="'name'"
        :background="'bg-white'"
        :options="GET_CUSTOMER_POSITIONS"
        :activeItems="
          GET_CUSTOMER_POSITION.parent ? [GET_CUSTOMER_POSITION.parent] : []
        "
        @changeItem="
          (item) => {
            select_super_position(item);
            $refs.dropdown.close();
          }
        "
      />
      <input-select
        v-if="GET_OPTIONS != null"
        :id="'area'"
        name="area"
        class="w-full sm:w-1/2 sm:flex-1"
        :activeColor="'grey-fontgrey'"
        :label="'Area'"
        :labelClass="'bg-white'"
        :background="'bg-white'"
        ref="dropdown"
        :nameColumn="'name'"
        :options="GET_OPTIONS"
        :activeItems="
          GET_CUSTOMER_POSITION.area ? [GET_CUSTOMER_POSITION.area] : []
        "
        @changeItem="
          (item) => {
            select_area(item);

            $refs.dropdown.close();
          }
        "
      />
    </div>

    <div class="mt-5 flex gap-4" v-if="GET_CUSTOMER_POSITION.id == null">
      <custom-button
        :id="'create_position'"
        class="h-11 w-full sm:w-1/4 sm:ml-auto"
        :bgColor="'bg-primary'"
        :textColor="'text-white'"
        :text="$t('add')"
        @click="$emit('createPosition')"
      />
    </div>

    <div class="mt-5 flex gap-4" v-else>
      <custom-button
        class="h-11 w-full sm:w-1/4 ml-auto"
        :bgColor="'bg-grey-fontgrey'"
        :textColor="'text-white'"
        :text="$t('abort')"
        @click="$emit('setBack')"
      />
      <custom-button
        :id="'update_position'"
        class="h-11 w-full sm:w-1/4"
        :bgColor="'bg-primary'"
        :textColor="'text-white'"
        :text="$t('edit_2')"
        @click="$emit('updatePosition')"
      />
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import CustomButton from "../../../components/input/CustomButton.vue";
import InputSelect from "../../../components/input/InputSelect.vue";
import InputText from "../../../components/input/InputText.vue";
import {
  CUSTOMER_POSITIONS_TYPES,
  CUSTOMER_RECEIVERS_TYPES,
  STORE_TYPES,
} from "../../../store/types";
import { computed } from "@vue/runtime-core";
export default {
  components: {
    InputText,
    InputSelect,
    CustomButton,
  },
  emits: ["closeModal", "setBack", "createPosition", "updatePosition"],
  props: {
    editButtons: {
      type: Boolean,
      default: false,
      super_position: {},
      receiver: {},
    },
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    /**
     *  Refs
     */

    /**
     *  State
     */
    const state = reactive({});

    /**
     * emits
     */

    /*
     * Computeds
     */

    const GET_CUSTOMER_POSITION = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.GET_CUSTOMER_POSITION}`
      ];
    });
    const GET_OPTIONS = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.GET_OPTIONS}`
      ];
    });
    const GET_CUSTOMER_POSITIONS = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.GET_CUSTOMER_POSITIONS}`
      ];
    });
    const GET_RECEIVERS = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_RECEIVERS}/${CUSTOMER_RECEIVERS_TYPES.GET_CUSTOMER_RECEIVERS}`
      ];
    });

    /**
     * Functions
     */

    function select_super_position(item) {
      if (GET_CUSTOMER_POSITION.value != null) {
        if (GET_CUSTOMER_POSITION.value.parent == item) {
          GET_CUSTOMER_POSITION.value.parent = null;
        } else {
          GET_CUSTOMER_POSITION.value.parent = item;
        }
      }
    }

    const select_area = (item) => {
      if (GET_CUSTOMER_POSITION.value != null) {
        if (GET_CUSTOMER_POSITION.value.area == null) {
          GET_CUSTOMER_POSITION.value.area = { area: {} };
        }
        if (GET_CUSTOMER_POSITION.value.area == item) {
          GET_CUSTOMER_POSITION.value.area = null;
        } else {
          GET_CUSTOMER_POSITION.value.area = item;
        }
      }
    };

    const select_receiver = (item) => {
      // check if fields != null
      if (GET_CUSTOMER_POSITION.value.anchors == null) {
        GET_CUSTOMER_POSITION.value.anchors = [];
      }

      // check if is an array
      if (Array.isArray(GET_CUSTOMER_POSITION.value.anchors)) {
        const findIndex = GET_CUSTOMER_POSITION.value.anchors.findIndex(
          (myAnchors) => {
            return myAnchors.id == item.id;
          }
        );

        // if fieldIndex != -1 => splicen else push
        if (findIndex != -1) {
          GET_CUSTOMER_POSITION.value.anchors.splice(findIndex, 1);
        } else {
          GET_CUSTOMER_POSITION.value.anchors.push(item);
        }
      }
    };

    return {
      state,
      router,
      store,
      GET_CUSTOMER_POSITION,
      GET_CUSTOMER_POSITIONS,
      GET_RECEIVERS,
      GET_OPTIONS,

      select_super_position,
      select_receiver,
      select_area,
    };
  },
};
</script>
