<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.995"
    height="18.322"
    viewBox="0 0 23.995 18.322"
  >
    <g id="Folder_closed" transform="translate(0.25 0.25)">
      <path
        id="Pfad_417"
        data-name="Pfad 417"
        d="M366.743,326.489H347.631a1.2,1.2,0,0,1-1.175-1.224l-1.014-8.712a1.217,1.217,0,0,1,1.171-1.3h21.15a1.2,1.2,0,0,1,1.175,1.224l-1.023,8.87A1.179,1.179,0,0,1,366.743,326.489Zm-.153-1.245a.09.09,0,0,0,0,.022Zm-18.814-.1H366.6l.986-8.541H346.783Z"
        transform="translate(-345.442 -308.667)"
        :class="fillClass + ' transition-fill'"
      />
      <path
        id="Pfad_417_-_Kontur"
        data-name="Pfad 417 - Kontur"
        d="M366.743,326.739H347.631a1.451,1.451,0,0,1-1.425-1.459l-1.015-8.726a1.593,1.593,0,0,1,.447-1.147,1.365,1.365,0,0,1,.974-.408h21.15a1.452,1.452,0,0,1,1.425,1.474l0,.029-1.022,8.855a1.382,1.382,0,0,1-.409.954A1.418,1.418,0,0,1,366.743,326.739Zm-21.051-10.2,1.014,8.727a.951.951,0,0,0,.925.974h19.112a.922.922,0,0,0,.656-.279.877.877,0,0,0,.264-.614v-.015l0-.015,1.022-8.855a.951.951,0,0,0-.925-.96h-21.15a.869.869,0,0,0-.619.26A1.094,1.094,0,0,0,345.692,316.538Zm21.133,8.852H347.554l-1.052-9.041h21.367l-1.03,8.925h0ZM348,324.89h18.38l.928-8.041H347.064Z"
        transform="translate(-345.442 -308.667)"
        :class="fillClass + ' transition-fill'"
      />
      <path
        id="Pfad_418"
        data-name="Pfad 418"
        d="M367.014,315.86a.67.67,0,0,1-.665-.674v-4.1a63.994,63.994,0,0,1-10.6-.611,10.192,10.192,0,0,1-2.064-.6,6.705,6.705,0,0,0-2.878-.588c-.495.027-1.987.268-2.326.99a1.417,1.417,0,0,0-.088.223v4.688a.665.665,0,1,1-1.33,0l.022-4.955a2.84,2.84,0,0,1,.2-.555c.728-1.553,3.174-1.724,3.45-1.739a7.89,7.89,0,0,1,3.4.671,9.032,9.032,0,0,0,1.8.531,63.168,63.168,0,0,0,10.523.591,1.117,1.117,0,0,1,.822.322,1.308,1.308,0,0,1,.385.938v4.2A.67.67,0,0,1,367.014,315.86Zm-.49-4.782h0Z"
        transform="translate(-345.626 -307.928)"
        :class="fillClass + ' transition-fill'"
      />
      <path
        id="Pfad_418_-_Kontur"
        data-name="Pfad 418 - Kontur"
        d="M367.014,316.11a.921.921,0,0,1-.915-.924v-3.845c-.395.01-.832.014-1.28.014a63.11,63.11,0,0,1-9.108-.635,10.371,10.371,0,0,1-2.113-.613l-.014-.005a6.822,6.822,0,0,0-2.5-.574c-.086,0-.174,0-.262.007-.551.03-1.849.285-2.112.846a1.166,1.166,0,0,0-.064.156v4.649a.915.915,0,1,1-1.83,0l.022-4.988.008-.031a3.088,3.088,0,0,1,.221-.6c.771-1.638,3.185-1.853,3.661-1.879.121-.006.241-.009.359-.009a8.421,8.421,0,0,1,3.146.7l.01,0a8.789,8.789,0,0,0,1.745.515,61.259,61.259,0,0,0,8.826.612c.843,0,1.429-.016,1.642-.023h.06a1.376,1.376,0,0,1,.955.394,1.558,1.558,0,0,1,.46,1.116v4.2A.921.921,0,0,1,367.014,316.11Zm-.415-5.284v4.359a.415.415,0,1,0,.83,0v-4.2a1.057,1.057,0,0,0-.309-.758.873.873,0,0,0-.638-.252h-.012c-.215.007-.808.024-1.658.024a61.767,61.767,0,0,1-8.9-.618,9.169,9.169,0,0,1-1.843-.54l-.011,0a8.013,8.013,0,0,0-2.972-.664c-.109,0-.221,0-.333.009-.261.014-2.574.182-3.237,1.6l0,.006a2.547,2.547,0,0,0-.177.476l-.022,4.923a.415.415,0,1,0,.83,0V310.46l.011-.036a1.658,1.658,0,0,1,.1-.261c.391-.833,1.964-1.1,2.537-1.129.1,0,.193-.007.288-.007a7.219,7.219,0,0,1,2.676.605l.014.005a9.977,9.977,0,0,0,2.015.588,63.808,63.808,0,0,0,9.032.629c.543,0,1.07-.007,1.522-.021l.182-.006h0Z"
        transform="translate(-345.626 -307.928)"
        :class="fillClass + ' transition-fill'"
      />
    </g>
  </svg>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ArchiveIcon",
  props: {
    fillClass: {
      type: String,
      default: "",
    },
  },
});
</script>
