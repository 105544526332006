<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.797"
    height="17.11"
    viewBox="0 0 13.797 17.11"
  >
    <path
      id="Pfad_375"
      data-name="Pfad 375"
      d="M12.668,17.11H1.129A1.1,1.1,0,0,1,0,16.05V1.061A1.1,1.1,0,0,1,1.129,0H7.98a.686.686,0,0,1,.481.194L13.615,5.3a.6.6,0,0,1,.182.428V16.05a1.1,1.1,0,0,1-1.129,1.06M1.325,15.865H12.472V5.978L7.695,1.245H1.325Z"
      :class="fillClass + ' transition-fill'"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "fileIcon",
  props: {
    fillClass: {
      type: String,
      required: false,
    },
  },
});
</script>
