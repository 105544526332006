<template>
  <svg
    id="Subtask"
    xmlns="http://www.w3.org/2000/svg"
    width="15.719"
    height="16.1"
    viewBox="0 0 15.719 16.1"
  >
    <path
      id="Pfad_440"
      data-name="Pfad 440"
      d="M10.8,14.1h-7A3.778,3.778,0,0,1,.1,10.3V.8C0,.3.3,0,.8,0a.736.736,0,0,1,.8.8v9.5a2.242,2.242,0,0,0,2.2,2.3h7a.789.789,0,0,1,.8.8A.709.709,0,0,1,10.8,14.1Z"
      transform="translate(-0.081)"
      :class="fillClass + ' transition-fill'"
    />
    <rect
      id="Rechteck_1045"
      data-name="Rechteck 1045"
      width="10"
      height="1.5"
      transform="translate(0.719 5.5)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_441"
      data-name="Pfad 441"
      d="M12.8,9.2a3,3,0,1,1,3-3A3.009,3.009,0,0,1,12.8,9.2Zm0-4.2A1.2,1.2,0,1,0,14,6.2,1.216,1.216,0,0,0,12.8,5Z"
      transform="translate(-0.081)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_442"
      data-name="Pfad 442"
      d="M12.8,16.1a3,3,0,1,1,3-3A3.076,3.076,0,0,1,12.8,16.1Zm0-4.2A1.2,1.2,0,1,0,14,13.1,1.155,1.155,0,0,0,12.8,11.9Z"
      transform="translate(-0.081)"
      :class="fillClass + ' transition-fill'"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ConnectIcon",
  props: {
    fillClass: {
      type: String,
      default: "",
    },
  },
});
</script>
