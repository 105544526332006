<template>
    <svg
        id="Trash"
        xmlns="http://www.w3.org/2000/svg"
        width="20.5"
        height="19"
        viewBox="0 0 20.5 19"
    >
        <path
            id="Pfad_409"
            data-name="Pfad 409"
            d="M16.75,19h-13A.75.75,0,0,1,3,18.25v-14H4.5V17.5H16V4.25h1.5v14a.75.75,0,0,1-.75.75"
            :class="fillClass + ' transition-fill'"
        />
        <path
            id="Pfad_410"
            data-name="Pfad 410"
            d="M13.75,4.5A.75.75,0,0,1,13,3.75V1.5H7.5V3.75a.75.75,0,0,1-1.5,0v-3A.75.75,0,0,1,6.75,0h7a.75.75,0,0,1,.75.75v3a.75.75,0,0,1-.75.75"
            :class="fillClass + ' transition-fill'"
        />
        <path
            id="Pfad_411"
            data-name="Pfad 411"
            d="M19.75,5H.75a.75.75,0,0,1,0-1.5h19a.75.75,0,0,1,0,1.5"
            :class="fillClass + ' transition-fill'"
        />
        <path
            id="Pfad_412"
            data-name="Pfad 412"
            d="M8.25,14.75A.75.75,0,0,1,7.5,14V8.5A.75.75,0,0,1,9,8.5V14a.75.75,0,0,1-.75.75"
            :class="fillClass + ' transition-fill'"
        />
        <path
            id="Pfad_413"
            data-name="Pfad 413"
            d="M12.25,14.75A.75.75,0,0,1,11.5,14V8.5a.75.75,0,0,1,1.5,0V14a.75.75,0,0,1-.75.75"
            :class="fillClass + ' transition-fill'"
        />
    </svg>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "TrashIcon",
    props: {
        fillClass: {
            type: String,
            default: '',
        },
    },
});
</script>
