export default class Debouncer {
    debounce_time = null;

    /**
     * Used to debounce methods
     *
     * Explanation:Debouncing enforces that a function not be called again until a certain amount of time has passed without it being called.
     * For example, “execute this function * only if 100 milliseconds have passed without it being called.”
     *
     * [callback] function that is fired if debounce stops
     */
    debounce(callback, debounce_delay = 500) {
        this.debounce_time = Date.now();

        setTimeout(() => {
            if (Date.now() - this.debounce_time >= debounce_delay) {
                callback();
                this.debounce_time = null;
            }
        }, debounce_delay);
    }
}
