<template>
  <div class="relative" ref="searchbar">
    <input-text
      id="global-search"
      name="global-search"
      class=" hover:border-grey-fontgrey text-black lg:focus-within:bg-opacity-75"
      v-model="state.search"
      :placeHolder="placeHolder"
      @input="updateInput"
      @focus="state.show = true"
      :class="[darkSearchBar ? 'bg-white-grey' : 'bg-white']"
    >
      <template #icon>
        <search-icon :fillClass="'fill-grey-fontgrey'"></search-icon>
      </template>
    </input-text>
    <!-- <div
      class="absolute top-full mt-2 w-full max-h-0 transition-all overflow-auto scrollbar rounded-md bg-white border-solid border border-grey-light rounded-md "
      :class="
        modelValue != '' && state.show
          ? 'max-h-72'
          : 'p-0 h-0 overflow-hidden border-none'
      "
    >
      <div class="flex flex-col pb-2">
        <template v-for="category in searchItems" :key="category.name">
          <div class="text-sm p-2 mt-1 text-grey">
            {{ category.name }}
          </div>
          <list-transition>
            <searchbar-item
              v-for="item in category.items"
              :item="item"
              :hasImage="category.hasImages"
              :key="item.id"
            >
            </searchbar-item>
          </list-transition>
        </template>
        <div
          v-if="
            state.search != '' &&
              (searchItems == null || searchItems.length == 0)
          "
          class="w-full max-w-full flex items-center text-md h-9 p-1 pl-3 pr-3 mt-1 transition-all"
        >
          <component
            :is="'CommunicationIcon'"
            class="h-4 w-4 mr-3 flex-shrink-0 "
            :fillClass="'fill-grey-dark'"
          ></component>
          <div
            class=" overflow-hidden  whitespace-nowrap  overflow-ellipsis  text-grey-dark "
          >
            Keine Ergebnisse für deine Suche..
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import SearchIcon from "../icons/SearchIcon.vue";

import { clickOutside } from "../../common/customDirectives";
import InputText from "../input/InputText.vue";
export default {
  components: {
    SearchIcon,

    InputText,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    searchItems: {
      type: Array,
    },
    placeHolder: {
      type: String,
    },
    darkSearchBar: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, ctx) {
    /**
     * refs
     */
    const searchbar = ref();
    const input = ref();

    /**
     * state
     */
    const state = reactive({
      search: "",
      show: false,
    });

    /**
     * emits
     */
    function updateInput(event) {
      const newValue = event.target.value;
      ctx.emit("update:modelValue", newValue);
    }

    /**
     * Functions
     */
    function close() {
      state.show = false;
    }

    /**
     * Mounted
     */
    onMounted(() => {
      clickOutside(searchbar.value, close);
    });

    return {
      //refs
      input,
      searchbar,
      //state
      state,
      //emits
      updateInput,
    };
  },
};
</script>

<style></style>
