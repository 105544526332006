<template>
  <fade-transition>
    <div
      class="fixed w-screen h-screen bg-black bg-opacity-50 opacity-100 transition-opacity left-0 top-0 flex items-center justify-center"
      style="z-index: 1000"
      v-if="isLoading"
    >
      <div class="spinner w-10 h-10 relative -mt-32">
        <div class="cube1"></div>
        <div class="cube2"></div>
      </div></div
  ></fade-transition>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { HELPERS_TYPES, STORE_TYPES } from "../../store/types";
import FadeTransition from "../transitions/FadeTransition.vue";

export default defineComponent({
  components: { FadeTransition },
  name: "LoadingAnimation",
  setup() {
    const store = useStore();
    /**
     *  Computed
     */
    const GET_LOADING_IDS = computed(
      () =>
        store.getters[`${STORE_TYPES.HELPERS}/${HELPERS_TYPES.GET_LOADING_IDS}`]
    );
    const isLoading = computed(() => {
      let isLoading = false;
      GET_LOADING_IDS.value.forEach((loadingId) => {
        if (loadingId == "isLoadingData") {
          isLoading = true;
          return;
        }
      });

      return isLoading;
    });
    return {
      isLoading,
    };
  },
});
</script>

<style lang="scss">
.cube1,
.cube2 {
  background-color: #fff;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes sk-cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}
</style>
