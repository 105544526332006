<template>
    <fade-transition>
        <div
            class="modalBackdrop fixed z-50 w-screen  transition-opacity opacity-100 max-h-screen left-0 top-0"
            :class="isDrawer ? 'bottom-12' : 'bottom-0'"
            v-if="isVisible"
            @click="closeModal()"
        >
            <div
                class="w-full h-full absolute bg-opacity-70 bg-black"
            ></div></div
    ></fade-transition>
</template>

<script>
import FadeTransition from "../transitions/FadeTransition";
import { defineComponent } from "vue";

export default defineComponent({
    name: "ModalBackdrop",
    props: {
        isVisible: {
            type: Boolean,
            required: false,
        },
        isDrawer: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["closeModal"],
    components: {
        FadeTransition,
    },
    setup(props, ctx) {
        /**
         * Functions
         */
        function closeModal() {
            ctx.emit("closeModal");
        }

        return {
            closeModal,
        };
    },
});
</script>
