import { POSITIONS_TYPES } from "../types";
import ApiService from "../../common/apiService";
import { getData } from "../../common/dataHelper";

/**  ********************** STATE *********************** */
const state = {
  positions: [],
  position: {},
  delete_position: null,
};

/**  ********************** GETTERS *********************** */
const getters = {
  [`${POSITIONS_TYPES.GET_POSITIONS}`](state) {
    return state.positions;
  },

  [`${POSITIONS_TYPES.GET_POSITION}`](state) {
    return state.position;
  },
  [`${POSITIONS_TYPES.GET_DELETE_POSITION}`](state) {
    return state.delete_position;
  },
};

/**  ********************** ACTIONS *********************** */
const actions = {
  async [`${POSITIONS_TYPES.FETCH_POSITIONS}`](context, { id }) {
    const response = await ApiService.query(
      "api/customers/" + id + "/colliPositions"
    );
    if (getData(response, "status") === 200) {
      context.commit(
        `${POSITIONS_TYPES.SET_POSITIONS}`,
        getData(response, "data")
      );
      return getData(response, "data");
    }
  },

  /** Create  COLLI POSITION */

  /** Update  COLLI POSITION */

  /** DELETE  COLLI POSITION */
};

/**  ********************** MUTATIONS *********************** */
const mutations = {
  [`${POSITIONS_TYPES.SET_POSITIONS}`](state, value) {
    state.positions = value;
  },

  [`${POSITIONS_TYPES.SET_POSITION}`](state, value) {
    state.position = value;
  },
  [`${POSITIONS_TYPES.DELETE_POSITION}`](state, value) {
    state.delete_position = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
