import { CUSTOMER_USERS_TYPES } from "../types";
import ApiService from "../../common/apiService";
import { getData } from "../../common/dataHelper";

/**  ********************** STATE *********************** */
const state = {
  customer_users: [],
  customer_user: {},
  delete_customer_user: null,
};

/**  ********************** GETTERS *********************** */
const getters = {
  [`${CUSTOMER_USERS_TYPES.GET_CUSTOMER_USERS}`](state) {
    return state.customer_users;
  },

  [`${CUSTOMER_USERS_TYPES.GET_CUSTOMER_USER}`](state) {
    return state.customer_user;
  },
  [`${CUSTOMER_USERS_TYPES.GET_DELETE_CUSTOMER_USER}`](state) {
    return state.delete_customer_user;
  },
};

/**  ********************** ACTIONS *********************** */
const actions = {
  async [`${CUSTOMER_USERS_TYPES.FETCH_CUSTOMER_USERS}`](context, id) {
    const response = await ApiService.query("api/customers/" + id + "/users");
    if (getData(response, "status") === 200) {
      context.commit(
        `${CUSTOMER_USERS_TYPES.SET_CUSTOMER_USERS}`,
        getData(response, "data")
      );
      return getData(response, "data");
    }
  },

  /** Create CUSTOMER USER */
  async [`${CUSTOMER_USERS_TYPES.CREATE_CUSTOMER_USER}`](context, id) {
    let customer_user = context.state.customer_user;
    const response = await ApiService.post(
      "api/customers/" + id + "/users",
      customer_user
    );
    if (getData(response, "status") === 201) {
      context.state.customer_users.push(getData(response, "data"));
    }
    return response;
  },

  /** Update CUSTOMER User */

  async [`${CUSTOMER_USERS_TYPES.UPDATE_CUSTOMER_USER}`](
    context,
    { customer_id }
  ) {
    let customer_user = context.state.customer_user;
    const response = await ApiService.update(
      "api/customers/" + customer_id + "/users",
      customer_user.id,
      customer_user
    );
    if (getData(response, "status") === 200) {
      context.commit(
        `${CUSTOMER_USERS_TYPES.SET_CUSTOMER_USER}`,
        getData(response, "data")
      );
      return getData(response, "data");
    }
    if (customer_user != null) {
      const findIndex = context.state.customer_users.findIndex(
        (customerUser) => {
          return customerUser.id == customer_user.id;
        }
      );
      context.state.customer_users.splice(findIndex, 1);
      context.state.delete_customer_user = null;
    }
  },

  /** DELETE CUSTOMER USER */

  async [CUSTOMER_USERS_TYPES.DELETE_CUSTOMER_USER](context, id) {
    let customer_user = context.state.delete_customer_user;
    const response = await ApiService.delete(
      "api/customers/" + id + "/users/" + customer_user.id
    );

    if (customer_user != null) {
      const findIndex = context.state.customer_users.findIndex(
        (customerUser) => {
          return customerUser.id == customer_user.id;
        }
      );
      context.state.customer_users.splice(findIndex, 1);
      context.state.delete_customer_user = null;
    }

    return response;
  },
};

/**  ********************** MUTATIONS *********************** */
const mutations = {
  [`${CUSTOMER_USERS_TYPES.SET_CUSTOMER_USERS}`](state, value) {
    state.customer_users = value;
  },

  [`${CUSTOMER_USERS_TYPES.SET_CUSTOMER_USER}`](state, value) {
    state.customer_user = value;
  },
  [`${CUSTOMER_USERS_TYPES.SET_DELETE_CUSTOMER_USER}`](state, value) {
    state.delete_customer_user = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
