<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.13"
    height="12.765"
    viewBox="0 0 20.13 12.765"
  >
    <g id="Login" transform="translate(20.13 12.765) rotate(180)">
      <path
        id="Pfad_388"
        data-name="Pfad 388"
        d="M14.585,40.488H1.244a1.244,1.244,0,1,1,0-2.488h13.34a1.244,1.244,0,1,1,0,2.488"
        transform="translate(0 -32.863)"
        fill="#0a0a0a"
      />
      <path
        id="Pfad_389"
        data-name="Pfad 389"
        d="M7.8,46.835l-.01-11.1A.831.831,0,0,1,9.2,35.148l5.552,5.542a.831.831,0,0,1,0,1.176L9.216,47.422a.831.831,0,0,1-1.42-.587"
        transform="translate(5.13 -34.903)"
        :class="fillClass + ' transition-fill'"
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TriangleIcon",
  props: {
    fillClass: {
      type: String,
       default: '',


    },
  },
});
</script>
