import ApiService from "../../common/apiService";
import { getData } from "../../common/dataHelper";
import { CUSTOMERS_TYPES } from "../types";

/**  ********************** STATE *********************** */

const state = {
  customer: {},
  customer_settings: {},
};

/**  ********************** GETTERS *********************** */

const getters = {
  [`${CUSTOMERS_TYPES.GET_CUSTOMER}`](state) {
    return state.customer;
  },
  [`${CUSTOMERS_TYPES.GET_CUSTOMER_SETTINGS}`](state) {
    return state.customer_settings;
  },
};

/**  ********************** ACTIONS *********************** */
const actions = {
  /**  FETCH SETTING */
  async [`${CUSTOMERS_TYPES.FETCH_SETTINGS}`](context, id) {
    const response = await ApiService.query(
      "api/customers/" + id + "/settings"
    );
    if (getData(response, "status") === 200) {
      context.commit(
        `${CUSTOMERS_TYPES.SET_CUSTOMER_SETTINGS}`,
        getData(response, "data")
      );

      return getData(response, "data");
    }
  },
  async [`${CUSTOMERS_TYPES.FETCH_CUSTOMER}`](context, { customer_id }) {
    const response = await ApiService.get("api/customers", customer_id);
    if (getData(response, "status") === 200) {
      context.commit(
        `${CUSTOMERS_TYPES.SET_CUSTOMER}`,
        getData(response, "data.customer")
      );
      return getData(response, "data");
    }
  },

  /**  UPDATE CUSTOMER SETTINGS */
  async [`${CUSTOMERS_TYPES.UPDATE_CUSTOMER_SETTINGS}`](context, { id }) {
    const customer_settings = context.state.customer_settings;
    const response = await ApiService.update(
      "api/customers/" + id + "/settings",
      null,
      customer_settings
    );
    if (getData(response, "status") === 200) {
      context.commit(
        `${CUSTOMERS_TYPES.SET_CUSTOMER_SETTINGS}`,
        getData(response, "data")
      );
      return getData(response, "data");
    }
  },
};

/**  ********************** MUTATIONS *********************** */
const mutations = {
  [`${CUSTOMERS_TYPES.SET_CUSTOMER_SETTINGS}`](state, value) {
    state.customer_settings = value;
  },
  [`${CUSTOMERS_TYPES.SET_CUSTOMER}`](state, value) {
    state.customer = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
