<template>
  <router-link
    :to="link.link"
    active-class="active"
    class="h-12 w-70px  flex flex-col items-center justify-center  transition-colors"
    :class="
      checkRouteMatch
        ? 'lg:border-l-2 lg:border-b-0 border-b-2 border-white'
        : 'hover:bg-white hover:bg-opacity-20'
    "
  >
    <component
      v-bind:is="link.icon"
      :fillClass="checkRouteMatch ? 'fill-white' : 'fill-white'"
      :strokeClass="checkRouteMatch ? 'stroke-white' : 'stroke-grey-light'"
    ></component>
    <div class="text-xs text-white font-normal mt-1 lg:mt-3">
      {{ link.name }}
    </div>
  </router-link>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    // Functions
    const checkRouteMatch = computed(() => {
      return route.matched.some(({ path }) => path.includes(props.link.path));
    });

    return {
      checkRouteMatch,
    };
  },
});
</script>

<style></style>
