import { createStore } from "vuex";
import auth from "./modules/auth";
import helpers from "./modules/helpers";
import search from "./modules/search";
import settings from "./modules/settings";
import users from "./modules/users";
import finders from "./modules/finders";
import customerCollis from "./modules/customerCollis";
import customerPositions from "./modules/customerPositions";
import colliPositions from "./modules/colliPositions";
import customerReceivers from "./modules/customerReceivers";
import customerChannels from "./modules/customerChannel";
import dataModels from "./modules/dataModels";
import dataFields from "./modules/dataFields";
import customerUser from "./modules/customerUsers";
import positions from "./modules/positions";
import customers from "./modules/customers";


export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    helpers,
    search,
    settings,
    users,
    finders,
    positions,
    customerCollis,
    customerPositions,
    colliPositions,
    customerReceivers,
    customerChannels,
    dataModels,
    dataFields,
    customerUser,
    customers,
  },
});
