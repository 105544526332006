<template>
  <middle-modal
    :isVisible="isVisible"
    @closeModal="$emit('closeDeleteModal')"
    :title="$t('delete_product_carrier')"
  >
    <div class="flex flex-col w-full">
      <div class="flex gap-5 mt-5">
        <custom-button
          class="h-11 w-1/2"
          :bgColor="'bg-grey-fontgrey'"
          :textColor="'text-white'"
          :text="$t('abort')"
          @click="$emit('closeModal')"
        />

        <custom-button
          :id="'delete'"
          class="h-11 w-1/2 "
          :bgColor="'bg-primary'"
          :textColor="'text-white'"
          :text="$t('delete')"
          @click="$emit('delete')"
        />
      </div>
    </div>
  </middle-modal>
</template>

<script>
import CustomButton from "../../../components/input/CustomButton.vue";

import MiddleModal from "../../../components/layout/MiddleModal.vue";

export default {
  components: {
    MiddleModal,
    CustomButton,
  },

  emits: ["closeDeleteModal", "delete"],

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    placeHolder: {
      type: String,
    },
  },
};
</script>
