<template>
  <svg
    id="Lock_closed"
    data-name="Lock closed"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="19"
    viewBox="0 0 15.5 19.15"
  >
    <path
      id="Pfad_379"
      data-name="Pfad 379"
      d="M14.247,19.15H1.252A1.254,1.254,0,0,1,0,17.9V8.9A1.254,1.254,0,0,1,1.252,7.65H14.247A1.254,1.254,0,0,1,15.5,8.9V17.9a1.254,1.254,0,0,1-1.253,1.253M1.5,17.65H14V9.15H1.5Z"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_380"
      data-name="Pfad 380"
      d="M13.185,8.55h-1.5V5.313a3.77,3.77,0,0,0-1.147-2.694A3.977,3.977,0,0,0,7.745,1.5a3.878,3.878,0,0,0-3.93,3.813V8.55h-1.5V5.313A5.378,5.378,0,0,1,7.745,0a5.467,5.467,0,0,1,3.843,1.549,5.259,5.259,0,0,1,1.6,3.764Z"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_381"
      data-name="Pfad 381"
      d="M9.25,12.4a1.5,1.5,0,1,1-1.5-1.5,1.5,1.5,0,0,1,1.5,1.5"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_382"
      data-name="Pfad 382"
      d="M8.62,13.241l.343,2.077a.5.5,0,0,1-.494.582H7.032a.5.5,0,0,1-.494-.582l.34-2.067"
      :class="fillClass + ' transition-fill'"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "LockClosedIcon",
  props: {
    fillClass: {
      type: String,
      default: "",
    },
  },
});
</script>
