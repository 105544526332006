<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.442"
    height="20.986"
    viewBox="0 0 17.442 20.986"
  >
    <g id="layer1" transform="translate(324.1 -217.377) rotate(90)">
      <path
        id="path3793"
        d="M158.443,120.5a9.366,9.366,0,0,0-6.567,2.7l1.889,1.886a6.7,6.7,0,0,1,9.429.073l1.886-1.886A9.357,9.357,0,0,0,158.443,120.5Z"
        transform="translate(69.293 191.514)"
        fill="#c0c1c3"
        :class="fillClass + ' transition-fill'"
      />
      <path
        id="path3789"
        d="M344.3,495.71a2.72,2.72,0,1,0,2.72,2.72A2.737,2.737,0,0,0,344.3,495.71Z"
        transform="translate(-116.564 -177.051)"
        fill="#c0c1c3"
        :class="fillClass + ' transition-fill'"
      />
      <path
        id="path3797"
        d="M119.767,60.5a14.708,14.708,0,0,0-10.361,4.274l1.889,1.883a12.056,12.056,0,0,1,17,.064l1.886-1.889A14.691,14.691,0,0,0,119.767,60.5Z"
        transform="translate(107.971 246.158)"
        fill="#c0c1c3"
        :class="fillClass + ' transition-fill'"
      />
      <rect
        id="Rechteck_1655"
        data-name="Rechteck 1655"
        width="1.5"
        height="9"
        transform="translate(229.363 322.129) rotate(-90)"
        fill="#c0c1c3"
        :class="fillClass + ' transition-fill'"
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ReceiverIcon",
  props: {
    fillClass: {
      type: String,
      required: false,
    },
  },
});
</script>
