import ApiService from "../../common/apiService";
import { getData } from "../../common/dataHelper";
import { FINDERS_TYPES, HELPERS_TYPES, STORE_TYPES } from "../types";

/**  ********************** STATE *********************** */

const state = {
  collis: [],
  colli: {},
  customer: {},
  positions: [],
  position: {},
  page: 1,
  allCollisLoaded: false,
};

/**  ********************** GETTERS *********************** */

const getters = {
  [`${FINDERS_TYPES.GET_FINDER_COLLIS}`](state) {
    return state.collis;
  },
  [`${FINDERS_TYPES.GET_FINDER_COLLI}`](state) {
    return state.colli;
  },
  [`${FINDERS_TYPES.GET_FINDER_POSITIONS}`](state) {
    return state.positions;
  },
  [`${FINDERS_TYPES.GET_FINDER_POSITION}`](state) {
    return state.position;
  },
  [`${FINDERS_TYPES.GET_PAGE}`](state) {
    return state.page;
  },
  [`${FINDERS_TYPES.GET_ALL_COLLIS_LOADED}`](state) {
    return state.allCollisLoaded;
  },
};

/**  ********************** ACTIONS *********************** */
const actions = {
  /** FETCH FINDER COLLIS */
  async [`${FINDERS_TYPES.FETCH_FINDER_COLLIS}`](context, { id, params }) {
    context.commit(
      `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
      "infinite-loading",
      { root: true }
    );
    const response = await ApiService.query(
      "api/customers/" + id + "/finder/collis",
      params
    );
    const collis = getData(response, "data.collis");
    const links = getData(response, "data.links");
    const meta = getData(response, "data.meta");
    if (getData(response, "status") === 200) {
      if (
        collis != null &&
        params != null &&
        params.page != null &&
        params.page != 1
      ) {
        //Push response collis in old collis
        const oldCollis = context.state.collis;
        const newCollis = oldCollis.concat(collis);
        context.commit(`${FINDERS_TYPES.SET_FINDER_COLLIS}`, newCollis);
      } else if (collis != null) {
        context.commit(`${FINDERS_TYPES.SET_FINDER_COLLIS}`, collis);
      }

      if (meta != null && meta.current_page != null) {
        context.commit(`${FINDERS_TYPES.SET_PAGE}`, meta.current_page);
      }

      if (links.next == null) {
        context.commit(`${FINDERS_TYPES.SET_ALL_COLLIS_LOADED}`, true);
      } else {
        context.commit(`${FINDERS_TYPES.SET_ALL_COLLIS_LOADED}`, false);
      }
      context.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "infinite-loading",
        { root: true }
      );
      return getData(response, "data.collis");
    }
  },

  /** FETCH FINDER POSTIONS */
  async [`${FINDERS_TYPES.FETCH_FINDER_POSITIONS}`](context, id) {
    const response = await ApiService.query(
      "api/customers/" + id + "/finder/positions"
    );
    if (getData(response, "status") === 200) {
      context.commit(
        `${FINDERS_TYPES.SET_FINDER_POSITIONS}`,
        getData(response, "data")
      );
      return getData(response, "data");
    }
  },
};

/**  ********************** MUTATIONS *********************** */
const mutations = {
  [`${FINDERS_TYPES.SET_FINDER_COLLIS}`](state, value) {
    state.collis = value;
  },

  [`${FINDERS_TYPES.SET_FINDER_COLLI}`](state, value) {
    state.colli = value;
  },
  [`${FINDERS_TYPES.SET_FINDER_POSITIONS}`](state, value) {
    state.positions = value;
  },
  [`${FINDERS_TYPES.SET_FINDER_POSITION}`](state, value) {
    state.position = value;
  },
  [`${FINDERS_TYPES.SET_PAGE}`](state, value) {
    state.page = value;
  },
  [`${FINDERS_TYPES.SET_ALL_COLLIS_LOADED}`](state, bool) {
    state.allCollisLoaded = bool;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
