<template>
  <modal-backdrop :isVisible="isVisible" @closeModal="closeModal()" />
  <height-transition>
    <div
      v-if="isVisible"
      class="fixed z-50 w-screen h-screen -bottom-8 left-0 flex justify-center transition-all items-center"
      @click.self="closeModal()"
    >
      <div
        ref="modal"
        class="bg-white rounded-lg pt-8 pb-5 px-8"
        :class="classes"
      >
        <div
          class="w-full overflow-y-auto scrollbar pr-1 flex-col flex justify-start items-center"
        >
          <h2 class="font-semibold mb-3">{{ title }}</h2>

          <slot></slot>
        </div>
      </div>
    </div>
  </height-transition>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { HELPERS_TYPES, STORE_TYPES } from "../../store/types";
import HeightTransition from "../transitions/HeightTransition.vue";
import ModalBackdrop from "./ModalBackdrop.vue";

export default defineComponent({
  name: "BottomUpModal",
  components: { HeightTransition, ModalBackdrop },
  emits: ["closeModal"],
  props: {
    isVisible: {
      type: Boolean,
      required: false,
    },
    classes: {
      type: String,
      default: "w-full max-w-sm h-auto max-h-full",
    },
    title: {
      type: String,
      required: false,
    },
  },
  setup(props, ctx) {
    const store = useStore();
    /**
     *  Refs
     */
    const modal = ref(null);

    /**
     *  State
     */
    const state = reactive({
      interval: false,
      countDragSeconds: 0,
      isDownDirection: false,
    });

    /**
     * Functions
     */

    function REMOVE_ERROR_ID() {
      store.commit(`${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_ERROR_IDS}`, {});
    }
    function closeModal() {
      REMOVE_ERROR_ID();
      ctx.emit("closeModal");
    }

    return { state, modal, closeModal };
  },
});
</script>
