<template>
  <div>
    <navbar></navbar>
    <div v-if="$route.meta.isLoggedIn">
      <sidebar></sidebar>
      <main>
        <router-view
          class="routerview pt-4 pl-4 pb-4 pr-2 mr-2 transition-all scrollbar overflow-y-auto"
          :class="GET_IS_SIDEBAR_ACTIVE ? 'lg:ml-70px' : ''"
        />
      </main>
    </div>
    <div v-else>
      <router-view />
    </div>
    <loading-animation />
  </div>
</template>

<script>
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { STORE_TYPES, HELPERS_TYPES } from "./store/types";
import Navbar from "./components/layout/Navbar.vue";
import Sidebar from "./components/layout/Sidebar.vue";
import LoadingAnimation from "./components/global/LoadingAnimation.vue";
import { useRoute, useRouter } from "vue-router";
import {pusherStore} from "./common/thePusherService";

export default defineComponent({
  components: { Sidebar, Navbar, LoadingAnimation },

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    pusherStore.initPusher();

    /** State */
    const state = reactive({});

    /**
     *  Computed
     */
    const GET_IS_SIDEBAR_ACTIVE = computed(
      () =>
        store.getters[
          `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.GET_IS_SIDEBAR_ACTIVE}`
        ]
    );

    return {
      GET_IS_SIDEBAR_ACTIVE,
      router,
      route,
      state,
    };
  },
});
</script>

<style lang="scss" scoped>
.routerview {
  height: calc(100vh - 112px);
  @screen lg {
    max-height: calc(100vh - 64px);
  }
}
</style>
