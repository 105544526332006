<template>
  <svg
    id="Company"
    xmlns="http://www.w3.org/2000/svg"
    width="16.944"
    height="20.93"
    viewBox="0 0 16.944 20.93"
  >
    <path
      id="Pfad_295"
      data-name="Pfad 295"
      d="M246.465,81.185H231.637a1.059,1.059,0,0,1-1.058-1.058V62.733a1.068,1.068,0,0,1,.685-.993,21.153,21.153,0,0,1,15.448-.048,1.259,1.259,0,0,1,.811,1.174V80.127A1.059,1.059,0,0,1,246.465,81.185Zm-14.386-1.5h13.944V63.036a19.662,19.662,0,0,0-13.944,0ZM231.8,63.141h0Z"
      transform="translate(-230.579 -60.255)"
     :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_296"
      data-name="Pfad 296"
      d="M240.9,81.185h-3.7a1.063,1.063,0,0,1-1.061-1.062V76.247a1,1,0,0,1,.474-.883,4.687,4.687,0,0,1,4.856-.011.986.986,0,0,1,.494.894v3.876A1.063,1.063,0,0,1,240.9,81.185Zm-3.263-1.5h2.824V76.526a3.157,3.157,0,0,0-2.824-.01Z"
      transform="translate(-230.579 -60.255)"
      fill="#fff"
    />
    <rect
      id="Rechteck_962"
      data-name="Rechteck 962"
      width="2.471"
      height="3.089"
      rx="0.497"
      transform="translate(3.53 4.117)"
      fill="#fff"
    />
    <rect
      id="Rechteck_963"
      data-name="Rechteck 963"
      width="2.471"
      height="3.089"
      rx="0.497"
      transform="translate(7.236 4.117)"
      fill="#fff"
    />
    <rect
      id="Rechteck_964"
      data-name="Rechteck 964"
      width="2.471"
      height="3.089"
      rx="0.497"
      transform="translate(7.236 9.059)"
      fill="#fff"
    />
    <rect
      id="Rechteck_965"
      data-name="Rechteck 965"
      width="2.471"
      height="3.089"
      rx="0.497"
      transform="translate(3.53 9.059)"
     :class="fillClass + ' transition-fill'"
    />
    <rect
      id="Rechteck_966"
      data-name="Rechteck 966"
      width="2.471"
      height="3.089"
      rx="0.497"
      transform="translate(10.943 9.059)"
      :class="fillClass + ' transition-fill'"
    />
    <rect
      id="Rechteck_967"
      data-name="Rechteck 967"
      width="2.471"
      height="3.089"
      rx="0.497"
      transform="translate(10.943 4.117)"
      :class="fillClass + ' transition-fill'"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "CustomersIcon",
    props: {
        fillClass: {
            type: String,
            default: "fill-white",
        },
    },
});
</script>
