export function clickOutside(HTMLElement, caseOutsideFunction, once = false) {
    const abortController = new AbortController();
    document.body.addEventListener(
        "click",
        (event) => {
            if (
                HTMLElement != null &&
                (HTMLElement == event.target ||
                    HTMLElement.contains(event.target))
            ) {
                return true;
            } else {
                caseOutsideFunction();
                if (once) {
                    abortController.abort();
                }
                return false;
            }
        },
        { signal: abortController.signal }
    );
    return abortController;
}
export function reverseClickOutside(HTMLElement, caseOutsideFunction) {
    const abortController = new AbortController();
    document.body.addEventListener(
        "click",
        (event) =>
            reverseClickOutsideFunc(
                event,
                HTMLElement,
                caseOutsideFunction,
                abortController
            ),
        { signal: abortController.signal }
    );
    return abortController;
}
function reverseClickOutsideFunc(
    event,
    HTMLElement,
    caseOutsideFunction,
    abortController,
    ignoreLinks = true,
    ignoreDropdowns = true
) {
    var a = event.target;
    var els = [];
    while (a) {
        els.unshift(a);
        a = a.parentNode;
    }
    if (
        els != null &&
        (HTMLElement == event.target ||
            els.some((el) => {
                let allowClick = el.id == HTMLElement.id;

                if (ignoreLinks) {
                    allowClick = allowClick || el.tagName == "A";
                }
                if (ignoreDropdowns) {
                    allowClick =
                        allowClick ||
                        (el.classList != null &&
                            el.classList.contains("dropdown"));
                }
                return allowClick;
            }))
    ) {
        return true;
    } else {
        abortController.abort();
        caseOutsideFunction();
        return false;
    }
}
