import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import translation from "./common/translation";
import { registerIcons } from "./common/icons";
import "./assets/css/index.css";
import apiService from "./common/apiService";

const app = createApp(App);

registerIcons(app);

apiService.init();

app
  .use(store)
  .use(router)
  .use(translation)
  .mount("#app");

export default app;
