<template>
  <div
    class="dropdown relative flex justify-center items-center content-center"
    ref="dropdowncomponent"
  >
    <div
      class="overflow-hidden cursor-pointer focus:outline-none transform transition-all flex items-center"
      @click="openDropdown"
      :class="
        state.isDropdownActive && onOpendRotate == true
          ? 'rotate-45 bg-white-dark rounded-full active'
          : ''
      "
      ref="trigger"
    >
      <slot name="trigger"></slot>
    </div>
    <div
      class="z-20 bg-white rounded-xl absolute flex flex-col opacity-0 h-auto max-h-0 right-0  w-56 transition-collapse shadow-sm"
      ref="dropdown"
      :class="[
        state.isDropdownActive
          ? 'border border-grey-light py-1 opacity-100 max-h-80 '
          : 'overflow-hidden',
        sizeClass,
        state.isDropdownActive && !isScrollable ? 'overflow-visible' : '',
        state.isDropdownActive && isScrollable ? 'scrollbar' : '',
      ]"
    >
      <slot name="items-prepend"></slot>
      <div
        :class="
          $slots.items.prepend != null ? 'max-h-56 scrollbar overflow-auto' : ''
        "
        :style="
          !isScrollable && state.isDropdownActive
            ? 'overflow: visible !important;'
            : ''
        "
      >
        <slot name="items"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeUnmount, reactive, ref } from "vue";
import { clickOutside } from "../../common/customDirectives";
export default defineComponent({
  props: {
    size: {
      type: String,
      default: "small",
    },
    expandMobile: {
      type: Boolean,
      default: false,
    },
    isScrollable: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    onOpendRotate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["toggle"],
  setup(props, ctx) {
    const dropdowncomponent = ref();
    const dropdown = ref();
    const trigger = ref();

    /**
     * State
     */
    const state = reactive({
      isDropdownActive: false,
    });

    /**
     * Computed
     */
    const sizeClass = computed(() => {
      if (props.size == "small")
        return state.isDropdownActive ? "max-h-80 w-56" : "w-56";
      if (props.size == "medium")
        return state.isDropdownActive ? "max-h-80 w-64" : "w-64";
      if (props.size == "large")
        return state.isDropdownActive ? "max-h-96 w-96" : "w-96";
      if (props.size == "xlarge")
        return state.isDropdownActive ? "max-h-420px w-96" : "w-96";
      return null;
    });

    const sizePX = computed(() => {
      if (props.size == "small") return 224;
      if (props.size == "medium") return 256;
      if (props.size == "large") return 384;
      if (props.size == "xlarge") return 384;

      return 0;
    });

    const maxHeightPX = computed(() => {
      if (props.size == "small") return 320;
      if (props.size == "medium") return 320;
      if (props.size == "large") return 384;
      if (props.size == "xlarge") return 420;

      return 0;
    });

    /**
     * Functions
     */
    const moveDropdownToTrigger = function() {
      let offsetLeft = trigger.value.getBoundingClientRect().left;

      if (
        window.innerWidth - offsetLeft > sizePX.value &&
        window.innerWidth > window.innerWidth - offsetLeft
      ) {
        // Wenn nach recths genug Platz
        let styleOffsetLeft = trigger.value.clientWidth * 0.1;
        dropdown.value.style.left = styleOffsetLeft + "px";
        dropdown.value.style.width = sizePX.value + "px";
      } else if (
        window.innerWidth >
        window.innerWidth - offsetLeft + sizePX.value
      ) {
        // Wenn nach links genug Platz
        let styleOffsetLeft = trigger.value.clientWidth / 1.1;
        dropdown.value.style.left = styleOffsetLeft - sizePX.value + "px";
        dropdown.value.style.width = sizePX.value + "px";
      } else if (props.expandMobile) {
        // Wenn in beide Reichtungen zu wenig Platz & mobile expand aktiviert
        dropdown.value.style.width = "calc(100% - 10px)";
        dropdown.value.style.left = "5px";
        dropdown.value.style.right = "5px";
      }

      let totalDropdownHeight =
        maxHeightPX.value + trigger.value.clientHeight / 2;
      if (
        window.innerHeight >
        trigger.value.getBoundingClientRect().top + totalDropdownHeight
      ) {
        let offsetTop = trigger.value.clientHeight / 2;
        dropdown.value.style.top = offsetTop + 25 + "px";
      } else {
        dropdown.value.style.bottom = 15 + "px";
      }
    };

    let clickOutsideController = null;
    function openDropdown() {
      if (props.isDisabled) return;
      clickOutsideController = clickOutside(
        dropdowncomponent.value,
        closeDropdown,
        true
      );
      state.isDropdownActive = !state.isDropdownActive;
      moveDropdownToTrigger();
      window.addEventListener("resize", moveDropdownToTrigger);
      ctx.emit("toggle", state.isDropdownActive);
    }

    function closeDropdown() {
      if (state.isDropdownActive == true) {
        state.isDropdownActive = false;
        window.removeEventListener("resize", moveDropdownToTrigger);
      }
    }

    /**
     * Before Unmounted
     */
    onBeforeUnmount(() => {
      window.removeEventListener("resize", moveDropdownToTrigger);
      if (clickOutsideController != null) {
        clickOutsideController.abort();
      }
    });
    return {
      // Refs
      dropdowncomponent,
      dropdown,
      trigger,

      // State
      state,

      // Computed
      sizeClass,

      // Functions
      openDropdown,
      closeDropdown,
      moveDropdownToTrigger,
    };
  },
});
</script>

<style></style>
