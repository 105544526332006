<template>
  <svg
    id="Location"
    xmlns="http://www.w3.org/2000/svg"
    width="15.8"
    height="19.8"
    viewBox="0 0 15.8 19.8"
  >
    <path
      id="Pfad_432"
      data-name="Pfad 432"
      d="M7.9,19.8a1.777,1.777,0,0,1-1-.4C4.8,17.1,0,11.4,0,7.9A7.853,7.853,0,0,1,7.9,0a7.853,7.853,0,0,1,7.9,7.9c0,3.5-4.8,9.2-6.9,11.5A1.433,1.433,0,0,1,7.9,19.8Zm0-18.3A6.445,6.445,0,0,0,1.5,7.9c0,2.4,3.3,6.9,6.4,10.4,3.1-3.5,6.4-8,6.4-10.4A6.511,6.511,0,0,0,7.9,1.5Z"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_433"
      data-name="Pfad 433"
      d="M7.9,11.3A3.543,3.543,0,0,1,4.4,7.8,3.543,3.543,0,0,1,7.9,4.3a3.543,3.543,0,0,1,3.5,3.5A3.543,3.543,0,0,1,7.9,11.3Zm0-5.4a2,2,0,1,0,2,2A2.006,2.006,0,0,0,7.9,5.9Z"
      :class="fillClass + ' transition-fill'"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "LocationIcon",
  props: {
    fillClass: {
      type: String,
      default: "",
    },
  },
});
</script>
