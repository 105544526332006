import { USERS_TYPES } from "../types";
import ApiService from "../../common/apiService";
import { getData } from "../../common/dataHelper";

const state = {
  users: [],
  user: {},
};
const getters = {
  [USERS_TYPES.GET_USERS]: (state) => state.users,
  [`${USERS_TYPES.GET_USERS}`](state) {
    return state.users;
  },

  [`${USERS_TYPES.GET_USER}`](state) {
    return state.user;
  },
};
const actions = {
  //   async [USERS_TYPES.FETCH_USERS]({ commit }) {
  //     const response = await ApiService.query(`users`);
  //     if (getData(response, "data.users")) {
  //       commit(`${USERS_TYPES.SET_USERS}`, getData(response, "data.users"));
  //     }
  //   },
  [`${USERS_TYPES.FETCH_USERS}`](context) {
    let users = [
      {
        id: 1,
        first_name: "Dima",
        last_name: "Mohammad",
        email: "d.mohammad@reanmo.com",
        role: "Standard",
        roles: [],
      },
      {
        id: 2,
        first_name: "Max",
        last_name: "Musermann",
        email: "m.mustermann@reanmo.com",
        role: "Standard",
        roles: [],
      },
      {
        id: 3,
        first_name: "Manfred",
        last_name: "Terhaar",
        email: "m.Terhaar@reanmo.com",
        role: "Standard",
        roles: [],
      },
      {
        id: 4,
        first_name: "L.",
        last_name: "Revers",
        email: "d.mohammad@reanmo.com",
        role: "Standard",
        roles: [],
      },
    ];
    context.commit(`${USERS_TYPES.SET_USERS}`, users);
  },

  [`${USERS_TYPES.FETCH_USER}`](context, id) {
    let user = {
      id: id,
      first_name: "Dima",
      last_name: "Mohammad",
      email: "d.mohammad@reanmo.com",
      role: "Admin",
    };
    context.commit(`${USERS_TYPES.SET_USER}`, user);
  },

  async [USERS_TYPES.CREATE_USER]({ state }, { user }) {
    const response = await ApiService.post(`users`, user);
    if (getData(response, "data.user")) {
      state.users.push(getData(response, "data.user"));
    }
    return response;
  },

  async [USERS_TYPES.UPDATE_USER](context, { user }) {
    const response = await ApiService.update(`users`, user.id, user);
    if (getData(response, "data.user")) {
      const findIndex = context.state.users.findIndex((stateUser) => {
        return stateUser.id == user.id;
      });
      context.state.users[findIndex] = getData(response, "data.user");
    }
    return response;
  },
  async [USERS_TYPES.DELETE_USER](context, { user }) {
    const response = await ApiService.delete(`users/${user.id}`);
    if (response.status == 204) {
      const findIndex = context.state.users.findIndex((stateUser) => {
        return stateUser.id == user.id;
      });
      context.state.users.splice(findIndex, 1);
    }
    return response;
  },
};
const mutations = {
  [USERS_TYPES.SET_USERS]: (state, array) => (state.users = array),

  [`${USERS_TYPES.SET_USERS}`](state, value) {
    state.users = value;
  },

  [`${USERS_TYPES.SET_USER}`](state, value) {
    state.user = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
