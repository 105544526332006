<template>
  <div class="mb-6 mt-4">
    <div class="loading-spinner" v-if="isActive">
      <div class="bounce1 bg-primary"></div>
      <div class="bounce2 bg-primary"></div>
      <div class="bounce3 bg-primary"></div>
    </div>

    <empty-list-feedback
      v-else
      :color="'primary'"
      class="self-center w-full items-center justify-center flex"
      :text="emptyText"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { HELPERS_TYPES, STORE_TYPES } from "../../store/types";
import EmptyListFeedback from "./EmptyListFeedback.vue";
export default defineComponent({
  name: "InfiniteLoading",
  components: { EmptyListFeedback },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    scrollBoxId: {
      type: String,
      required: true,
    },
    emptyText: { type: String, required: true },
  },
  emits: ["load"],
  setup(props, ctx) {
    const store = useStore();
    /**
     *  Computed
     */
    const isLoading = computed(() => {
      const loadingIds =
        store.getters[
          `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.GET_LOADING_IDS}`
        ];
      return loadingIds.some((loadingId) => loadingId == "infinite-loading");
    });
    /**
     * Mounted
     */
    onMounted(() => {
      const element = document.getElementById(props.scrollBoxId);
      element.addEventListener("scroll", () => {
        if (
          element.scrollHeight - element.offsetHeight <= //arrived height
          element.scrollTop + 100 // height when emit getting fired
        ) {
          if (isLoading.value == false && props.isActive == true) {
            ctx.emit("load");
          }
        }
      });
    });
    return {};
  },
});
</script>
<style lang="scss">
.loading-spinner {
  margin: 0 auto 0;
  width: 60px;
  height: 21px;
  text-align: center;
}
.loading-spinner > div {
  width: 10px;
  height: 10px;
  margin-right: 7px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.loading-spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loading-spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
