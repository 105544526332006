<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.414"
        height="18.135"
        viewBox="0 0 19.414 18.135"
    >
        <g
            id="Gruppe_236"
            data-name="Gruppe 236"
            transform="translate(-35.25 -65.25)"
        >
            <path
                id="Pfad_252"
                data-name="Pfad 252"
                d="M42.5,79.75a7.25,7.25,0,1,1,7.25-7.25A7.258,7.258,0,0,1,42.5,79.75Zm0-13a5.75,5.75,0,1,0,5.75,5.75A5.757,5.757,0,0,0,42.5,66.75Z"
                :class="fillClass + ' transition-fill'"
            />
            <path
                id="Rechteck_918"
                data-name="Rechteck 918"
                d="M0,0H1.5a0,0,0,0,1,0,0V7.95a.5.5,0,0,1-.5.5H.5a.5.5,0,0,1-.5-.5V0A0,0,0,0,1,0,0Z"
                transform="translate(47.361 77.759) rotate(-48.25)"
                :class="fillClass + ' transition-fill'"
            />
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "SearchIcon",
    props: {
        fillClass: {
            type: String,
            required: true,
        },
    },
});
</script>
