<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.082"
    height="10.081"
    viewBox="0 0 10.082 10.081"
  >
    <g id="Plus" transform="translate(5.041 -4.176) rotate(45)">
      <rect
        id="Rechteck_973"
        data-name="Rechteck 973"
        width="1.222"
        height="13.035"
        rx="0.5"
        transform="translate(5.906)"
        :class="fillClass + ' transition-fill'"
      />
      <rect
        id="Rechteck_974"
        data-name="Rechteck 974"
        width="1.222"
        height="13.035"
        rx="0.5"
        transform="translate(13.035 5.906) rotate(90)"
        :class="fillClass + ' transition-fill'"
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "PlucIcon2",
  props: {
    fillClass: {
      type: String,
      default: "",
    },
  },
});
</script>
