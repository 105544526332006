<template>
  <div class="flex flex-col mb-5">
    <div>
      <div class="flex w-full flex-nowrap sm:flex-wrap  gap-3">
        <div
          class="w-1/3 mb-5 sm:flex-1"
          v-if="
            GET_CUSTOMER.is_auto_name == false || GET_CUSTOMER_COLLI.id != null
          "
        >
          <input-text
          data-testId="designation-field"
            :id="'name'"
            :label="$t('designation')"
            :name="'name'"
            :labelClass="'bg-white'"
            v-model="GET_CUSTOMER_COLLI.name"
          ></input-text>
        </div>
        <div
          class="w-1/3 mb-5 sm:flex-1 "
          v-if="
            GET_CUSTOMER.is_auto_name == true && GET_CUSTOMER_COLLI.id == null
          "
        >
          <input-text
            :id="'name'"
            :label="$t('designation')"
            :name="'name'"
            :labelClass="'bg-white'"
            v-model="GET_CUSTOMER.id_count"
            :disabled="true"
          ></input-text>
        </div>
        <input-select
          :id="'channel'"
          :activeColor="'grey-fontgrey'"
          :label="$t('channel')"
          class="w-1/3 mb-5 sm:flex-1"
          :labelClass="'bg-white'"
          :background="'bg-white'"
          :nameColumn="'name'"
          :isSearch="true"
          ref="dropdown"
          :options="GET_CHANNELS"
          :activeItems="GET_CUSTOMER_COLLI.tag ? [GET_CUSTOMER_COLLI.tag] : []"
          @changeItem="
            (item) => {
              select_channel(item);
              $refs.dropdown.close();
            }
          "
        />
        <input-select
          :id="'group'"
          :activeColor="'grey-fontgrey'"
          :label="$t('group')"
          :labelClass="'bg-white'"
          class="w-1/3 mb-5 sm:flex-1"
          :background="'bg-white'"
          :nameColumn="'name'"
          :isSearch="true"
          ref="dropdown"
          :options="GET_GROUPS"
          :activeItems="
            GET_CUSTOMER_COLLI.data != null &&
            GET_CUSTOMER_COLLI.data.dataModel != null
              ? [GET_CUSTOMER_COLLI.data.dataModel]
              : []
          "
          @changeItem="
            (item) => {
              select_group(item);
              $refs.dropdown.close();
            }
          "
        />
      </div>
      <div class="w-full flex flex-nowrap sm:flex-wrap gap-3">
        <!-- <input-select
          v-if="GET_CUSTOMER_COLLI.tag == null"
          :id="'superior_product_carrier'"
          :activeColor="'grey-fontgrey'"
          :name="'superior_product_carrier'"
          :label="$t('superior_product_carrier')"
          :labelClass="'bg-white'"
          :background="'bg-white'"
          :nameColumn="'name'"
          :isSearch="true"
          ref="dropdown"
          class="w-1/2 sm:w-1/3 mb-5 sm:flex-1"
          :options="GET_CUSTOMER_COLLIS"
          :activeItems="
            GET_CUSTOMER_COLLI.parent ? [GET_CUSTOMER_COLLI.parent] : []
          "
          @changeItem="
            (item) => {
              select_super_colli(item);
              $refs.dropdown.close();
            }
          "
        /> -->
        <input-select
          :id="'sub_product_carrier'"
          :activeColor="'grey-fontgrey'"
          :name="'´sub_product_carrier'"
          :label="$t('positions')"
          :labelClass="'bg-white'"
          :background="'bg-white'"
          :nameColumn="'name'"
          :isSearch="true"
          ref="dropdown"
          class="w-1/2 sm:w-1/3 mb-5 sm:flex-1"
          :options="GET_COLLI_POSITIONS"
          :activeItems="
            GET_CUSTOMER_COLLI.colliPositions
              ? GET_CUSTOMER_COLLI.colliPositions
              : []
          "
          @changeItem="
            (item) => {
              select_sub_colli(item);
              $refs.dropdown.close();
            }
          "
        />
      </div>
    </div>
    <!------------------- individual fields  --------------->

    <div
      v-if="
        GET_CUSTOMER_COLLI.data != null &&
          GET_CUSTOMER_COLLI.data.dataModel != null &&
          GET_CUSTOMER_COLLI.data.dataModel.dataFields != null
      "
    >
      {{ $t("individual_fields") }}

      <div class="flex w-full flex-wrap gap sm:gap-3 mt-5">
        <div
          class="flex sm:w-1/3-3  mb-2"
          v-for="field of GET_CUSTOMER_COLLI.data.dataModel.dataFields"
          :key="field.id"
        >
          <input-text
            v-if="field.type != 'boolean'"
            class="w-full"
            :id="field.key"
            :type="field.type == 'numeric' ? 'number' : 'text'"
            :label="
              field.type == 'numeric' && field.required == true
                ? field.key + ' *' + ' (Zahl)'
                : field.type == 'numeric' && field.required == false
                ? field.key + ' (Zahl)'
                : field.type == 'string' && field.required == true
                ? field.key + ' *' + ' (Text)'
                : field.type == 'string' && field.required == false
                ? field.key + ' (Text)'
                : field.key
            "
            :name="field.key"
            :labelClass="'bg-white'"
            v-model="GET_CUSTOMER_COLLI.data.data[field.key]"
          >
          </input-text>

          <input-checkbox
            class="w-full"
            :id="field.key"
            :label="field.key"
            :name="field.key"
            :labelClass="'bg-white'"
            :modelValue="GET_CUSTOMER_COLLI.data.data[field.key]"
            @change="
              GET_CUSTOMER_COLLI.data.data[field.key] =
                GET_CUSTOMER_COLLI.data.data[field.key] == true ? false : true
            "
            v-else
          >
          </input-checkbox>
        </div>
      </div>
    </div>

    <!-- _______________________________________--->
    <div class="flex " v-if="GET_CUSTOMER_COLLI.id == null">
      <custom-button
        :id="'create_colli'"
        class="h-11 w-full sm:w-1/4 sm:ml-auto"
        :bgColor="'bg-primary'"
        :textColor="'text-white'"
        :text="$t('add')"
        @click="$emit('createColli')"
      />
    </div>

    <div class="mt-5 flex gap-4" v-else>
      <custom-button
        data-testId="abort-button"
        class="h-11 w-full sm:w-1/4 ml-auto"
        :bgColor="'bg-grey-fontgrey'"
        :textColor="'text-white'"
        :text="$t('abort')"
        @click="$emit('setBack')"
      />
      <custom-button
      data-testId="edit-button"
        class="h-11 w-full sm:w-1/4"
        :bgColor="'bg-primary'"
        :textColor="'text-white'"
        :text="$t('edit_2')"
        :id="'update'"
        @click="$emit('updateColli')"
      />
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import InputText from "../../../components/input/InputText.vue";
import InputSelect from "../../../components/input/InputSelect.vue";
import CustomButton from "../../../components/input/CustomButton.vue";
import { useStore } from "vuex";
import {
  COLLI_POSITIONS_TYPES,
  CUSTOMERS_TYPES,
  CUSTOMER_CHANNELS_TYPES,
  CUSTOMER_COLLIS_TYPES,
  DATA_MODELS_TYPES,
  STORE_TYPES,
} from "../../../store/types";
import { useRouter } from "vue-router";
import InputCheckbox from "../../../components/input/InputCheckbox.vue";

export default {
  name: "AddProductCarrier",
  components: { InputText, InputSelect, CustomButton, InputCheckbox },
  emits: ["open", "closeModal", "setBack", "updateColli", "createColli"],

  setup() {
    const store = useStore();
    const router = useRouter();
    /**
     *  Refs
     */
    /**
     *  State
     */
    const state = reactive({
      isOpen: false,
      openDeleteModal: false,
      changeTitle: false,
    });

    /*
     * Computeds
     */

    const GET_CUSTOMER_COLLI = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.GET_CUSTOMER_COLLI}`
      ];
    });
    const GET_CUSTOMER = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMERS}/${CUSTOMERS_TYPES.GET_CUSTOMER}`
      ];
    });
    const GET_COLLI_POSITIONS = computed(
      () =>
        store.getters[
          `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.GET_COLLI_POSITIONS}`
        ]
    );

    const GET_CUSTOMER_COLLIS = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.GET_CUSTOMER_COLLIS}`
      ];
    });
    const GET_CHANNELS = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_CHANNELS}/${CUSTOMER_CHANNELS_TYPES.GET_CUSTOMER_CHANNELS}`
      ];
    });
    const GET_GROUPS = computed(() => {
      return store.getters[
        `${STORE_TYPES.DATA_MODELS}/${DATA_MODELS_TYPES.GET_DATA_MODELS}`
      ];
    });

    // const GET_COLLI_TAG = computed(() => {
    //   return store.getters[
    //     `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.GET_COLLI_TAG}`
    //   ];
    // });

    /**
     * Functions
     */
    const select_channel = (item) => {
      if (GET_CUSTOMER_COLLI.value != null) {
        if (GET_CUSTOMER_COLLI.value.tag == item) {
          GET_CUSTOMER_COLLI.value.tag = null;
        } else {
          GET_CUSTOMER_COLLI.value.tag = item;
        }
      }
    };

    const select_group = (item) => {
      if (GET_CUSTOMER_COLLI.value != null) {
        if (GET_CUSTOMER_COLLI.value.data == null) {
          GET_CUSTOMER_COLLI.value.data = { data: {} };
        }
        if (GET_CUSTOMER_COLLI.value.data.dataModel == item) {
          GET_CUSTOMER_COLLI.value.data.dataModel = null;
        } else {
          GET_CUSTOMER_COLLI.value.data.dataModel = item;
        }
      }
    };

    const select_super_colli = (item) => {
      if (GET_CUSTOMER_COLLI.value != null) {
        if (GET_CUSTOMER_COLLI.value.parent == item) {
          GET_CUSTOMER_COLLI.value.parent = null;
        } else {
          GET_CUSTOMER_COLLI.value.parent = item;
        }
      }
    };
    const select_sub_colli = (item) => {
      if (GET_CUSTOMER_COLLI.value.colliPositions == null) {
        GET_CUSTOMER_COLLI.value.colliPositions = [];
      }
      if (Array.isArray(GET_CUSTOMER_COLLI.value.colliPositions)) {
        const findIndex = GET_CUSTOMER_COLLI.value.colliPositions.findIndex(
          (onePosition) => {
            return onePosition.id == item.id;
          }
        );
        if (findIndex != -1) {
          GET_CUSTOMER_COLLI.value.colliPositions.splice(findIndex, 1);
        } else {
          GET_CUSTOMER_COLLI.value.colliPositions.push(item);
        }
      }
    };

    // const SET_COLLI_TAG = (tag) => {
    //   return store.commit(
    //     `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.SET_COLLI_TAG}`,
    //     tag
    //   );
    // };

    return {
      state,
      router,
      store,
      GET_CUSTOMER_COLLI,
      GET_COLLI_POSITIONS,
      GET_CUSTOMER_COLLIS,
      GET_CHANNELS,
      GET_GROUPS,
      GET_CUSTOMER,

      select_sub_colli,
      select_super_colli,
      select_channel,
      select_group,
    };
  },
};
</script>

<style lang="scss">
.customer-link {
  &.router-link-exact-active {
    @apply bg-grey-lightgrey;
  }
}
</style>
