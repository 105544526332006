<template>
  <svg
    id="Exit"
    xmlns="http://www.w3.org/2000/svg"
    width="11.982"
    height="11.982"
    viewBox="0 0 11.982 11.982"
  >
    <rect
      id="Rechteck_973"
      data-name="Rechteck 973"
      width="1.452"
      height="15.492"
      rx="0.5"
      transform="translate(0 1.027) rotate(-45)"
      :class="fillClass + ' transition-fill'"
    />
    <rect
      id="Rechteck_974"
      data-name="Rechteck 974"
      width="1.452"
      height="15.492"
      rx="0.5"
      transform="translate(10.955 0) rotate(45)"
      :class="fillClass + ' transition-fill'"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FalseIcon",
  props: {
    fillClass: {
      type: String,
      default: "",
    },
  },
});
</script>
