import { createI18n } from "vue-i18n";
const language = {
  de: {
    abort: "Abbrechen",
    active_consecutive_number_start_at_1000000:
      "Fortlaufende Nummer aktivieren (Beginn: 1000000)",
    add: "Hinzufügen",
    add_customer: "Kunde hinzufügen",
    add_device: "Gerät hinzufügen",
    add_user: "User hinzufügen",
    add_position: "Position hinzufügen",
    add_product_carrier: "Warenträger hinzufügen",
    add_location: "Ort hinzufügen",
    designation: "Bezeichnung",
    admin: "Admin",
    admin_role: "Rolle Admin",
    administration: "Administration",
    administration_2: "Verwaltung",
    administration_permissions: "Verwaltung Schreibrechte",
    amount: "Menge",
    archive: "Archiv",
    article: "Artikel",
    article_name: "Artikelname",
    boolean: "Wert",
    channel: "Sender",
    city: "Stadt",
    cancel: "Abbrechen",
    continue: "Weiter",
    included_colli: "Enthaltene Warenträger",
    colli: "Warenträger",
    comment: "Bemerkung",
    contact_name: "Kontaktname",
    content: "Inhalt",
    content_or_date: "Inhalt oder Datum",
    csv: "csv",
    customers: "Kunden",
    customer_name: "Kundenname",
    current_position: "Aktuelle Position",
    create: "anlegen",
    create_customer: "Verwaltung Schreibrechte",
    create_customer_1: " Kunde anlegen",
    create_device_1: "Gerät hinzufügen",
    create_device: "Geräteverwaltung",
    create_user: "Userverwaltung",
    dashboard: "Dashboard",
    description: "Bezeichnung",
    devices: "Geräte",
    device_id: "Netzwerkadresse",
    delete: "Löschen",
    delete_device: "Gerät löschen ?",
    delete_history: "History löschen ?",
    delete_location: "Ort löschen ?",
    delete_product_carrier: "Warenträger löschen?",
    delete_tarif: "Tarif löschen ?",
    delete_user: "User löschen ?",
    email: "E-Mail",
    email_repeat: "E-Mail wiederholen",

    edit: "Verwaltung",
    edit_2: "Bearbeiten",
    edit_product_carrier: "Warenträger bearbeiten",
    edit_fields: "Verwalten Sie Ihre Felder",
    edit_fields_2: "Verwaltung der Individuellen Felder",
    edit_tarifs: "Verwalten Sie Ihre Tarife",
    edit_tarifs_2: "Verwaltung der Tarife",
    edit_groups: "Verwalten Sie Ihre Gruppen",
    edit_groups_2: "Verwaltung der Gruppen",

    edit_receiver: "Empfänger bearbeiten",
    edit_location: "Ort bearbeiten",
    edit_channel: "Sender bearbeiten",
    edit_position: "Position bearbeiten",
    edit_fields_text:
      "Nutzen Sie Bezeichnungen, die in Ihrem Unternehmen verwendet werden.",
    edit_groups_text:
      "Definieren Sie Gruppen, um beim Anlegen von Warenträgern auf die individuellen Felder zurückzugreifen.",
    edit_tarifs_text:
      "Nutzen Sie Tarife, um bei jedem Kunden die Einnahmen zu erfassen.",
    edit_users: "User bearbeiten und hinzufügen",

    edit_users_2: "Userverwaltung",

    edit_users_text: "Verwalten Sie Ihre Benutzer und verteilen Sie Rechte.",
    entered_passwords_do_not_match:
      "Die angegebenen Passwörter stimmen nicht überein.",
    try_again_2: "Bitte versuchen Sie es noch einmal.",
    an_invitation_was_sent_to_the_user:
      "Es wurde eine Einladung an den Nutzer versendet",
    field: "Feld",
    file: "Datei",
    filter: "Filtern",
    filter_toggle: "Filter ein/ausblenden",
    finder: "Finder",
    forgot_password: "Passwort vergessen",
    fullscreen_toggle: "Vollbildmodus aktivieren/deaktivieren",
    group: "Gruppe",
    group_name: "Gruppenname",
    groups: "Gruppen",
    history: "Historie",
    id: "ID",
    import: "hochladen",
    import_csv: "Import csv",
    import_csv_file: "Import csv-Datei",
    start_import: "Import starten",
    income: "Einnahmen",
    Individualisation: "Individualisierung",
    individual_field: "Individuelles Feld",
    individual_fields: "Individuelle Felder",
    info: "Info",
    linked_locations: "Verknüpfte Orte",
    locations: "Orte",
    location: "Ort",
    login: "Anmeldung",
    login_return: "Zurück zum Login",
    login_2: "Anmelden",
    logout: "Abmelden",
    mobile: "Smartphone",
    mandatory_field: "Pflichtfeld",
    name: "Name",
    numeric: "Zahl",
    no_children_for_position:
      "Keine Warenträger oder Orte für diesen Ort angelegt",
    no_logs_for_colli:
      "Für diesen Warenträger gibt es noch keine Positionshistorie",
    no_collis: "Keine Warenträger verfügbar.",
    no_receiver: "Keine Empfänger verfügbar.",
    no_position: "Keine Orte verfügbar.",
    no_channel: "Kein Sender verfügbar.",
    no_more_collis: "Keine weiteren Warenträger vefügbar.",
    no_positions: "Keine Positionen verfügbar.",
    order: "Auftrag",
    ongoing_number: "Fortlaufende Nummer",

    password_old: "Altes Passwort",
    password: "Passwort",
    password_forgot: "Passwort vergessen?",
    password_new: "Neues Passwort",
    password_repeat: "Passwort wiederholen",

    password_reset: "Passwort zurücksetzen",
    password_change: "Passwort ändern",
    password_change_text:
      "Geben Sie Ihr neues Passwort ein, mit dem Sie sich in Zukunft bei enterDATA anmelden können.",
    password_forgot_text:
      "Geben Sie Ihre E-Mail-Adresse ein, mit der Sie Ihr enterData Konto erstellt haben. So können wir Ihnen einen Link zum Zurücksetzen schicken.",
    position: "Position",
    positions: "Positionen",
    postcode: "PLZ",
    product_carrier: "Warenträger",
    product_carrier_designation: "Warenträger Bezeichnung",
    product_carrier_designation_2: "Warenträgerbezeichnung",
    product_carrier_designation_text:
      "Nutzen Sie eine fortlaufende Nummer für eine einheitliche Warenträger Bezeichnung.",
    price_per_license_in_euros: "Preis pro Lizenz in €",
    sub_product_carrier: "Untergeordneter Warenträger",
    superior_product_carrier: "Übergeordneter Warenträger",
    superior_location: "Übergeordneter Ort",
    upload_logo: "Logo hochladen",
    upload_profile_picture: "Profilbild hochladen",
    user: "User",
    user_administration: "Userverwaltung",
    user_role: "Rolle User",
    receiver: "Empfänger",
    recording_time: "Zeitpunkt Erfassung",
    registration: "Registrierung",
    register: "Registrieren",
    registration_text:
      "Geben Sie Ihr Passwort ein, mit dem Sie sich in Zukunft bei enterData anmelden können.",
    role: "Rolle",
    edit_profile: "Profil bearbeiten",

    licenses: "Lizenzen",
    list: "Liste",
    last_name: "Nachname",
    first_name: "Vorname",
    calendar: "Kalender",
    calendar_type: "Kalendarart",
    month_view: "Monatsansicht",
    week_view: "Wochenansicht",
    resend_code: "Code erneut senden",
    search: "Suchen",
    send_invitation: "Einladung senden",
    settings: "Einstellungen",
    shortcuts: "Tastenkürzel",
    show_all_positions: "Alle Positionen anzeigen",
    sign_in: "Anmelden",
    stay_logged_in: "Angemeldet bleiben",
    street_and_number: "Str. & Hausnr.",
    string: "Zeichen",
    upload_file: "Datei",

    menu_toggle: "Menüleiste ein/ausblenden",
    tarif: "Tarif",
    tarif_name: "Tarifname",
    telefone: "Telefon",
    trust_browser: "Browser vertrauen?",
    type: "Typ",
    two_fa: "Zwei-Faktor-Authentifizierung",
    two_fa_text:
      "Bitte geben Sie den fünfstelligen Code aus der E-Mail ein. Keinen Code erhalten?",
    save: "Speichern",
    view_profile: "Profil ansehen",
    wrong_password_and_email:
      "Das Passwort oder die E-Mail Adresse ist nicht korrekt.",
    try_again: " Bitte versuchen Sie es erneut oder wählen Sie unten ",

    toast: {
      login_failed: "Anmeldung ist fehlgeschlagen.",
      user_search: "Name, E-Mail oder Abteilung",
      no_users: "Keine User gefunden",
    },
    /***
     * Settings
     ***/ speedUpWork: "Beschleunigen Sie Ihre Arbeitsweise",
    useShortcutsText:
      "Nutzen Sie Tastenkürzel, um bspw. Inhalte schneller zu durchsuchen.",
  },
  en: {
    dashboard: "Dashboard",
    login: "Login",
    logout: "Logout",
    toast: {
      login_failed: "Login failed.",
    },
  },
};

const i18n = createI18n({
  messages: language,
  locale: "de",
  fallbackLocale: "de",
});

export default i18n;
