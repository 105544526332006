<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.786"
    height="19.93"
    viewBox="0 0 24.786 19.93"
  >
    <g id="layer1" transform="translate(-213.586 -301.304)">
      <path
        id="path3793"
        d="M157.617,120.5a8.189,8.189,0,0,0-5.742,2.359l1.651,1.649a5.854,5.854,0,0,1,8.244.063l1.649-1.649A8.181,8.181,0,0,0,157.617,120.5Z"
        transform="translate(68.339 190.168)"
        fill="#c0c1c3"
         :class="fillClass + ' transition-fill'"
      />
      <path
        id="path3789"
        d="M343.955,495.71a2.378,2.378,0,1,0,2.378,2.378A2.393,2.393,0,0,0,343.955,495.71Z"
        transform="translate(-118.001 -179.232)"
        fill="#c0c1c3"
         :class="fillClass + ' transition-fill'"
      />
      <path
        id="path3797"
        d="M118.465,60.5a12.859,12.859,0,0,0-9.059,3.737l1.651,1.646a10.541,10.541,0,0,1,14.866.056l1.649-1.651A12.844,12.844,0,0,0,118.465,60.5Z"
        transform="translate(107.494 245.485)"
        fill="#c0c1c3"
         :class="fillClass + ' transition-fill'"
      />
      <path
        id="path3803"
        d="M79.311.531a17.529,17.529,0,0,0-12.373,5.11L68.589,7.29a15.219,15.219,0,0,1,21.488.046l1.646-1.651A17.513,17.513,0,0,0,79.311.531Z"
        transform="translate(146.648 300.773)"
        fill="#c0c1c3"
         :class="fillClass + ' transition-fill'"
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "ChannelIcon",
    props: {
        fillClass: {
            type: String,
            required: false,
        },
    },
});
</script>
