<template>
    <svg
        id="Communication"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="24"
        viewBox="0 0 32.055 23.437"
    >
        <path
            id="Pfad_319"
            data-name="Pfad 319"
            d="M313.343,90.761a1.575,1.575,0,0,1-.7-.166.833.833,0,0,1-.143-.09L307.41,86.58l-4.967.017h0a2.367,2.367,0,0,1-2.366-2.364V73.018a2.37,2.37,0,0,1,2.366-2.368h13.466a2.37,2.37,0,0,1,2.368,2.368V84.233c0,1.062-1.067,1.4-1.774,1.621-.162.051-.387.123-.526.182l-1.164,3.72a.885.885,0,0,1-.051.129A1.584,1.584,0,0,1,313.343,90.761Zm-10.9-5.9h5.069a1.586,1.586,0,0,1,.711.17.826.826,0,0,1,.138.087l4.9,3.777,1.04-3.326c.1-.875,1.046-1.172,1.68-1.373.161-.051.4-.126.555-.194V73.018a.625.625,0,0,0-.625-.625H302.443a.625.625,0,0,0-.625.625V84.233a.625.625,0,0,0,.623.623Zm12.717.871h0Z"
            transform="translate(-286.22 -67.324)"
            :class="fillClass + ' transition-fill'"
        />
        <path
            id="Pfad_320"
            data-name="Pfad 320"
            d="M294.679,88.282a1.6,1.6,0,0,1-1.422-.863.87.87,0,0,1-.056-.138l-1.16-3.723c-.137-.059-.362-.13-.524-.181-.707-.22-1.775-.554-1.775-1.618V70.538a2.371,2.371,0,0,1,2.366-2.369h13.466a2.375,2.375,0,0,1,2.368,2.368v1.831a.871.871,0,1,1-1.742,0v-1.83a.631.631,0,0,0-.627-.626H292.109a.625.625,0,0,0-.625.623V81.522c.153.066.391.141.552.19.635.2,1.579.495,1.683,1.373l1.038,3.332,4.9-3.781a.934.934,0,0,1,.13-.083,1.566,1.566,0,0,1,.712-.176h3.968a.871.871,0,1,1,0,1.742h-3.88l-5.065,3.91a.842.842,0,0,1-.149.093A1.575,1.575,0,0,1,294.679,88.282Z"
            transform="translate(-289.741 -68.169)"
            :class="fillClass + ' transition-fill'"
        />
        <circle
            id="Ellipse_44"
            data-name="Ellipse 44"
            cx="1.197"
            cy="1.197"
            r="1.197"
            transform="translate(4.552 5.06)"
            :class="fillClass + ' transition-fill'"
        />
        <circle
            id="Ellipse_45"
            data-name="Ellipse 45"
            cx="1.197"
            cy="1.197"
            r="1.197"
            transform="translate(10.537 5.06)"
            :class="fillClass + ' transition-fill'"
        />
        <path
            id="Pfad_234"
            data-name="Pfad 234"
            d="M294.029,75.932a3.057,3.057,0,0,0,5.985,0"
            transform="translate(-288.28 -65.524)"
            :class="fillClass + ' transition-fill'"
        />
        <path
            id="Pfad_235"
            data-name="Pfad 235"
            d="M304.642,78.025a3.056,3.056,0,0,0,5.985,0"
            transform="translate(-284.664 -64.811)"
            :class="fillClass + ' transition-fill'"
        />
        <path
            id="Pfad_236"
            data-name="Pfad 236"
            d="M306.7,76.357c-.137-.934-.727-1.61-1.4-1.61s-1.267.676-1.405,1.61"
            transform="translate(-284.921 -65.928)"
            :class="fillClass + ' transition-fill'"
        />
        <path
            id="Pfad_237"
            data-name="Pfad 237"
            d="M310.55,76.357c-.138-.934-.728-1.61-1.405-1.61s-1.266.676-1.4,1.61"
            transform="translate(-283.608 -65.928)"
            :class="fillClass + ' transition-fill'"
        />
    </svg>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "CommunicationIcon",
    props: {
        fillClass: {
            type: String,
            required: true,
        },
    },
});
</script>
