import { CUSTOMER_COLLIS_TYPES, HELPERS_TYPES, STORE_TYPES } from "../types";
import router from "../../router";

const state = {
  isSidebarActive: true,
  getFilterRouteName: null,
  loadingIds: [],
  errorIds: {},
  editButtons: false,
  histroylogs: [],
};
const getters = {
  [HELPERS_TYPES.GET_IS_SIDEBAR_ACTIVE]: (state) => state.isSidebarActive,
  [HELPERS_TYPES.GET_FILTER_ACTIVE_ROUTE]: (state) => state.getFilterRouteName,
  [HELPERS_TYPES.GET_HISTORY_LOGS]: (state) => state.historyLogs,
  [HELPERS_TYPES.GET_LOADING_IDS]: (state) => state.loadingIds,
  [HELPERS_TYPES.GET_ERROR_IDS]: (state) => state.errorIds,
  [HELPERS_TYPES.GET_EDIT_BUTTONS]: (state) => state.editButtons,
};
const actions = {
  async [HELPERS_TYPES.RESET_DATA]({ commit }, isSameSite = false) {
    if (isSameSite) {
      commit(HELPERS_TYPES.SET_HISTORY_LOGS, []);
    }
    commit(
      `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.SET_CUSTOMER_COLLIS}`,
      [],
      {
        root: true,
      }
    );
    // commit(
    //   `${STORE_TYPES.APPRENTICESHIPS}/${APPRENTICESHIP_TYPES.SET_APPRENTICESHIPS}`,
    //   [],
    //   { root: true }
    // );
    // commit(`${STORE_TYPES.MAILS}/${MAIL_TYPES.SET_MAILS}`, [], {
    //   root: true,
    // });
    // commit(`${STORE_TYPES.MAILS}/${MAIL_TYPES.SET_MAIL}`, null, {
    //   root: true,
    // });
    // commit(`${STORE_TYPES.CONTACTS}/${CONTACT_TYPES.SET_CONTACTS}`, [], {
    //   root: true,
    // });
  },
};
const mutations = {
  [HELPERS_TYPES.SET_HISTORY_LOGS]: (state, array) =>
    (state.historyLogs = array),
  [HELPERS_TYPES.SET_IS_SIDEBAR_ACTIVE]: (state, value) =>
    (state.isSidebarActive = value),
  [HELPERS_TYPES.SET_EDIT_BUTTONS]: (state, value) =>
    (state.SET_EDIT_BUTTONS = value),
  [HELPERS_TYPES.SET_FILTER_ACTIVE_ROUTE]: (state) => {
    if (state.getFilterRouteName) {
      state.getFilterRouteName = null;
    } else {
      state.getFilterRouteName = router.currentRoute.value.name;
    }
  },
  [HELPERS_TYPES.SET_LOADING_IDS]: (state, array) => (state.loadingIds = array),
  [HELPERS_TYPES.SET_LOADING_ID]: (state, string) =>
    state.loadingIds.push(string),
  [HELPERS_TYPES.REMOVE_LOADING_ID]: (state, string) =>
    (state.loadingIds = state.loadingIds.filter(
      (loadingId) => loadingId != string
    )),
  [HELPERS_TYPES.SET_ERROR_IDS]: (state, object) => (state.errorIds = object),
  [HELPERS_TYPES.SET_ERROR_ID_OBJECT]: (state, object) =>
    (state.errorIds = Object.assign(state.errorIds, object)),
  [HELPERS_TYPES.REMOVE_ERROR_ID]: (state, string) =>
    (state.errorIds[string] = null),
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
