import ApiService from "../../common/apiService";
import { getData } from "../../common/dataHelper";
import router from "../../router";
// import router from "../../router";
import { CUSTOMER_POSITIONS_TYPES } from "../types";

/************************** HELPERS ********************* */
// DTO :
function createPositionDTO(customer_position) {
  var create_position = {
    ...customer_position,
    parent: getData(customer_position, "parent"),
    parent_id: getData(customer_position, "parent.id"),
    name: getData(customer_position, "name"),
    anchors: getData(customer_position, "anchors"),
    area: getData(customer_position, "area"),
    areaid: getData(customer_position, "area.areaid"),
  };
  return create_position;
}
/**  ********************** STATE *********************** */
const state = {
  customer_positions: [],
  customer_position: {},
  delete_customer_position: null,
  options: [],
};

/**  ********************** GETTERS *********************** */
const getters = {
  [`${CUSTOMER_POSITIONS_TYPES.GET_CUSTOMER_POSITIONS}`](state) {
    return state.customer_positions;
  },
  [`${CUSTOMER_POSITIONS_TYPES.GET_OPTIONS}`](state) {
    return state.options;
  },

  [`${CUSTOMER_POSITIONS_TYPES.GET_CUSTOMER_POSITION}`](state) {
    return state.customer_position;
  },
  [`${CUSTOMER_POSITIONS_TYPES.GET_DELETE_CUSTOMER_POSITION}`](state) {
    return state.delete_customer_position;
  },
};

/**  ********************** ACTIONS *********************** */
const actions = {
  async [`${CUSTOMER_POSITIONS_TYPES.FETCH_CUSTOMER_POSITIONS}`](context, id) {
    const response = await ApiService.query(
      "api/customers/" + id + "/positions"
    );
    if (getData(response, "status") === 200) {
      context.commit(
        `${CUSTOMER_POSITIONS_TYPES.SET_CUSTOMER_POSITIONS}`,
        getData(response, "data")
      );
      return getData(response, "data");
    }
  },

  /** Create CUSTOMER POSITION */
  async [`${CUSTOMER_POSITIONS_TYPES.CREATE_CUSTOMER_POSITION}`](context, id) {
    let customer_position = context.state.customer_position;
    let create_position = createPositionDTO(customer_position);
    const response = await ApiService.post(
      "api/customers/" + id + "/positions",
      create_position
    );
    if (getData(response, "status") === 200) {
      context.state.customer_positions.push(getData(response, "data"));
    }
    router.push({ name: "Locations" });
    return response;
  },

  /**   FETCH OPTIONS */

  async [`${CUSTOMER_POSITIONS_TYPES.FETCH_OPTIONS}`](
    context,
    { customer_id, params }
  ) {
    const response = await ApiService.query(
      "api/customers/" + customer_id + "/options",
      params
    );
    if (getData(response, "status") === 200) {
      //  SET OPTIONS
      context.commit(
        `${CUSTOMER_POSITIONS_TYPES.SET_OPTIONS}`,
        getData(response, "data.areas")
      );

      return getData(response, "data");
    }
  },

  /** Update CUSTOMER position */

  async [`${CUSTOMER_POSITIONS_TYPES.UPDATE_CUSTOMER_POSITION}`](context) {
    let customer_position = context.state.customer_position;
    let create_position = createPositionDTO(customer_position);
    const response = await ApiService.update(
      "api/customers/" + customer_position.customer_id + "/positions",
      customer_position.id,
      create_position
    );
    if (getData(response, "status") === 200) {
      context.commit(
        `${CUSTOMER_POSITIONS_TYPES.SET_CUSTOMER_POSITION}`,
        getData(response, "data")
      );
      return getData(response, "data");
    }
  },

  /** DELETE CUSTOMER POSITION */

  async [CUSTOMER_POSITIONS_TYPES.DELETE_CUSTOMER_POSITION](context) {
    let customer_position = context.state.delete_customer_position;
    const response = await ApiService.delete(
      "api/customers/" +
        customer_position.customer_id +
        "/positions/" +
        customer_position.id
    );

    if (customer_position != null) {
      const findIndex = context.state.customer_positions.findIndex(
        (customerPosition) => {
          return customerPosition.id == customer_position.id;
        }
      );
      context.state.customer_positions.splice(findIndex, 1);
      context.state.delete_customer_position = null;
    }

    return response;
  },
};

/**  ********************** MUTATIONS *********************** */
const mutations = {
  [`${CUSTOMER_POSITIONS_TYPES.SET_CUSTOMER_POSITIONS}`](state, value) {
    state.customer_positions = value;
  },
  [`${CUSTOMER_POSITIONS_TYPES.SET_OPTIONS}`](state, value) {
    state.options = value;
  },

  [`${CUSTOMER_POSITIONS_TYPES.SET_CUSTOMER_POSITION}`](state, value) {
    state.customer_position = value;
  },
  [`${CUSTOMER_POSITIONS_TYPES.SET_DELETE_CUSTOMER_POSITION}`](state, value) {
    state.delete_customer_position = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
