<template>
  <div
    class="w-70px items-center justify-center h-16 cursor-pointer fixed top-0 left-0 z-50 transition-all hidden lg:flex bg-white"
    @click="SET_IS_SIDEBAR_ACTIVE(!GET_IS_SIDEBAR_ACTIVE)"
  >
    <menu-left-icon
      fillClass="fill-black"
      v-if="GET_IS_SIDEBAR_ACTIVE"
    ></menu-left-icon>
    <menu-right-icon fillClass="fill-black" v-else></menu-right-icon>
  </div>
  <aside
    class="flex items-center gap-0 lg:gap-4 fixed left-0 bottom-0 w-full h-12 bg-primary justify-center content-center transition-transform lg:justify-start lg:pt-24 lg:flex-col lg:h-full lg:w-70px"
    :class="[GET_IS_SIDEBAR_ACTIVE ? '' : 'lg:-translate-x-full lg:transform']"
  >
    <sidebar-link v-for="link in state.links" :key="link.id" :link="link" />
    <sidebar-link class="mt-auto" :link="state.settingsLink"></sidebar-link>
    <!-- <div
      class="h-12 w-70px flex-col items-center justify-center cursor-pointer flex lg:hidden hover:bg-white hover:bg-opacity-5"
      @click="state.isDrawerVisible = !state.isDrawerVisible"
    >
      <menu-icon fillClass="fill-white"></menu-icon>
    </div> -->
  </aside>

  <!-- <bottom-up-modal
    title=""
    :isVisible="state.isDrawerVisible"
    :isDrawer="true"
    @closeModal="state.isDrawerVisible = false"
  ></bottom-up-modal> -->
</template>

<script>
import { defineComponent, reactive, computed } from "vue";
import { useStore } from "vuex";
import { STORE_TYPES, HELPERS_TYPES } from "../../store/types";
import SidebarLink from "./SidebarLink.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { SidebarLink },
  setup() {
    const store = useStore();
    const i18n = useI18n();
    /**
     *  State
     */
    const state = reactive({
      links: [
        {
          id: 1,
          name: i18n.t("finder"),
          icon: "FinderIcon",
          link: { name: "ProductCarrier" },
          path: "finder",
        },
        {
          id: 2,
          name: i18n.t("product_carrier"),
          icon: "ColliBoxIcon",
          link: { name: "Colli" },
          path: "colli",
        },
        {
          id: 3,
          name: i18n.t("position"),
          icon: "ConnectIcon",
          link: { name: "ColliPosition" },
          path: "positions",
        },
        {
          id: 4,
          name: i18n.t("archive"),
          icon: "ArchiveIcon",
          link: { name: "Archive" },
          path: "archive",
        },
        {
          id: 5,
          name: i18n.t("locations"),
          icon: "LocationIcon",
          link: { name: "Location" },
          path: "locations",
        },

        {
          id: 6,
          name: i18n.t("channel"),
          icon: "ChannelIcon",
          link: { name: "Channel" },
          path: "channels",
        },
        {
          id: 7,
          name: i18n.t("receiver"),
          icon: "ReceiverIcon",
          link: { name: "Receiver" },
          path: "receivers",
        },
      ],

      settingsLink: {
        id: 99,
        name: i18n.t("settings"),
        icon: "SettingsIcon",
        link: { name: "Settings" },
        path: "settings",
      },
      isDrawerVisible: false,
    });

    /**
     *  Functions
     */
    function SET_IS_SIDEBAR_ACTIVE(value) {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_IS_SIDEBAR_ACTIVE}`,
        value
      );
    }

    /**
     *  Computed
     */
    const GET_IS_SIDEBAR_ACTIVE = computed(
      () =>
        store.getters[
          `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.GET_IS_SIDEBAR_ACTIVE}`
        ]
    );

    return {
      state,
      SET_IS_SIDEBAR_ACTIVE,
      GET_IS_SIDEBAR_ACTIVE,
    };
  },
});
</script>
