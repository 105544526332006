import ApiService from "../../common/apiService";
import { getData } from "../../common/dataHelper";
// import router from "../../router";
import { DATA_MODELS_TYPES } from "../types";

/**  ********************** STATE *********************** */
const state = {
  data_models: [],
  data_model: {},
  delete_data_model: null,
};

/**  ********************** GETTERS *********************** */
const getters = {
  [`${DATA_MODELS_TYPES.GET_DATA_MODELS}`](state) {
    return state.data_models;
  },

  [`${DATA_MODELS_TYPES.GET_DATA_MODEL}`](state) {
    return state.data_model;
  },
  [`${DATA_MODELS_TYPES.GET_DELETE_DATA_MODEL}`](state) {
    return state.delete_data_model;
  },
};

/**  ********************** ACTIONS *********************** */
const actions = {
  async [`${DATA_MODELS_TYPES.FETCH_DATA_MODELS}`](context, id) {
    const response = await ApiService.query(
      "api/customers/" + id + "/dataModels"
    );
    if (getData(response, "status") === 200) {
      context.commit(
        `${DATA_MODELS_TYPES.SET_DATA_MODELS}`,
        getData(response, "data")
      );
      return getData(response, "data");
    }
  },

  /** Create CUSTOMER DATAMODEL */
  async [`${DATA_MODELS_TYPES.CREATE_DATA_MODEL}`](context, id) {
    let data_model = context.state.data_model;
    const response = await ApiService.post(
      "api/customers/" + id + "/dataModels",
      data_model
    );

    if (getData(response, "status") === 200) {
      context.state.data_models.push(getData(response, "data"));
    }
    return response;
  },

  /** Update CUSTOMER DATAMODEL */

  async [`${DATA_MODELS_TYPES.UPDATE_DATA_MODEL}`](context, { customer_id }) {
    let data_model = context.state.data_model;
    const response = await ApiService.update(
      "api/customers/" + customer_id + "/dataModels",
      data_model.id,
      data_model
    );
    if (getData(response, "status") === 200) {
      context.commit(
        `${DATA_MODELS_TYPES.SET_DATA_MODEL}`,
        getData(response, "data")
      );
      return getData(response, "data");
    }
    if (data_model != null) {
      const findIndex = context.state.data_models.findIndex((dataModel) => {
        return dataModel.id == data_model.id;
      });
      context.state.data_models.splice(findIndex, 1);
      context.state.delete_data_model = null;
    }
  },

  /** DELETE CUSTOMER DATAMODEL */

  async [DATA_MODELS_TYPES.DELETE_DATA_MODEL](context, { customer_id }) {
    let data_model = context.state.delete_data_model;
    const response = await ApiService.delete(
      "api/customers/" + customer_id + "/dataModels/" + data_model.id
    );

    if (data_model != null) {
      const findIndex = context.state.data_models.findIndex((dataModel) => {
        return dataModel.id == data_model.id;
      });
      context.state.data_models.splice(findIndex, 1);
      context.state.delete_data_model = null;
    }

    return response;
  },
};

/**  ********************** MUTATIONS *********************** */
const mutations = {
  [`${DATA_MODELS_TYPES.SET_DATA_MODELS}`](state, value) {
    state.data_models = value;
  },

  [`${DATA_MODELS_TYPES.SET_DATA_MODEL}`](state, value) {
    state.data_model = value;
  },
  [`${DATA_MODELS_TYPES.SET_DELETE_DATA_MODEL}`](state, value) {
    state.delete_data_model = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
