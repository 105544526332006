<template>
  <div
    class="avatar-bubble relative top flex justify-center items-center rounded-full"
    :class="[
      borderColor,
      src == null ? backgroundColor : 'bg-transparent',
      isSquared
        ? `w-${size} h-${size} rounded-md`
        : `w-${size} h-${size} rounded-full`,
    ]"
    @click="$emit('upload')"
  >
    <h3
      v-if="src == null && state.fakeSrc == null"
      :class="`${fontSizeClass} ${textColor}`"
    >
      {{ acronym }}
    </h3>
    <img
      :src="src != null ? src : state.fakeSrc"
      :class="[
        isSquared
          ? `w-${size} h-${size} rounded-md`
          : `w-${size} h-${size} rounded-full`,
      ]"
      alt=""
      v-else
    />
    <button
      v-if="showDelete"
      @click.stop="$emit('deleteAvatar')"
      class="cancel-button absolute top-4 right-4 w-0 h-0 rounded-full flex justify-center items-center bg-black overflow-hidden transition-all opacity-0 hover:bg-primary-lightest"
    >
      <cancel-icon class="w-2 h-2" :fillClass="'fill-white'"></cancel-icon>
    </button>
    <upload-icon
      v-if="showEdit"
      class="absolute avatar-upload opacity-0"
      :fillClass="'fill-white'"
    />
  </div>
</template>

<script>
import { computed, defineComponent, reactive } from "vue";
import makeAcronym from "../../common/acronym";
import UploadIcon from "../icons/UploadIcon.vue";
export default defineComponent({
  components: { UploadIcon },
  name: "AvatarBubble",
  props: {
    name: {
      type: String,
      default: "",
    },
    borderColor: {
      type: String,
      default: "border-transparent",
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "bg-primary",
    },

    isSquared: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "10",
    },
    src: {
      type: String,
      default: null,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    textColor: {
      type: String,
      default: "text-white font-medium",
    },
  },
  emits: ["delete"],
  setup(props, ctx) {
    const state = reactive({
      fakeSrc: null,
    });
    /**
     * Emits
     */
    const emitDelete = () => {
      ctx.emit("delete");
    };

    /**
     * Computed
     */
    const acronym = computed(() => {
      return makeAcronym(props.name);
    });
    const fontSizeClass = computed(() => {
      return parseInt(props.size) <= 6 ? "text-xs" : "text-md";
    });

    const upload_fake_image = (avatar) => {
      let fileReader = new FileReader();
      if (FileReader && avatar) {
        fileReader.onload = () => {
          setTimeout(() => {
            state.fakeSrc = fileReader.result;
          }, 10);
        };
        fileReader.readAsDataURL(avatar);
      }
      return;
    };
    return {
      state,
      // Emits
      emitDelete,
      // Computed
      acronym,
      fontSizeClass,
      upload_fake_image,
    };
  },
});
</script>

<style lang="scss">
.avatar-bubble {
  &:hover {
    .cancel-button {
      @apply opacity-100;
      @apply w-5;
      @apply h-5;
      @apply -top-1;
      @apply -right-1;
    }
    .avatar-upload {
      @apply opacity-100;
    }
  }
}
</style>
