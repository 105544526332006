import ApiService from "../../common/apiService";
import { getData } from "../../common/dataHelper";
// import router from "../../router";
import { DATA_FIELDS_TYPES } from "../types";

/**  ********************** STATE *********************** */
const state = {
  data_fields: [],
  data_field: {},
  delete_field: null,
};

/**  ********************** GETTERS *********************** */
const getters = {
  [`${DATA_FIELDS_TYPES.GET_DATA_FIELDS}`](state) {
    return state.data_fields;
  },

  [`${DATA_FIELDS_TYPES.GET_DATA_FIELD}`](state) {
    return state.data_field;
  },
  [`${DATA_FIELDS_TYPES.GET_DELETE_DATA_FIELD}`](state) {
    return state.delete_data_field;
  },
};

/**  ********************** ACTIONS *********************** */
const actions = {
  async [`${DATA_FIELDS_TYPES.FETCH_DATA_FIELDS}`](context, id) {
    const response = await ApiService.query(
      "api/customers/" + id + "/dataFieldModels"
    );
    if (getData(response, "status") === 200) {
      context.commit(
        `${DATA_FIELDS_TYPES.SET_DATA_FIELDS}`,
        getData(response, "data")
      );
      return getData(response, "data");
    }
  },

  /** Create CUSTOMER DATA FIELD */
  async [`${DATA_FIELDS_TYPES.CREATE_DATA_FIELD}`](context, id) {
    let data_field = context.state.data_field;
    // if (data_field.type != null) {
    //   data_field.type = data_field.type.id;
    // }
    const response = await ApiService.post(
      "api/customers/" + id + "/dataFieldModels",
      data_field
    );

    if (getData(response, "status") === 200) {
      context.state.data_fields.push(getData(response, "data"));
    }

    return response;
  },

  /** Update CUSTOMER DATA FIELD */

  async [`${DATA_FIELDS_TYPES.UPDATE_DATA_FIELD}`](context, { customer_id }) {
    let data_field = context.state.data_field;
    const response = await ApiService.update(
      "api/customers/" + customer_id + "/dataFieldModels",
      data_field.id,
      data_field
    );
    if (getData(response, "status") === 200) {
      context.commit(
        `${DATA_FIELDS_TYPES.SET_DATA_FIELD}`,
        getData(response, "data")
      );
      return getData(response, "data");
    }
    if (data_field != null) {
      const findIndex = context.state.data_fields.findIndex((dataField) => {
        return dataField.id == data_field.id;
      });
      context.state.data_fields.splice(findIndex, 1);
      context.state.delete_data_field = null;
    }
  },

  /** DELETE DATA FIELD */

  async [DATA_FIELDS_TYPES.DELETE_DATA_FIELD](context, { customer_id }) {
    let data_field = context.state.delete_data_field;
    const response = await ApiService.delete(
      "api/customers/" + customer_id + "/dataFieldModels/" + data_field.id
    );

    if (data_field != null) {
      const findIndex = context.state.data_fields.findIndex((dataField) => {
        return dataField.id == data_field.id;
      });
      context.state.data_fields.splice(findIndex, 1);
      context.state.delete_data_field = null;
    }

    return response;
  },
};

/**  ********************** MUTATIONS *********************** */
const mutations = {
  [`${DATA_FIELDS_TYPES.SET_DATA_FIELDS}`](state, value) {
    state.data_fields = value;
  },

  [`${DATA_FIELDS_TYPES.SET_DATA_FIELD}`](state, value) {
    state.data_field = value;
  },
  [`${DATA_FIELDS_TYPES.SET_DELETE_DATA_FIELD}`](state, value) {
    state.delete_data_field = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
