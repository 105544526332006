<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.5"
        height="22.5"
        viewBox="0 0 22.5 22.5"
    >
        <g id="Check" transform="translate(0.75 0.75)">
            <circle
                id="Ellipse_102"
                data-name="Ellipse 102"
                cx="10.5"
                cy="10.5"
                r="10.5"
                fill="none"
                :class="strokeClass + ' transition-stroke'"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="1.5"
            />
            <path
                id="Pfad_403"
                data-name="Pfad 403"
                d="M9.571,15.3a.755.755,0,0,1-.52-.209L5.487,11.666a.75.75,0,1,1,1.039-1.082l3.015,2.9,6.116-6.574a.75.75,0,1,1,1.1,1.022L10.12,15.058a.752.752,0,0,1-.528.239H9.571"
                transform="translate(-0.75 -0.75)"
                :class="fillClass + ' transition-fill'"
                stroke="rgba(0,0,0,0)"
                stroke-width="1"
            />
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "TaskIcon",
    props: {
        fillClass: {
            type: String,
            required: true,
        },
        strokeClass: {
            type: String,
            required: true,
        },
    },
});
</script>
