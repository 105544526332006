<template>
  <svg
    id="File_import"
    data-name="File import"
    xmlns="http://www.w3.org/2000/svg"
    width="19.232"
    height="20.61"
    viewBox="0 0 19.232 20.61"
  >
    <g
      id="Gruppe_294"
      data-name="Gruppe 294"
      transform="translate(-556.05 -181.42)"
    >
      <path
        id="Pfad_473"
        data-name="Pfad 473"
        d="M571.475,192.62h0a.775.775,0,0,0,.775-.775V188.33a.717.717,0,0,0-.21-.52l-6.05-6.15a.8.8,0,0,0-.57-.24h-8.04a1.313,1.313,0,0,0-1.33,1.28v18.06a1.3,1.3,0,0,0,1.33,1.27h13.55a1.3,1.3,0,0,0,1.32-1.27V197.4a.775.775,0,0,0-.775-.775h0a.775.775,0,0,0-.775.775v3.135H557.61V182.92h7.48l.93.94,3.82,3.89.86.87v3.225A.775.775,0,0,0,571.475,192.62Z"
        :class="fillClass + ' transition-fill'"
      />
      <path
        id="Pfad_474"
        data-name="Pfad 474"
        d="M571.474,189.255h-5.555a1.43,1.43,0,0,1-1.45-1.407v-5.675h1.556v5.582h5.449Z"
        :class="fillClass + ' transition-fill'"
      />
    </g>
    <g
      id="Gruppe_296"
      data-name="Gruppe 296"
      transform="translate(-556.05 -181.42)"
    >
      <path
        id="Pfad_475"
        data-name="Pfad 475"
        d="M574.532,195.367h-8.041a.75.75,0,0,1,0-1.5h8.041a.75.75,0,0,1,0,1.5Z"
        :class="fillClass + ' transition-fill'"
      />
      <g id="Gruppe_295" data-name="Gruppe 295">
        <path
          id="Pfad_476"
          data-name="Pfad 476"
          d="M567.491,191.271l0,6.69a.5.5,0,0,1-.855.356l-3.347-3.342a.5.5,0,0,1,0-.709l3.341-3.348A.5.5,0,0,1,567.491,191.271Z"
          :class="fillClass + ' transition-fill'"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ImportFileIcon",
  props: {
    fillClass: {
      type: String,
      default: "fill-grey",
    },
  },
});
</script>
