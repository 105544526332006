<template>
  <div
    class="input-text flex flex-row items-center  border relative transition-all max-w-full cursor-pointer hover:border-grey-fontgrey "
    :class="
      heightClass + ' ' + borderClass + ' ' + focusWithinClass + ' ' + isLoading
    "
    @click="focusInput"
  >
    <div class="absolute top-3 left-3 flex-shrink-0">
      <slot name="icon" v-if="$slots.icon != null"> </slot>
    </div>

    <input
      ref="input"
      class="bg-transparent border-none w-full h-11 rounded-xl"
      :class="[
        inputClass + ' ',
        $slots.icon != null ? 'pl-9' : '',
        disabled ? 'disabled:opacity-50' : '',
      ]"
      :id="id"
      :name="name"
      :type="type"
      :value="modelValue"
      :placeholder="placeHolder"
      :autocomplete="'off'"
      @input="updateInput"
      @change="changeInput"
      @focus="focus"
    />
    <label
      @click="$emit('click')"
      :for="id"
      class="text-grey-fontgrey absolute transition-all text-sm px-1 left-2"
      :class="[
        modelValue && modelValue != '' ? labelClass + ' -top-3 ' : 'top-2',
        $slots.icon != null && (!modelValue || modelValue == '')
          ? 'left-8'
          : '',
      ]"
      >{{ label }}</label
    >
    <error-message-button v-if="errorMessage" :message="errorMessage" />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { HELPERS_TYPES, STORE_TYPES } from "../../store/types";
import ErrorMessageButton from "../global/ErrorMessageButton.vue";
export default defineComponent({
  components: { ErrorMessageButton },
  name: "InputText",
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
    modelValue: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    heightClass: {
      type: String,
      default: "h-11",
    },
    borderClass: {
      type: String,
      default: "border-grey-light hover:border-black rounded-xl",
    },
    focusWithinClass: {
      type: String,
      default: "focus-within:border-black",
    },
    inputClass: {
      type: String,
      default: "pl-3",
    },
    placeHolder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "update:value", "change", "focus", "click"],
  setup(props, ctx) {
    const store = useStore();
    /**
     * refs
     */
    const input = ref();
    /**
     * functions
     */
    function focusInput() {
      if (input.value) {
        input.value.focus();
      }
      ctx.emit("click");
    }
    /**
     * emits
     */
    function updateInput(event) {
      REMOVE_ERROR_ID();
      const newValue = event.target.value;
      ctx.emit("update:modelValue", newValue);
    }
    function changeInput(event) {
      const newValue = event.target.value;
      ctx.emit("change", newValue);
    }
    function focus(event) {
      ctx.emit("focus", event);
    }
    function REMOVE_ERROR_ID() {
      if (errorMessage.value) {
        store.commit(
          `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_ERROR_ID}`,
          props.name
        );
      }
    }
    /**
     *  Computed
     */
    const errorMessage = computed(() => {
      const errorIds =
        store.getters[`${STORE_TYPES.HELPERS}/${HELPERS_TYPES.GET_ERROR_IDS}`];
      let error = errorIds[props.name];
      if (error) {
        return error[0];
      }
      return error;
    });
    const isLoading = computed(() => {
      const loadingIds =
        store.getters[
          `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.GET_LOADING_IDS}`
        ];
      let loading = loadingIds.find((loadingId) => loadingId == props.id);

      return loading ? "border-loading" : "";
    });
    return {
      //refs
      input,
      //functions
      focusInput,
      REMOVE_ERROR_ID,
      //emits
      updateInput,
      changeInput,
      focus,
      //computed
      errorMessage,
      isLoading,
    };
  },
});
</script>
<style lang="scss">
.border-loading {
  border-color: #0c7c59 !important;
  label {
    color: #0c7c59 !important;
  }
}
</style>
