export const STORE_TYPES = {
  AUTH: "auth",
  CUSTOMER_COLLIS: "customerCollis",
  CUSTOMER_POSITIONS: "customerPositions",
  CUSTOMERS: "customers",
  COLLI_POSITIONS: "colliPositions",
  CUSTOMER_USERS: "customerUser",
  CUSTOMER_RECEIVERS: "customerReceivers",
  CUSTOMER_CHANNELS: "customerChannels",
  DATA_MODELS: "dataModels",
  DATA_FIELDS: "dataFields",
  FINDERS: "finders",
  HELPERS: "helpers",
  POSITIONS: "positions",
  SEARCH: "search",
  SETTINGS: "settings",
  USERS: "users",
};

export const HELPERS_TYPES = {
  /*** GETTERS  ***/
  GET_FILTER_ACTIVE_ROUTE: "GET_FILTER_ACTIVE_ROUTE",
  GET_IS_SIDEBAR_ACTIVE: "GET_IS_SIDEBAR_ACTIVE",
  GET_LOADING_IDS: "GET_LOADING_IDS",
  GET_EDIT_BUTTONS: "GET_EDIT_BUTTONS",
  GET_ERROR_IDS: "GET_ERROR_IDS",
  GET_TOAST_MESSAGE: "GET_TOAST_MESSAGE",
  GET_HISTORY_LOGS: "GET_HISTORY_LOGS",

  /*** MUTATIONS  ***/
  SET_FILTER_ACTIVE_ROUTE: "SET_FILTER_ACTIVE_ROUTE",
  SET_EDIT_BUTTONS: "SET_EDIT_BUTTONS",
  SET_IS_SIDEBAR_ACTIVE: "SET_IS_SIDEBAR_ACTIVE",
  SET_LOADING_IDS: "SET_LOADING_IDS",
  SET_LOADING_ID: "SET_LOADING_ID",
  REMOVE_LOADING_ID: "REMOVE_LOADING_ID",
  SET_ERROR_IDS: "SET_ERROR_IDS",
  SET_ERROR_ID_OBJECT: "SET_ERROR_ID_OBJECT",
  REMOVE_ERROR_ID: "REMOVE_ERROR_ID",
  SET_TOAST_MESSAGE: "SET_TOAST_MESSAGE",
  SET_HISTORY_LOGS: "SET_HISTORY_LOGS",
  /*** ACTIONS  ***/
  RESET_DATA: "RESET_DATA",
};

export const AUTH_TYPES = {
  /*** GETTERS  ***/
  GET_USER: "GET_USER",
  GET_USERS: "GET_USERS",
  IS_LOGGED_IN: "IS_LOGGED_IN",
  GET_WRONG_PASSWORD: "GET_WRONG_PASSWORD",
  GET_PERMISSIONS: "GET_PERMISSIONS",
  /*** MUTATIONS  ***/
  SET_USER: "SET_USER",
  SET_PERMISSIONS: "SET_PERMISSIONS",
  SET_WRONG_PASSWORD: "SET_WRONG_PASSWORD",
  /*** ACTIONS  ***/
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  FETCH_USER: "FETCH_USER",
  REGISTER: "REGISTER",
  FETCH_TOKEN_USERINFORMATION: "FETCH_TOKEN_USERINFORMATION",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESEND_2FA_CODE: "RESEND_2FA_CODE",
  SEND_2FA_CODE: "SEND_2FA_CODE",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  FETCH_CHANGE_PASSWORD_USERINFORMATION:
    "FETCH_CHANGE_PASSWORD_USERINFORMATION",
  FETCH_PERMISSIONS: "FETCH_PERMISSIONS",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPLOAD_AVATAR: "UPLOAD_AVATAR",
  DELETE_AVATAR: "DELETE_AVATAR",
};

export const CUSTOMER_USERS_TYPES = {
  /*** GETTERS  ***/
  GET_CUSTOMER_USERS: "GET_CUSTOMER_USERS",
  GET_CUSTOMER_USER: "GET_CUSTOMER_USER",
  GET_DELETE_CUSTOMER_USER: "GET_DELETE_CUSTOMER_USER",
  /*** MUTATIONS  ***/
  SET_CUSTOMER_USERS: "SET_CUSTOMER_USERS",
  SET_CUSTOMER_USER: "SET_CUSTOMER_USER",
  SET_DELETE_CUSTOMER_USER: "SET_DELETE_CUSTOMER_USER",
  /*** ACTIONS  ***/
  FETCH_CUSTOMER_USERS: "FETCH_CUSTOMER_USERS",
  FETCH_CUSTOMER_USER: "FETCH_CUSTOMER_USER",

  UPDATE_CUSTOMER_USER: "UPDATE_CUSTOMER_USER",
  CREATE_CUSTOMER_USER: "CREATE_CUSTOMER_USER",
  DELETE_CUSTOMER_USER: "DELETE_CUSTOMER_USER",
};

export const SEARCH_TYPES = {
  /*** GETTERS  ***/
  GET_IS_SEARCH_ACTIVE: "GET_IS_SEARCH_ACTIVE",
  GET_SEARCH: "GET_SEARCH",
  GET_SEARCH_ITEMS: "GET_SEARCH_ITEMS",

  /*** MUTATIONS  ***/
  SET_IS_SEARCH_ACTIVE: "SET_IS_SEARCH_ACTIVE",
  SET_SEARCH: "SET_SEARCH",
  SET_SEARCH_ITEMS: "SET_SEARCH_ITEMS",

  /*** ACTIONS  ***/
};

export const CUSTOMERS_TYPES = {
  /*** GETTERS  ***/
  GET_CUSTOMER: "GET_CUSTOMER",
  GET_CUSTOMER_SETTINGS: "GET_CUSTOMER_SETTINGS",

  /*** MUTATIONS  ***/
  SET_CUSTOMER: "SET_CUSTOMER",
  SET_CUSTOMER_SETTINGS: "SET_CUSTOMER_SETTINGS",

  /*** ACTIONS  ***/
  UPDATE_CUSTOMER_SETTINGS: "UPDATE_CUSTOMER_SETTINGS",
  FETCH_SETTINGS: "FETCH_SETTINGS",
  FETCH_CUSTOMER: "FETCH_CUSTOMER",
};

export const CUSTOMER_COLLIS_TYPES = {
  /*** GETTERS  ***/
  GET_CUSTOMER_COLLIS: "GET_CUSTOMER_COLLIS",
  GET_CUSTOMER_COLLI: "GET_CUSTOMER_COLLI",
  GET_COLLI_TAG: "GET_COLLI_TAG",
  GET_DELETE_CUSTOMER_COLLI: "GET_DELETE_CUSTOMER_COLLI",
  GET_PAGE: "GET_PAGE",
  GET_ALL_COLLIS_LOADED: "GET_ALL_COLLIS_LOADED",
  /*** MUTATIONS  ***/
  SET_CUSTOMER_COLLIS: "SET_CUSTOMER_COLLIS",
  SET_CUSTOMER_COLLI: "SET_CUSTOMER_COLLI",
  SET_COLLI_TAG: "SET_COLLI_TAG",
  SET_DELETE_CUSTOMER_COLLI: "SET_DELETE_CUSTOMER_COLLI",
  SET_PAGE: "SET_PAGE",
  SET_ALL_COLLIS_LOADED: "SET_ALL_COLLIS_LOADED",
  /*** ACTIONS  ***/
  FETCH_CUSTOMER_COLLIS: "FETCH_CUSTOMER_COLLIS",
  FETCH_CUSTOMER_COLLI: "FETCH_CUSTOMER_COLLI",
  FETCH_COLLI_POSITIONS: "FETCH_COLLI_POSITIONS",

  UPDATE_CUSTOMER_COLLI: "UPDATE_CUSTOMER_COLLI",
  CREATE_CUSTOMER_COLLI: "CREATE_CUSTOMER_COLLI",
  DELETE_CUSTOMER_COLLI: "DELETE_CUSTOMER_COLLI",
};
export const COLLI_POSITIONS_TYPES = {
  /*** GETTERS  ***/
  GET_COLLI_POSITIONS: "GET_COLLI_POSITIONS",
  GET_COLLI_POSITION: "GET_COLLI_POSITION",
  GET_DELETE_COLLI_POSITION: "GET_DELETE_COLLI_POSITION",
  /*** MUTATIONS  ***/
  SET_COLLI_POSITIONS: "SET_COLLI_POSITIONS",
  SET_COLLI_POSITION: "SET_COLLI_POSITION",
  SET_DELETE_COLLI_POSITION: "SET_DELETE_COLLI_POSITION",
  /*** ACTIONS  ***/
  FETCH_COLLI_POSITIONS: "FETCH_COLLI_POSITIONS",
  FETCH_COLLI_POSITION: "FETCH_COLLI_POSITION",
  UPDATE_COLLI_POSITION: "UPDATE_COLLI_POSITION",
  CREATE_COLLI_POSITION: "CREATE_COLLI_POSITION",
  DELETE_COLLI_POSITION: "DELETE_COLLI_POSITION",
};

export const POSITIONS_TYPES = {
  /*** GETTERS  ***/
  GET_POSITIONS: "GET_POSITIONS",
  GET_POSITION: "GET_POSITION",
  GET_DELETE_POSITION: "GET_DELETE_POSITION",
  /*** MUTATIONS  ***/
  SET_POSITIONS: "SET_POSITIONS",
  SET_POSITION: "SET_POSITION",
  SET_DELETE_POSITION: "SET_DELETE_POSITION",
  /*** ACTIONS  ***/
  FETCH_POSITIONS: "FETCH_POSITIONS",
  FETCH_POSITION: "FETCH_POSITION",

  DELETE_POSITION: "DELETE_POSITION",
};
export const CUSTOMER_POSITIONS_TYPES = {
  /*** GETTERS  ***/
  GET_CUSTOMER_POSITIONS: "GET_CUSTOMER_POSITIONS",
  GET_CUSTOMER_POSITION: "GET_CUSTOMER_POSITION",
  GET_OPTIONS: "GET_OPTIONS",
  GET_DELETE_CUSTOMER_POSITION: "GET_DELETE_CUSTOMER_POSITION",
  /*** MUTATIONS  ***/
  SET_CUSTOMER_POSITIONS: "SET_CUSTOMER_POSITIONS",
  SET_CUSTOMER_POSITION: "SET_CUSTOMER_POSITION",
  SET_OPTIONS: "SET_OPTIONS",
  SET_DELETE_CUSTOMER_POSITION: "SET_DELETE_CUSTOMER_POSITION",
  /*** ACTIONS  ***/
  FETCH_CUSTOMER_POSITIONS: "FETCH_CUSTOMER_POSITIONS",
  FETCH_CUSTOMER_POSITION: "FETCH_CUSTOMER_POSITION",
  FETCH_OPTIONS: "FETCH_OPTIONS",

  UPDATE_CUSTOMER_POSITION: "UPDATE_CUSTOMER_POSITION",
  CREATE_CUSTOMER_POSITION: "CREATE_CUSTOMER_POSITION",
  DELETE_CUSTOMER_POSITION: "DELETE_CUSTOMER_POSITION",
};
export const CUSTOMER_RECEIVERS_TYPES = {
  /*** GETTERS  ***/
  GET_CUSTOMER_RECEIVERS: "GET_CUSTOMER_RECEIVERS",
  GET_CUSTOMER_RECEIVER: "GET_CUSTOMER_RECEIVER",
  GET_DELETE_CUSTOMER_RECEIVER: "GET_DELETE_CUSTOMER_RECEIVER",
  /*** MUTATIONS  ***/
  SET_CUSTOMER_RECEIVERS: "SET_CUSTOMER_RECEIVERS",
  SET_CUSTOMER_RECEIVER: "SET_CUSTOMER_RECEIVER",
  SET_DELETE_CUSTOMER_RECEIVER: "SET_DELETE_CUSTOMER_RECEIVER",
  /*** ACTIONS  ***/
  FETCH_CUSTOMER_RECEIVERS: "FETCH_CUSTOMER_RECEIVERS",
  FETCH_CUSTOMER_RECEIVER: "FETCH_CUSTOMER_RECEIVER",

  UPDATE_CUSTOMER_RECEIVER: "UPDATE_CUSTOMER_RECEIVER",
  CREATE_CUSTOMER_RECEIVER: "CREATE_CUSTOMER_RECEIVER",
  DELETE_CUSTOMER_RECEIVER: "DELETE_CUSTOMER_RECEIVER",
};
export const CUSTOMER_CHANNELS_TYPES = {
  /*** GETTERS  ***/
  GET_CUSTOMER_CHANNELS: "GET_CUSTOMER_CHANNELS",
  GET_CUSTOMER_CHANNEL: "GET_CUSTOMER_CHANNEL",
  GET_DELETE_CUSTOMER_CHANNEL: "GET_DELETE_CUSTOMER_CHANNEL",
  /*** MUTATIONS  ***/
  SET_CUSTOMER_CHANNELS: "SET_CUSTOMER_CHANNELS",
  SET_CUSTOMER_CHANNEL: "SET_CUSTOMER_CHANNEL",
  SET_DELETE_CUSTOMER_CHANNEL: "SET_DELETE_CUSTOMER_CHANNEL",
  /*** ACTIONS  ***/
  FETCH_CUSTOMER_CHANNELS: "FETCH_CUSTOMER_CHANNELS",
  FETCH_CUSTOMER_CHANNEL: "FETCH_CUSTOMER_CHANNEL",

  UPDATE_CUSTOMER_CHANNEL: "UPDATE_CUSTOMER_CHANNEL",
  CREATE_CUSTOMER_CHANNEL: "CREATE_CUSTOMER_CHANNEL",
  DELETE_CUSTOMER_CHANNEL: "DELETE_CUSTOMER_CHANNEL",
};

export const SETTINGS_TYPE = {
  /*** ACTIONS  ***/
  FETCH_SETTINGS: "FETCH_SETTINGS",
};

export const USERS_TYPES = {
  /*** GETTERS  ***/
  GET_USERS: "GET_USERS",
  GET_USER: "GET_USER",

  /*** MUTATIONS  ***/
  SET_USERS: "SET_USERS",
  SET_USER: "SET_USER",

  /*** ACTIONS  ***/
  FETCH_USERS: "FETCH_USERS",
  FETCH_USER: "FETCH_USER",
  CREATE_USER: "CREATE_USER",
  UPDATE_USER: "UPDATE_USER",
  DELETE_USER: "DELETE_USER",
};
export const DATA_MODELS_TYPES = {
  /*** GETTERS  ***/
  GET_DATA_MODELS: "GET_DATA_MODELS",
  GET_DATA_MODEL: "GET_DATA_MODEL",
  GET_DELETE_DATA_MODEL: "GET_DELETE_DATA_MODEL",

  /*** MUTATIONS  ***/
  SET_DATA_MODELS: "SET_DATA_MODELS",
  SET_DATA_MODEL: "SET_DATA_MODEL",
  SET_DELETE_DATA_MODEL: "SET_DELETE_DATA_MODEL",

  /*** ACTIONS  ***/
  FETCH_DATA_MODELS: "FETCH_DATA_MODELS",
  FETCH_DATA_MODEL: "FETCH_DATA_MODEL",
  CREATE_DATA_MODEL: "CREATE_DATA_MODEL",
  UPDATE_DATA_MODEL: "UPDATE_DATA_MODEL",
  DELETE_DATA_MODEL: "DELETE_DATA_MODEL",
};
export const DATA_FIELDS_TYPES = {
  /*** GETTERS  ***/
  GET_DATA_FIELDS: "GET_DATA_FIELDS",
  GET_DATA_FIELD: "GET_DATA_FIELD",
  GET_DELETE_DATA_FIELD: "GET_DELETE_DATA_FIELD",

  /*** MUTATIONS  ***/
  SET_DATA_FIELDS: "SET_DATA_FIELDS",
  SET_DATA_FIELD: "SET_DATA_FIELD",
  SET_DELETE_DATA_FIELD: "SET_DELETE_DATA_FIELD",

  /*** ACTIONS  ***/
  FETCH_DATA_FIELDS: "FETCH_DATA_FIELDS",
  FETCH_DATA_FIELD: "FETCH_DATA_FIELD",
  CREATE_DATA_FIELD: "CREATE_DATA_FIELD",
  UPDATE_DATA_FIELD: "UPDATE_DATA_FIELD",
  DELETE_DATA_FIELD: "DELETE_DATA_FIELD",
};

export const FINDERS_TYPES = {
  /*** GETTERS  ***/
  GET_FINDER_COLLIS: "GET_FINDER_COLLIS",
  GET_FINDER_COLLI: "GET_FINDER_COLLI",

  GET_FINDER_POSITIONS: "GET_FINDER_POSITIONS",
  GET_FINDER_POSITION: "GET_FINDER_POSITION",
  GET_PAGE: "GET_PAGE",
  GET_ALL_COLLIS_LOADED: "GET_ALL_COLLIS_LOADED",

  /*** MUTATIONS  ***/
  SET_FINDER_COLLIS: "SET_FINDER_COLLIS",
  SET_FINDER_COLLI: "SET_FINDER_COLLI",
  SET_FINDER_POSITIONS: "SET_FINDER_POSITIONS",
  SET_FINDER_POSITION: "SET_FINDER_POSITION",
  SET_PAGE: "SET_PAGE",
  SET_ALL_COLLIS_LOADED: "SET_ALL_COLLIS_LOADED",

  /*** ACTIONS  ***/
  FETCH_FINDER_COLLIS: " FETCH_FINDER_COLLIS",
  FETCH_FINDER_POSITIONS: "FETCH_FINDER_POSITIONS",
};
