import axios from "axios";
import router from "../router";
import { HELPERS_TYPES, STORE_TYPES } from "../store/types";
import { removeAllCookies } from "./cookie";
import { getData } from "./dataHelper";
import store from "../store";

const ApiService = {
  init() {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL;

    this.setHeader();
    axios.interceptors.response.use(
      (response) => response,
      (error) => this.errorHandler(error)
    );
  },

  setHeader() {
    axios.defaults.withCredentials = true;
    axios.defaults.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
  },
  errorHandler(error) {
    if (getData(error, "response.status") == 401) {
      removeAllCookies();
      router.push({ name: "Login" });
      console.log("NOT AUTHORIZED");
    } else if (getData(error, "response.status") == 404) {
      console.log("NOT FOUND");
    } else if (getData(error, "response.status") == 422) {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_ERROR_ID_OBJECT}`,
        getData(error, "response.data.errors")
      );
      return error.response;
    }
    throw error;
  },

  query(resource, params) {
    return axios.get(resource, { params }).catch((error) => {
      return error;
    });
  },
  get(resource, slug = "") {
    return axios.get(`${resource}/${slug}`).catch((error) => {
      return error;
    });
  },

  async post(resource, params, config) {
    return await axios
      .post(`${resource}`, params, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },

  update(resource, slug, params) {
    // TODO: Data to FormData
    let slugString = slug ? `/${slug}` : "";

    return axios
      .put(`${resource}${slugString}`, params)
      .then((response) => {
        return response;
      })
      .catch(() => {
        return;
      });
  },

  delete(resource) {
    return axios.delete(resource).catch((error) => {
      return error;
    });
  },
};

export default ApiService;
