import { CUSTOMER_RECEIVERS_TYPES } from "../types";
import ApiService from "../../common/apiService";
import { getData } from "../../common/dataHelper";
import router from "../../router";

/**  ********************** STATE *********************** */
const state = {
  customer_receivers: [],
  customer_receiver: {},
  delete_customer_receiver: null,
};

/**  ********************** GETTERS *********************** */
const getters = {
  [`${CUSTOMER_RECEIVERS_TYPES.GET_CUSTOMER_RECEIVERS}`](state) {
    return state.customer_receivers;
  },

  [`${CUSTOMER_RECEIVERS_TYPES.GET_CUSTOMER_RECEIVER}`](state) {
    return state.customer_receiver;
  },
  [`${CUSTOMER_RECEIVERS_TYPES.GET_DELETE_CUSTOMER_RECEIVER}`](state) {
    return state.delete_customer_receiver;
  },
};

/**  ********************** ACTIONS *********************** */
const actions = {
  async [`${CUSTOMER_RECEIVERS_TYPES.FETCH_CUSTOMER_RECEIVERS}`](context, id) {
    const response = await ApiService.query("api/customers/" + id + "/anchors");
    if (getData(response, "status") === 200) {
      context.commit(
        `${CUSTOMER_RECEIVERS_TYPES.SET_CUSTOMER_RECEIVERS}`,
        getData(response, "data")
      );
      return getData(response, "data");
    }
  },

  /** Create CUSTOMER RECEIVER */
  async [`${CUSTOMER_RECEIVERS_TYPES.CREATE_CUSTOMER_RECEIVER}`](context, id) {
    let customer_receiver = context.state.customer_receiver;
    const response = await ApiService.post(
      "api/customers/" + id + "/anchors",
      customer_receiver
    );
    if (getData(response, "data.anchors") === 200) {
      state.customer_receivers.push(getData(response, "data.anchors"));
    }
    router.push({ name: "ProductCarrier" });
    return response;
  },

  /** Update CUSTOMER RECEIVER */

  async [`${CUSTOMER_RECEIVERS_TYPES.UPDATE_CUSTOMER_RECEIVER}`](context) {
    let customer_receiver = context.state.customer_receiver;
    const response = await ApiService.update(
      "api/customers/" + customer_receiver.customer_id + "/anchors",
      customer_receiver.id,
      customer_receiver
    );
    if (getData(response, "status") === 200) {
      context.commit(
        `${CUSTOMER_RECEIVERS_TYPES.SET_CUSTOMER_RECEIVER}`,
        getData(response, "data")
      );
    }

    return response;
  },

  /** DELETE CUSTOMER RECEIVERS */

  async [CUSTOMER_RECEIVERS_TYPES.delete_customer_receiver](context, id) {
    let customer_receiver = context.state.delete_customer_receiver;
    const response = await ApiService.delete(
      "api/customers/" + id + "/anchors/" + customer_receiver.id
    );

    if (customer_receiver != null) {
      const findIndex = context.state.customer_receivers.findIndex(
        (customerReceiver) => {
          return customerReceiver.id == customer_receiver.id;
        }
      );
      context.state.customer_receivers.splice(findIndex, 1);
      context.state.delete_customer_receiver = null;
    }

    return response;
  },
};

/**  ********************** MUTATIONS *********************** */
const mutations = {
  [`${CUSTOMER_RECEIVERS_TYPES.SET_CUSTOMER_RECEIVERS}`](state, value) {
    state.customer_receivers = value;
  },

  [`${CUSTOMER_RECEIVERS_TYPES.SET_CUSTOMER_RECEIVER}`](state, value) {
    state.customer_receiver = value;
  },
  [`${CUSTOMER_RECEIVERS_TYPES.SET_DELETE_CUSTOMER_RECEIVER}`](state, value) {
    state.delete_customer_receiver = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
