/**
 * Nullsafe Method of retrieving nested data
 * e.g.: (object, "sub_object.id") returns id of the sub_object OR null
 */
export function getData(data, columns) {
  if (data == null) return null;
  const columnsArray = columns.split(".");
  let resultData = data;
  columnsArray.forEach((column) => {
    if (resultData != null && resultData[column] != null) {
      resultData = resultData[column];
    } else {
      resultData = null;
    }
  });
  return resultData;
}
export function formatNumberToPrice(number) {
  if (!number) {
    return "";
  }
  if (isNaN(number)) {
    return "0,00 €";
  }
  let price = parseFloat(number);
  price = price.toFixed(2);

  let priceParts = price.toString().split(".");

  priceParts[0] = priceParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const returnPrice = priceParts.join(",");
  return `${returnPrice} €`;
}
export function isDataInArray(array, element) {
  if (!array || !element || !element.id) return false;

  let isInArray = false;
  for (var i = 0; i < array.length; i++) {
    if (array[i].id == element.id) {
      isInArray = true;
      break;
    }
  }

  return isInArray;
}

export function formatDataToRequest(object, prefix = "") {
  if (prefix != "" && prefix.slice(-1) == "]") {
    prefix += ".";
  } else if (prefix != "" && prefix.slice(-1) != "[") {
    prefix += ".";
  }
  let newObject = {};

  for (const key in object) {
    const element = object[key];
    let prefixKey = "";
    if (prefix.slice(-1) == "[") {
      prefixKey = prefix.slice(0, -1) + key;
    } else {
      prefixKey = prefix + key;
    }
    if (element instanceof File) {
      newObject[prefixKey] = element;
    } else if (typeof element == "object") {
      // is Array
      if (element && element.length >= 0) {
        newObject = Object.assign(
          newObject,
          formatDataToRequest(element, prefixKey + ".[")
        );
      } else {
        newObject = Object.assign(
          newObject,
          formatDataToRequest(element, prefixKey)
        );
      }
    } else {
      newObject[prefixKey] = element;
    }
  }

  return newObject;
}
export function arraysAreSame(array1, array2) {
  if (array1.length != array2.length) {
    return false;
  }

  var arraysAreSame = true;
  for (var propertyName in array1) {
    if (array1[propertyName] !== array2[propertyName]) {
      arraysAreSame = false;
      break;
    }
  }
  return arraysAreSame;
}
