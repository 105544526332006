<template>
  <div class="div h-16 w-full" :class="$route.meta.isLoggedIn ? '' : 'd-none'">
    <nav
      class="flex w-full items-center fixed left-0 top-0 h-16 bg-white lg:pl-20"
    >
      <div
        @click="$router.push({ name: 'ProductCarrier' })"
        class="w-24 cursor-pointer"
      >
        <img src="@/assets/enterData.svg" alt="Logo" class="ml-2 block w-24" />
      </div>

      <div class="flex ml-auto mr-5 items-center">
        <!--- TO-DO --- dropdown -->
        <dropdown :onOpendRotate="true" ref="openedDrop">
          <template #trigger>
            <div class=" flex place-items-center">
              <div class="h-8 w-8 rounded-full border border-black">
                <div class="cursor-pointer mt-2 ml-2">
                  <plus-icon />
                </div>
              </div>
            </div>
          </template>
          <template #items>
            <div class="flex flex-col gap-1 ">
              <div
                class="flex items-start mt-1 w-full cursor-pointer h-8  px-3 text-black hover:bg-white-dark rounded-md"
                @click="add_product_carrier()"
              >
                <div>
                  {{ $t("product_carrier") }}
                </div>
              </div>
              <div
                class="flex flex-col items-start w-full  cursor-pointer h-8  px-3 text-black hover:bg-white-dark rounded-md"
                @click="add_location()"
              >
                <div>
                  {{ $t("location") }}
                </div>
              </div>
            </div>
          </template>
        </dropdown>

        <div class="flex" id="navbar-actions"></div>
        <dropdown class="ml-6">
          <template #trigger>
            <avatar-bubble
              class="mr-4"
              :name="GET_AUTH_USER.fullname"
              :src="
                GET_AUTH_USER.avatar == null
                  ? null
                  : GET_AUTH_USER.avatar.picture_path
              "
            ></avatar-bubble>
          </template>
          <template #items>
            <div
              class=" flex items-center w-full  cursor-pointer  px-2 text-black hover:bg-white-dark rounded-md"
              @click="LOGOUT()"
            >
              <logout-icon
                class="mr-2 w-4"
                fillClass="fill-black"
              ></logout-icon>
              {{ $t("logout") }}
            </div>
          </template>
        </dropdown>
      </div>
    </nav>
  </div>

  <teleport to="body">
    <add-product-carrier-modal
      :fetchProp="true"
      :isVisible="state.openModal"
      @closeModal="state.openModal = false"
    />
    <add-location-modal
      :isVisible="state.openLocationModal"
      @closeModal="state.openLocationModal = false"
    />
  </teleport>
</template>

<script>
import { defineComponent, computed, reactive, ref } from "vue";
import { useStore } from "vuex";
import {
  STORE_TYPES,
  HELPERS_TYPES,
  SEARCH_TYPES,
  AUTH_TYPES,
} from "../../store/types";
import AddLocationModal from "../../views/finders/modals/AddLocationModal.vue";
import AddProductCarrierModal from "../../views/finders/modals/AddProductCarrierModal.vue";
import AvatarBubble from "../global/AvatarBubble.vue";
import Dropdown from "../global/Dropdown.vue";
import PlusIcon from "../icons/PlusIcon.vue";

// import IconContainer from "../global/IconContainer.vue";

export default defineComponent({
  components: {
    AvatarBubble,
    // IconContainer,
    Dropdown,
    AddProductCarrierModal,
    AddLocationModal,
    PlusIcon,
  },
  name: "Navbar",
  setup() {
    const store = useStore();
    const openedDrop = ref();

    const state = reactive({
      showSearch: false,
      openModal: false,
      openLocationModal: false,
      triggerOpend: false,
    });
    /**
     *  Functions
     */
    function SET_IS_SIDEBAR_ACTIVE(value) {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_IS_SIDEBAR_ACTIVE}`,
        value
      );
    }

    function toggleSearchbar() {
      state.showSearch = !state.showSearch;
    }

    function changeTriggerIcon() {
      if (state.triggerOpend == false) {
        state.triggerOpend = true;
      } else {
        state.triggerOpend = false;
      }
    }

    function LOGOUT() {
      return store.dispatch(`${STORE_TYPES.AUTH}/${AUTH_TYPES.LOGOUT}`);
    }

    function add_product_carrier() {
      state.openModal = true;
    }
    function add_location() {
      state.openLocationModal = true;
    }

    /**
     *  Computed
     */
    const GET_IS_SIDEBAR_ACTIVE = computed(
      () =>
        store.getters[
          `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.GET_IS_SIDEBAR_ACTIVE}`
        ]
    );
    const GET_SEARCH_ITEMS = computed(
      () =>
        store.getters[`${STORE_TYPES.SEARCH}/${SEARCH_TYPES.GET_SEARCH_ITEMS}`]
    );
    const search = computed({
      set(value) {
        store.commit(`${STORE_TYPES.SEARCH}/${SEARCH_TYPES.SET_SEARCH}`, value);
      },
      get() {
        return store.getters[
          `${STORE_TYPES.SEARCH}/${SEARCH_TYPES.GET_SEARCH}`
        ];
      },
    });

    const GET_AUTH_USER = computed(
      () => store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`]
    );

    return {
      //State
      state,
      // Computed
      search,
      GET_IS_SIDEBAR_ACTIVE,
      GET_SEARCH_ITEMS,
      GET_AUTH_USER,
      // Functions
      add_product_carrier,
      add_location,
      SET_IS_SIDEBAR_ACTIVE,
      toggleSearchbar,
      changeTriggerIcon,
      LOGOUT,
      openedDrop,
    };
  },
});
</script>

<style lang="scss">
.d-none {
  display: none;
  &:only-child {
    display: none;
  }
}
.searchbar {
  @screen lg {
    width: 500px;
  }
  @screen xl {
    width: 545px;
  }
}
</style>
