<template>
  <svg
    id="Drag_Drop"
    data-name="Drag&amp;Drop"
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="10"
    viewBox="0 0 6 10"
  >
    <rect
      id="Rechteck_923"
      data-name="Rechteck 923"
      width="2"
      height="2"
      rx="0.5"
      fill="#676b72"
    />
    <rect
      id="Rechteck_924"
      data-name="Rechteck 924"
      width="2"
      height="2"
      rx="0.5"
      transform="translate(4)"
      fill="#676b72"
    />
    <rect
      id="Rechteck_926"
      data-name="Rechteck 926"
      width="2"
      height="2"
      rx="0.5"
      transform="translate(0 4)"
      fill="#676b72"
    />
    <rect
      id="Rechteck_927"
      data-name="Rechteck 927"
      width="2"
      height="2"
      rx="0.5"
      transform="translate(4 4)"
      fill="#676b72"
    />
    <rect
      id="Rechteck_929"
      data-name="Rechteck 929"
      width="2"
      height="2"
      rx="0.5"
      transform="translate(0 8)"
      fill="#676b72"
    />
    <rect
      id="Rechteck_930"
      data-name="Rechteck 930"
      width="2"
      height="2"
      rx="0.5"
      transform="translate(4 8)"
      fill="#676b72"
    />
  </svg>
</template>


<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DragIcon",
  props: {
    fillClass: {
      type: String,
      default: "",
    },
  },
});
</script>