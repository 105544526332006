import { COLLI_POSITIONS_TYPES } from "../types";
import ApiService from "../../common/apiService";
import { getData } from "../../common/dataHelper";
import router from "../../router";
/************************** HELPERS ********************* */

// DTO :
function createColliPositionDTO(colli_position) {
  var create_colli_position = {
    collis: getData(colli_position, "collis"),
    data_model_id: getData(colli_position, "dataModel.id"),
    dataModel: getData(colli_position, "dataModel"),
    data: getData(colli_position, "data"),
    name: getData(colli_position, "name"),
  };
  return create_colli_position;
}

/**  ********************** STATE *********************** */
const state = {
  colli_positions: [],
  colli_position: {},
  delete_colli_position: null,
};

/**  ********************** GETTERS *********************** */
const getters = {
  [`${COLLI_POSITIONS_TYPES.GET_COLLI_POSITIONS}`](state) {
    return state.colli_positions;
  },

  [`${COLLI_POSITIONS_TYPES.GET_COLLI_POSITION}`](state) {
    return state.colli_position;
  },
  [`${COLLI_POSITIONS_TYPES.GET_DELETE_COLLI_POSITION}`](state) {
    return state.delete_colli_position;
  },
};

/**  ****************** ACTIONS *********************** */
const actions = {
  async [`${COLLI_POSITIONS_TYPES.FETCH_COLLI_POSITIONS}`](context, { id }) {
    const response = await ApiService.query(
      "api/customers/" + id + "/colliPositions"
    );
    if (getData(response, "status") === 200) {
      context.commit(
        `${COLLI_POSITIONS_TYPES.SET_COLLI_POSITIONS}`,
        getData(response, "data")
      );
      return getData(response, "data");
    }
  },

  /** Create  COLLI POSITION */
  async [`${COLLI_POSITIONS_TYPES.CREATE_COLLI_POSITION}`](context, id) {
    let colli_position = context.state.colli_position;

    let create_colli_position = createColliPositionDTO(colli_position);

    const response = await ApiService.post(
      "api/customers/" + id + "/colliPositions",
      create_colli_position
    );
    if (getData(response, "status") === 201) {
      context.state.colli_positions.push(getData(response, "data"));
    }
    router.push({ name: "ProductCarrier" });
    return response;
  },

  /** Update  COLLI POSITION */
  async [`${COLLI_POSITIONS_TYPES.UPDATE_COLLI_POSITION}`](context) {
    let colli_position = context.state.colli_position;
    let create_colli_position = createColliPositionDTO(colli_position);
    const response = await ApiService.update(
      "api/customers/" + colli_position.customer_id + "/colliPositions",
      colli_position.id,
      create_colli_position
    );
    if (getData(response, "status") === 200) {
      context.commit(
        `${COLLI_POSITIONS_TYPES.SET_COLLI_POSITION}`,
        getData(response, "data")
      );
      return getData(response, "data");
    }
  },
  /** DELETE  COLLI POSITION */

  async [COLLI_POSITIONS_TYPES.DELETE_COLLI_POSITION](context) {
    let colli_position = context.state.delete_colli_position;

    const response = await ApiService.delete(
      "api/customers/" +
        colli_position.customer_id +
        "/colliPositions/" +
        colli_position.id,
      colli_position
    );

    if (colli_position != null) {
      const findIndex = context.state.colli_positions.findIndex(
        (colliPosition) => {
          return colliPosition.id == colli_position.id;
        }
      );

      context.state.colli_positions.splice(findIndex, 1);
      context.state.delete_colli_position = null;
    }

    return response;
  },
};

/**  ********************** MUTATIONS *********************** */
const mutations = {
  [`${COLLI_POSITIONS_TYPES.SET_COLLI_POSITIONS}`](state, value) {
    state.colli_positions = value;
  },

  [`${COLLI_POSITIONS_TYPES.SET_COLLI_POSITION}`](state, value) {
    state.colli_position = value;
  },
  [`${COLLI_POSITIONS_TYPES.SET_DELETE_COLLI_POSITION}`](state, value) {
    state.delete_colli_position = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
