<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.5"
    height="17.805"
    viewBox="0 0 26.5 17.805"
  >
    <g id="Gruppe_386" data-name="Gruppe 386" transform="translate(-707 -208)">
      <g id="Upload" transform="translate(707 208)">
        <path
          id="Pfad_394"
          data-name="Pfad 394"
          d="M13.25,14.35a.75.75,0,0,1-.75-.75V9.549a.75.75,0,0,1,1.5,0V13.6a.75.75,0,0,1-.75.75"
          fill="#0a0a0a"
        />
        <path
          id="Pfad_395"
          data-name="Pfad 395"
          d="M16.595,10.548l-6.69.006A.5.5,0,0,1,9.55,9.7l3.341-3.346a.5.5,0,0,1,.709,0l3.349,3.341a.5.5,0,0,1-.354.856"
          fill="#0a0a0a"
        />
        <path
          id="Pfad_396"
          data-name="Pfad 396"
          d="M20.858,17.805H6.728A6.727,6.727,0,0,1,5.914,4.4,7.9,7.9,0,0,1,13.25,0c4.23,0,7.687,2.909,7.812,6.525a5.642,5.642,0,0,1-.2,11.28M13.25,1.5c-2.833,0-5.338,1.582-6.093,3.846a.75.75,0,0,1-.678.512,5.226,5.226,0,0,0,.249,10.447h14.13a4.142,4.142,0,1,0,0-8.283,4.332,4.332,0,0,0-.489.027.749.749,0,0,1-.831-.834,4.265,4.265,0,0,0,.027-.486c0-2.883-2.833-5.229-6.315-5.229"
          fill="#0a0a0a"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "UploadIcon2",
  props: {
    fillClass: {
      type: String,
 
      default: '',
    },
  },
});
</script>
