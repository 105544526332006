import { AUTH_TYPES } from "../types";
import router from "../../router";
import { getCookie, removeCookie, setCookie } from "../../common/cookie";
import ApiService from "../../common/apiService";
import { getData } from "../../common/dataHelper";

const state = {
  user: {},
  users: [],
  wrong_password: false,
  permissions: [],
};

const getters = {
  [AUTH_TYPES.GET_USER](state) {
    return state.user;
  },
  [AUTH_TYPES.GET_PERMISSIONS](state) {
    return state.permissions;
  },
  [AUTH_TYPES.GET_USERS](state) {
    return state.users;
  },
  [AUTH_TYPES.IS_LOGGED_IN]() {
    return getCookie("logged_in");
  },
  [AUTH_TYPES.GET_WRONG_PASSWORD]() {
    return state.wrong_password;
  },
};

const actions = {
  /** LOGINNN */
  async [AUTH_TYPES.LOGIN](context, { user }) {
    let expiringDays = 1;

    if (user.stay_logged_in) {
      expiringDays = 365;
    }

    const response = await ApiService.query("sanctum/csrf-cookie").then(() => {
      return ApiService.post("api/login", user);
    });
    if (getData(response, "status") === 200) {
      context.state.user = getData(response, "data.user");

      setCookie("logged_in", true, expiringDays);

      router.push({
        name: "ProductCarrier",
      });
    } else if (getData(response, "response.status") == 423) {
      router.push({
        name: "TwoFactorCode",
        params: { email: user.email, password: user.password },
      });
    } else if (getData(response, "response.status") == 422) {
      return false;
    } else if (getData(response, "response.status") == 404) {
      return false;
    }

    return true;
  },

  /**  REGISTER */

  async [AUTH_TYPES.REGISTER](context, user) {
    const response = await ApiService.query("sanctum/csrf-cookie").then(() => {
      return ApiService.post("api/register", user);
    });
    if (getData(response, "status") === 200) {
      context.state.user = getData(response, "data");
      setCookie("logged_in", true, 1);
      router.push({
        name: "ProductCarrier",
      });
    } else if (getData(response, "response.status") === 422) {
      return false;
    } else if (getData(response, "response.status") === 401) {
      return false;
    }
    return true;
  },

  /** FETCH USER INFORMATION  */
  async [AUTH_TYPES.FETCH_TOKEN_USERINFORMATION](context, token) {
    const response = await ApiService.query("sanctum/csrf-cookie").then(
      async () => {
        return await ApiService.post("api/register/user", {
          token,
        });
      }
    );

    if (getData(response, "status") === 200) {
      return getData(response, "data.user");
    }
    return response;
  },
  /** FETCH PERMISSIONS  */
  async [`${AUTH_TYPES.FETCH_PERMISSIONS}`](context) {
    const response = await ApiService.query("api/permissions");
    if (getData(response, "status") === 200) {
      context.commit(
        `${AUTH_TYPES.SET_PERMISSIONS}`,
        getData(response, "data")
      );
      return getData(response, "data");
    }
  },

  /** CHANGE PASSWORD   */

  async [AUTH_TYPES.CHANGE_PASSWORD](context, user) {
    const response = await ApiService.query("sanctum/csrf-cookie").then(() => {
      return ApiService.post("api/changePassword", user);
    });

    if (getData(response, "status") === 200) {
      router.push({ name: "Login" });
    }
    return response;
  },

  /** FETCH USER INFORMATION  ( CHANGE PASSOWRD )  */
  async [AUTH_TYPES.FETCH_CHANGE_PASSWORD_USERINFORMATION](context, token) {
    const response = await ApiService.post("api/changePassword/user", {
      token,
    });

    if (getData(response, "status") === 200) {
      return getData(response, "data.user");
    }
    return response;
  },

  /** RESET PASSWORD  */
  async [AUTH_TYPES.RESET_PASSWORD](context, user) {
    console.log("passwort zurücksetzen!!!");
    const response = await ApiService.query("sanctum/csrf-cookie").then(() => {
      return ApiService.post("api/resetPassword", user);
    });

    if (getData(response, "status") === 200) {
      router.push({ name: "Login" });
    }
    return;
  },

  /** SEND 2FA CODE   */
  async [AUTH_TYPES.SEND_2FA_CODE]({ state }, { twoFactorItem }) {
    const response = await ApiService.query("sanctum/csrf-cookie").then(() => {
      return ApiService.post("api/login");
    });
    console.log("send 2FA code");
    console.log(twoFactorItem);
    console.log(state);

    return response;
  },
  async [AUTH_TYPES.RESEND_2FA_CODE]() {
    console.log("resend code");
  },

  /** FETCH USER  */

  async [AUTH_TYPES.FETCH_USER](context) {
    const response = await ApiService.query("/api/user");
    if (response.status == 200) {
      context.state.user = response.data;
    }
    return response;
  },

  /** LOGOUT  */
  async [AUTH_TYPES.LOGOUT]() {
    if (getCookie("logged_in")) {
      ApiService.post("/api/logout");
      removeCookie("logged_in");
    }
    router.push({ name: "Login" });
  },

  /** UPDATE AUTH _ PROFILE   */

  async [AUTH_TYPES.UPDATE_PROFILE](context, user) {
    const response = await ApiService.update("api/user", null, user);
    if (response.status == 200) {
      context.state.user = response.data;
    }
    return response;
  },

  /** UPLOAD AVATAR   */
  async [AUTH_TYPES.UPLOAD_AVATAR](context, avatar) {
    const response = await ApiService.post("api/user/avatar", avatar);
    if (getData(response, "status") === 201) {
      context.state.user.avatar = getData(response, "data");
    }
    return response;
  },

  /** DELETE AVATAR   */
  async [AUTH_TYPES.DELETE_AVATAR](context, user) {
    const response = await ApiService.delete("api/user/avatar", user);
    if (getData(response, "status") === 204) {
      context.state.user.avatar = null;
    }
    return response;
  },
};

/** MUTATIONS  */

const mutations = {
  [`${AUTH_TYPES.SET_USER}`](state, value) {
    state.user = value;
  },
  [`${AUTH_TYPES.SET_PERMISSIONS}`](state, value) {
    state.permissions = value;
  },
  [`${AUTH_TYPES.SET_WRONG_PASSWORD}`](state, value) {
    state.wrong_password = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
