import { CUSTOMER_COLLIS_TYPES, HELPERS_TYPES, STORE_TYPES } from "../types";
import ApiService from "../../common/apiService";
import { getData } from "../../common/dataHelper";
import router from "../../router";

/************************** HELPERS ********************* */

// DTO :
function createColliDTO(customer_colli) {
  var create_colli = {
    tag_id: getData(customer_colli, "tag.id"),
    parent: getData(customer_colli, "parent"),
    colliPositions: getData(customer_colli, "colliPositions"),
    parent_id: getData(customer_colli, "parent.id"),
    data_model_id: getData(customer_colli, "dataModel.id"),
    // dataModel: getData(customer_colli, "dataModel"),
    data: getData(customer_colli, "data"),
    name: getData(customer_colli, "name"),
  };
  return create_colli;
}

/**  ********************** STATE *********************** */
const state = {
  customer_collis: [],
  customer_colli: {},
  delete_customer_colli: null,
  tag: {},
  page: {},
  allCollisLoaded: false,
};

/**  ********************** GETTERS *********************** */
const getters = {
  [`${CUSTOMER_COLLIS_TYPES.GET_CUSTOMER_COLLIS}`](state) {
    return state.customer_collis;
  },

  [`${CUSTOMER_COLLIS_TYPES.GET_CUSTOMER_COLLI}`](state) {
    return state.customer_colli;
  },
  [`${CUSTOMER_COLLIS_TYPES.GET_DELETE_CUSTOMER_COLLI}`](state) {
    return state.delete_customer_colli;
  },

  [`${CUSTOMER_COLLIS_TYPES.GET_COLLI_TAG}`](state) {
    return state.tag;
  },
  [`${CUSTOMER_COLLIS_TYPES.GET_PAGE}`](state) {
    return state.page;
  },
  [`${CUSTOMER_COLLIS_TYPES.GET_ALL_COLLIS_LOADED}`](state) {
    return state.allCollisLoaded;
  },
};

/**  ********************** ACTIONS *********************** */
const actions = {
  async [`${CUSTOMER_COLLIS_TYPES.FETCH_CUSTOMER_COLLIS}`](
    context,
    { id, params }
  ) {
    context.commit(
      `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
      "infinite-loading",
      { root: true }
    );
    const response = await ApiService.query(
      "api/customers/" + id + "/collis",
      params
    );
    const collis = getData(response, "data.collis");
    const links = getData(response, "data.links");
    const meta = getData(response, "data.meta");
    if (getData(response, "status") === 200) {
      if (
        collis != null &&
        params != null &&
        params.page != null &&
        params.page != 1
      ) {
        //Push response collis in old collis
        const oldCollis = context.state.customer_collis;
        const newCollis = oldCollis.concat(collis);
        context.commit(
          `${CUSTOMER_COLLIS_TYPES.SET_CUSTOMER_COLLIS}`,
          newCollis
        );
      } else if (collis != null) {
        context.commit(`${CUSTOMER_COLLIS_TYPES.SET_CUSTOMER_COLLIS}`, collis);
      }
      if (meta != null && meta.current_page != null) {
        context.commit(`${CUSTOMER_COLLIS_TYPES.SET_PAGE}`, meta.current_page);
      }
      if (links.next == null) {
        context.commit(`${CUSTOMER_COLLIS_TYPES.SET_ALL_COLLIS_LOADED}`, true);
      } else {
        context.commit(`${CUSTOMER_COLLIS_TYPES.SET_ALL_COLLIS_LOADED}`, false);
      }
      context.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "infinite-loading",
        { root: true }
      );
      return getData(response, "data.collis");
    }
  },

  async [`${CUSTOMER_COLLIS_TYPES.FETCH_CUSTOMER_COLLI}`](context) {
    const customer_colli = context.state.customer_colli;
    const response = await ApiService.get(
      "api/customers/" + customer_colli.customer_id + "/collis",
      customer_colli.id
    );

    if (getData(response, "status") === 200) {
      context.commit(
        `${CUSTOMER_COLLIS_TYPES.SET_CUSTOMER_COLLI}`,
        getData(response, "data")
      );
      return getData(response, "data");
    }
  },

  async [`${CUSTOMER_COLLIS_TYPES.FETCH_COLLI_POSITIONS}`](context) {
    let customer_colli = context.state.customer_colli;

    const response = await ApiService.query(
      "api/customers/" +
        customer_colli.customer_id +
        "/collis/" +
        customer_colli.id
    );
    if (getData(response, "status") === 200) {
      context.commit(
        `${CUSTOMER_COLLIS_TYPES.SET_CUSTOMER_COLLI}`,
        getData(response, "data")
      );
      return getData(response, "data");
    }
  },

  /** Create CUSTOMER COLLI */
  async [`${CUSTOMER_COLLIS_TYPES.CREATE_CUSTOMER_COLLI}`](context, id) {
    let customer_colli = context.state.customer_colli;

    let create_colli = createColliDTO(customer_colli);

    const response = await ApiService.post(
      "api/customers/" + id + "/collis",
      create_colli
    );

    const createdColli = getData(response, "data");

    if (getData(response, "status") === 201) {
      context.state.customer_collis.push(createdColli);
    } else {
      return response;
    }

    router.push({ name: "ProductCarrier" });
  },

  /** Update CUSTOMER COLLI */

  async [`${CUSTOMER_COLLIS_TYPES.UPDATE_CUSTOMER_COLLI}`](context) {
    let customer_colli = context.state.customer_colli;
    let create_colli = createColliDTO(customer_colli);
    const response = await ApiService.update(
      "api/customers/" + customer_colli.customer_id + "/collis",
      customer_colli.id,
      create_colli
    );
    // save colli into a variable
    const updatedColli = getData(response, "data");
    if (getData(response, "status") === 200) {
      // find updatedColli index in customercollis[]  and replace it
      const findIndex = context.state.customer_collis.findIndex((colli) => {
        return colli.id == customer_colli.id;
      });
      context.state.customer_collis[findIndex] = updatedColli;
    }

    return response;
  },

  /** DELETE CUSTOMER COLLI */

  async [CUSTOMER_COLLIS_TYPES.DELETE_CUSTOMER_COLLI](context) {
    let customer_colli = context.state.delete_customer_colli;

    const response = await ApiService.delete(
      "api/customers/" +
        customer_colli.customer_id +
        "/collis/" +
        customer_colli.id
    );

    if (customer_colli != null) {
      const findIndex = context.state.customer_collis.findIndex(
        (customerColli) => {
          return customerColli.id == customer_colli.id;
        }
      );

      context.state.customer_collis.splice(findIndex, 1);
      context.state.delete_customer_colli = null;
    }

    return response;
  },
};

/**  ********************** MUTATIONS *********************** */
const mutations = {
  [`${CUSTOMER_COLLIS_TYPES.SET_CUSTOMER_COLLIS}`](state, value) {
    state.customer_collis = value;
  },

  [`${CUSTOMER_COLLIS_TYPES.SET_CUSTOMER_COLLI}`](state, value) {
    state.customer_colli = value;
  },
  [`${CUSTOMER_COLLIS_TYPES.SET_DELETE_CUSTOMER_COLLI}`](state, value) {
    state.delete_customer_colli = value;
  },
  [`${CUSTOMER_COLLIS_TYPES.SET_COLLI_TAG}`](state, value) {
    state.tag = value;
  },
  [`${CUSTOMER_COLLIS_TYPES.SET_PAGE}`](state, value) {
    state.page = value;
  },
  [`${CUSTOMER_COLLIS_TYPES.SET_ALL_COLLIS_LOADED}`](state, bool) {
    state.allCollisLoaded = bool;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
