import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import { AUTH_TYPES, HELPERS_TYPES, STORE_TYPES } from "../store/types";

const routes = [
  {
    path: "",
    redirect: "/finder",
  },
  {
    path: "/finder",
    name: "Finder",
    component: () => import("../views/finders/Index.vue"),
    meta: {
      requiresAdmin: true,
      isLoggedIn: true,
      isFinderRoute: true,
    },

    children: [
      {
        path: "",
        name: "ProductCarrier",
        component: () => import("../views/finders/show/FinderCollis.vue"),
        meta: {
          requiresAdmin: true,
          isLoggedIn: true,
          isFinderChildRoute: true,
        },
      },
      {
        path: "",
        name: "Locations",
        component: () => import("../views/finders/show/FinderLocations.vue"),
        meta: {
          requiresAdmin: true,
          isLoggedIn: true,
          isFinderChildRoute: true,
        },
      },
    ],
  },

  {
    path: "/colli",
    name: "Colli",
    component: () => import("../views/collis/Index.vue"),
    meta: {
      requiresAdmin: true,
      isLoggedIn: true,
    },
  },
  {
    path: "/positions",
    name: "ColliPosition",
    component: () => import("../views/positions/Index.vue"),
    meta: {
      requiresAdmin: true,
      isLoggedIn: true,
    },
  },
  {
    path: "/archive",
    name: "Archive",
    component: () => import("../views/archives/Index.vue"),
    meta: {
      requiresAdmin: true,
      isLoggedIn: true,
    },
  },

  {
    path: "/locations",
    name: "Location",
    component: () => import("../views/locations/Index.vue"),
    meta: {
      requiresAdmin: true,
      isLoggedIn: true,
    },
  },

  {
    path: "/channels",
    name: "Channel",
    component: () => import("../views/channels/Index.vue"),
    meta: {
      requiresAdmin: true,
      isLoggedIn: true,
    },
  },
  {
    path: "/receivers",
    name: "Receiver",
    component: () => import("../views/receivers/Index.vue"),
    meta: {
      requiresAdmin: true,
      isLoggedIn: true,
    },
  },

  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/settings/Index.vue"),
    meta: {
      requiresAdmin: true,
      isLoggedIn: true,
    },
  },

  {
    path: "/register",
    name: "Register",
    component: () => import("../views/auth/Register.vue"),
    meta: {
      isLoggedIn: false,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/auth/Login.vue"),
    meta: {
      isLoggedIn: false,
    },
  },
  {
    path: "/password-reset",
    name: "ResetPassword",
    component: () => import("../views/auth/ResetPassword.vue"),
    meta: {
      isLoggedIn: false,
    },
  },
  {
    path: "/password-change",
    name: "ChangePassword",
    component: () => import("../views/auth/ChangePassword.vue"),
    meta: {
      isLoggedIn: false,
    },
  },
  {
    path: "/2fa",
    name: "TwoFactorCode",
    component: () => import("../views/auth/TwoFactorCode.vue"),
    meta: {
      isLoggedIn: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// set firstRedirect
let isFirstRedirect = true;

// visited page from Localstorage
router.afterEach((to) => localStorage.setItem("lastVisitedPage", to.name));

router.beforeEach(async (to, from, next) => {
  // check last visited page from Localstorage
  const lastVisitedPage = localStorage.getItem("lastVisitedPage");
  const executableRedirect = lastVisitedPage && isFirstRedirect;

  // set first redirect = false
  isFirstRedirect = false;

  executableRedirect
    ? next({
        name: lastVisitedPage,
      })
    : next();

  if (
    from.matched.length == 0 ||
    to.matched.length == 0 ||
    from.matched[0].path != to.matched[0].path
  ) {
    store.dispatch(`${STORE_TYPES.HELPERS}/${HELPERS_TYPES.RESET_DATA}`);
  }
  const isLoggedIn =
    store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.IS_LOGGED_IN}`];
  if (from.name == null) {
    if (to.meta.isLoggedIn === true) {
      store.dispatch(`${STORE_TYPES.AUTH}/${AUTH_TYPES.FETCH_USER}`);
    }
  }
  const routeRequiresLogIn = to.matched.some(
    (record) => record.meta.isLoggedIn
  );
  if (!isLoggedIn && routeRequiresLogIn) {
    next({ name: "Login" });
    return;
  } else if (isLoggedIn && !routeRequiresLogIn) {
    next({ name: "Finder" });
  }

  next();
});

export default router;
