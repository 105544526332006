<template>
    <svg
        id="Logout"
        xmlns="http://www.w3.org/2000/svg"
        width="18.093"
        height="19.5"
        viewBox="0 0 18.093 19.5"
    >
        <path
            id="Pfad_391"
            data-name="Pfad 391"
            d="M13.792,19.5h-9a.75.75,0,0,1-.75-.75V.75A.75.75,0,0,1,4.792,0h9a.75.75,0,0,1,0,1.5H5.542V18h8.25a.75.75,0,1,1,0,1.5"
            transform="translate(-4.042)"
            :class="fillClass + ' transition-fill'"
        />
        <path
            id="Pfad_392"
            data-name="Pfad 392"
            d="M18.792,10.5H10.75a.75.75,0,0,1,0-1.5h8.042a.75.75,0,0,1,0,1.5"
            transform="translate(-4.042)"
            :class="fillClass + ' transition-fill'"
        />
        <path
            id="Pfad_393"
            data-name="Pfad 393"
            d="M17.792,13.1l-.007-6.69a.5.5,0,0,1,.856-.355l3.347,3.341a.5.5,0,0,1,0,.709L18.647,13.45a.5.5,0,0,1-.855-.354"
            transform="translate(-4.042)"
            :class="fillClass + ' transition-fill'"
        />
    </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "LogoutIcon",
    props: {
        fillClass: {
            type: String,
            required: true,
        },
    },
});
</script>
