<template>
    <svg
        id="BurgerMenu"
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="14.5"
        viewBox="0 0 22 14.5"
    >
        <rect
            id="Rechteck_920"
            data-name="Rechteck 920"
            width="22"
            height="1.891"
            rx="0.5"
            :class="fillClass + ' transition-fill'"
        />
        <rect
            id="Rechteck_921"
            data-name="Rechteck 921"
            width="22"
            height="1.891"
            rx="0.5"
            transform="translate(0 6.304)"
            :class="fillClass + ' transition-fill'"
        />
        <rect
            id="Rechteck_922"
            data-name="Rechteck 922"
            width="22"
            height="1.891"
            rx="0.5"
            transform="translate(0 12.609)"
            :class="fillClass + ' transition-fill'"
        />
    </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "MenuIcon",
    props: {
        fillClass: {
            type: String,
            required: true,
        },
    },
});
</script>
