import Pusher from "pusher-js";
import { getData } from "./dataHelper";
import store from "../store";
import { STORE_TYPES, AUTH_TYPES } from "../store/types";
import { changeCurrentPosition, updateCollis } from "./pusherHelperFunctions";
import { reactive } from "vue";



export const pusherStore = reactive({
    pusher: null,
    activeChannels: null,
    activeRoute:null,  
    
    initPusher(){
        this.pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
            cluster: "eu",
            forceTLS: true,
            logToConsole: true,
            authEndpoint: process.env.VUE_APP_API_URL + "/updatePosition",
        });
        this.activeChannels = [];
        this.activeRoute = "";
    },

    getAuthUser() {
        return store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`];
    },

    //unsubscribe
    unsubscribeChannels() {
        this.activeChannels.forEach((channelName) => {
            this.pusher.unsubscribe(channelName);
        });
    },

    subscribeToUpdateCollis() {
        let user = this.getAuthUser();

        this.activeChannels.push(user.customer_id + "-position-update");
        let channel = this.pusher.subscribe(user.customer_id + "-position-update");
        channel.bind("update", (data) => {
            let collis = getData(data, "collis");
            updateCollis(collis);
        });
    },

    subscribeToChangeCurrentPositions() {
        let user = this.getAuthUser();
        let channel = this.pusher.subscribe(user.customer_id + "-position-update");
        channel.bind("change", (data) => {
            let collis = getData(data, "collis");
            changeCurrentPosition(collis);
        });
    }

});