<template>
  <bottom-up-modal
    :isVisible="isVisible"
    @closeModal="$emit('closeModal')"
    :title="
      GET_CUSTOMER_POSITION != null && GET_CUSTOMER_POSITION.id != null
        ? $t('edit_location')
        : $t('add_location')
    "
  >
    <!-- Location form -->
    <add-location-form
      :position="position"
      @closeModal="$emit('closeModal')"
      @setBack="set_back()"
      @updatePosition="UPDATE_POSITION()"
      @createPosition="CREATE_POSITION()"
    />

    <div class="mt-8 mb-8 w-full h-px bg-primary-light"></div>

    <div class="flex flex-col gap-3">
      <div>
        <div class="font-semibold text-lg ">
          {{ $t("location") }}
        </div>

        <!-- search input-->
        <searchbar
          :id="'search'"
          v-model="state.search"
          :label="$t('search')"
          :labelClass="'bg-white'"
          :activeColor="'primary'"
          class="mt-5 w-full sm:w-1/3 bg-white"
          :placeHolder="$t('designation')"
          @update="
            (value) => {
              state.search = value;
            }
          "
        />
      </div>

      <!--Location list-->

      <div v-if="filteredPositions != null && filteredPositions.length > 0">
        <div
          class="bg-white-dark rounded-md w-full p-2 mt-2 hover:border-grey-fontgrey"
          :class="
            GET_CUSTOMER_POSITION.id == position.id
              ? 'border-t-4 border-primary'
              : 'border border-transparent'
          "
          v-for="position of filteredPositions"
          :key="position.id"
          :position="position"
        >
          <div class="p-2 flex items-center" v-if="position != null">
            <div class="flex flex-col">
              <div
                class=" flex text-xs font-semibold  box-content md:text-md gap-1"
              >
                <div class="font-semibold">{{ position.name }}</div>
              </div>
              <div class="text-xs md:text-sm">
                <div
                  class=" flex items-center text-sm gap-2"
                  v-if="position.parent != null"
                >
                  <div>
                    {{ $t("superior_location") }}:
                    {{ position.parent.name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="ml-auto md:mr-7">
              <div class="flex items-center space-x-6 ">
                <edit-icon
                  class="cursor-pointer"
                  @click="edit_position(position)"
                />
                <trash-icon
                  class="cursor-pointer"
                  @click="open_delete_modal(position)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--empty feedback-->
      <div v-else>
        <empty-list-feedback
          class="pt-10 flex justify-center items-center"
          v-if="filteredPositions == null || filteredPositions.length == 0"
          :text="$t('no_position')"
        ></empty-list-feedback>
      </div>
    </div>
    <delete-location-modal
      :isVisible="state.openDeleteModal"
      @closeModal="state.openDeleteModal = false"
      @deletePosition="DELETE_POSITION()"
    />
  </bottom-up-modal>
</template>

<script>
import { reactive } from "@vue/reactivity";

import { useStore } from "vuex";

import BottomUpModal from "../../../components/layout/BottomUpModal.vue";
import TrashIcon from "../../../components/icons/TrashIcon.vue";
import EditIcon from "../../../components/icons/EditIcon.vue";
import Searchbar from "../../../components/layout/Searchbar.vue";
import DeleteLocationModal from "./DeleteLocationModal.vue";
import AddLocationForm from "../components/LocationForm.vue";
import {
  AUTH_TYPES,
  CUSTOMER_POSITIONS_TYPES,
  CUSTOMER_RECEIVERS_TYPES,
  HELPERS_TYPES,
  STORE_TYPES,
} from "../../../store/types";
import { computed, watch } from "@vue/runtime-core";
import EmptyListFeedback from "../../../components/global/EmptyListFeedback.vue";
// import { useRouter } from "vue-router";

export default {
  components: {
    BottomUpModal,
    TrashIcon,
    EditIcon,
    Searchbar,
    DeleteLocationModal,
    AddLocationForm,
    EmptyListFeedback,
  },

  emits: ["closeModal"],

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    placeHolder: {
      type: String,
    },

    position: {
      type: Object,
    },
    fetchProp: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const store = useStore();

    /**
     *  State
     */
    const state = reactive({
      position: "",
      search: "",
      isOpen: false,
      openDeleteModal: false,
    });

    /*
     * Computeds
     */

    const GET_CUSTOMER_POSITIONS = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.GET_CUSTOMER_POSITIONS}`
      ];
    });
    const GET_CUSTOMER_POSITION = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.GET_CUSTOMER_POSITION}`
      ];
    });

    const GET_DELETE_CUSTOMER_POSITION = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.GET_DELETE_CUSTOMER_POSITION}`
      ];
    });
    const GET_USER = computed(
      () => store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`]
    );

    const filteredPositions = computed(() => {
      if (GET_CUSTOMER_POSITIONS.value == null) return [];
      return GET_CUSTOMER_POSITIONS.value.filter((position) => {
        return position.name.toLowerCase().match(state.search.toLowerCase());
      });
    });
    /**
     * Functions
     */

    function FETCH_CUSTOMER_POSITIONS() {
      store.dispatch(
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.FETCH_CUSTOMER_POSITIONS}`,
        GET_USER.value.customer_id
      );
    }
    function FETCH_RECEIVERS() {
      store.dispatch(
        `${STORE_TYPES.CUSTOMER_RECEIVERS}/${CUSTOMER_RECEIVERS_TYPES.FETCH_CUSTOMER_RECEIVERS}`,
        GET_USER.value.customer_id
      );
    }

    // CREATE POSITTION
    const CREATE_POSITION = async () => {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "create_position"
      );
      await store.dispatch(
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.CREATE_CUSTOMER_POSITION}`,
        GET_USER.value.customer_id
      );
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "create_position"
      );
      SET_CUSTOMER_POSITION({});
    };

    /**
     * DELETE POSITION
     */
    const DELETE_POSITION = async () => {
      let positionData = {
        customer_id: GET_USER.value.customer_id,
        id: GET_DELETE_CUSTOMER_POSITION.value.id,
      };
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "delete"
      );
      await store.dispatch(
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.DELETE_CUSTOMER_POSITION}`,
        positionData
      );
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "delete"
      );
      set_back();
      state.openDeleteModal = false;
    };

    // UPDATE POSITION
    const UPDATE_POSITION = async (input_id) => {
      if (
        GET_CUSTOMER_POSITION.value == null ||
        GET_CUSTOMER_POSITION.value.id == null
      ) {
        return;
      }
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        input_id
      );

      await store.dispatch(
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.UPDATE_CUSTOMER_POSITION}`,
        GET_CUSTOMER_POSITION.value
      );

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        input_id
      );
      SET_CUSTOMER_POSITION({});
    };

    const SET_CUSTOMER_POSITION = (position) => {
      return store.commit(
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.SET_CUSTOMER_POSITION}`,
        position
      );
    };
    const SET_DELETE_POSITION = (position) => {
      return store.commit(
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.SET_DELETE_CUSTOMER_POSITION}`,
        position
      );
    };

    const edit_position = (position) => {
      SET_CUSTOMER_POSITION(position);
    };
    const delete_position = (position) => {
      SET_DELETE_POSITION(position);
      DELETE_POSITION();
    };
    const open_delete_modal = (position) => {
      SET_DELETE_POSITION(position);
      state.openDeleteModal = true;
    };

    const set_back = () => {
      SET_CUSTOMER_POSITION({});
      state.isOpen = false;
    };

    function FETCH_OPTIONS() {
      const fetchData = {
        customer_id: GET_USER.value.customer_id,
        params: {
          page: "createPosition",
        },
      };
      store.dispatch(
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.FETCH_OPTIONS}`,
        fetchData
      );
    }

    watch(
      () => props.isVisible,
      (newValue) => {
        if (newValue == true) {
          if (GET_USER.value != null && GET_USER.value.id != null) {
            if (props.fetchProp == true) {
              FETCH_CUSTOMER_POSITIONS(GET_USER.value.customer_id);
            }
            FETCH_RECEIVERS(GET_USER.value.customer_id);
            FETCH_OPTIONS(GET_USER.value.customer_id);
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    return {
      state,
      store,
      set_back,
      delete_position,
      edit_position,
      open_delete_modal,
      GET_CUSTOMER_POSITIONS,
      GET_CUSTOMER_POSITION,
      CREATE_POSITION,
      UPDATE_POSITION,
      GET_DELETE_CUSTOMER_POSITION,
      GET_USER,
      DELETE_POSITION,
      filteredPositions,
    };
  },
};
</script>
