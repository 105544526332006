<template>
  <svg
    id="Arrow"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="7"
    viewBox="0 0 11.29 6.607"
  >
    <path
      id="Pfad_297"
      data-name="Pfad 297"
      d="M93.863,119.856l-5.18,5.6a.442.442,0,0,1-.658,0l-5.178-5.6a.508.508,0,0,1,.329-.858l10.358,0A.509.509,0,0,1,93.863,119.856Z"
      transform="translate(-82.71 -118.997)"
      :class="fillClass + ' transition-fill'"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TriangleIcon",
  props: {
    fillClass: {
      type: String,
      default: "",
    },
  },
});
</script>
